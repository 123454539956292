import { Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { Payload } from "recharts/types/component/DefaultTooltipContent";

import { formatTime, set } from "../../../../helpers/date";
import { CustomizedGraphTooltip } from "../../Graphs/CustomizedGraphTooltip";

type Props = {
    label: string;
    payload: Payload<number, string>[];
    active: boolean;
};

export const DeviantHourTooltipContent = (props: Props) => {
    const { active, label, payload } = props;
    const datasetProps = payload[0];
    const { t } = useTranslation();

    if (active && datasetProps) {
        const timeLabel = formatTime(set(new Date(), { hours: parseInt(label), minutes: 0 }));
        const eventsCount = datasetProps.value ?? 0;

        return (
            <CustomizedGraphTooltip
                content={[
                    <Stack gap="M" direction="column" key={label}>
                        <Text>
                            {t("insights.summary.deviantHourWidget.chartTooltipTitle")}: {timeLabel}
                        </Text>
                        <Text>{`${eventsCount} ${t("common.event", { count: eventsCount })}`}</Text>
                    </Stack>,
                ]}
                active={active}
            />
        );
    }
};
