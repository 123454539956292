import { Stack } from "@secuis/ccp-react-components";
import { StackProps } from "@secuis/ccp-react-components/dist/types/styles/stack/Stack";
import { PropsWithChildren } from "react";

type FooterProps = PropsWithChildren & Pick<StackProps, "direction">;

export const WidgetFooter = ({ children, direction }: FooterProps) => {
    return (
        <Stack direction={direction} marginTop="XS">
            {children}
        </Stack>
    );
};
