import { getBooleanValue, getFieldValueByFieldName, getSiteLocation } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../../models/ReportModel";
import { CalloutFields } from "../../../../models/reportTemplates/Callout";
import { DescriptionSectionPdf } from "../../../PdfReports/shared/PdfDescriptionSection";
import { PdfReportText } from "../../../PdfReports/shared/PdfReportText";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { TextAreaSummaryFieldPdf, TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { getPdfUnknownFields } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";
import { getPdfImagesCarousel } from "./imageConfigPdf";

export const getCalloutReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
    {
        component: DescriptionSectionPdf,
        props: {
            sectionTitle: "reportdetails.callout.description",
        },
        children: [
            {
                component: PdfReportText,
                props: {
                    description:
                        getFieldValueByFieldName(CalloutFields.describeTheIncident, report) || getFieldValueByFieldName(CalloutFields.describeTheEvent, report),
                    shouldAutoTranslate: true,
                },
            },
            getPdfImagesCarousel(images),
        ],
    },
    {
        component: SummarySectionPdf,
        props: {
            label: "reportdetails.callout.calloutDetails",
        },
        children: [
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.callout.alarmCallType",
                    fieldValue: getFieldValueByFieldName(CalloutFields.alarmCallType, report),
                },
            },
            {
                component: TextAreaSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.callout.additionalInformation",
                    fieldValue: getFieldValueByFieldName(CalloutFields.additionalInformation, report),
                    isRequired: false,
                },
            },
        ],
    },
    {
        component: SummarySectionPdf,
        props: {
            label: "reportdetails.callout.responseDetails",
        },
        children: [
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.callout.didAnIncidentOccurOnSite",
                    fieldValue:
                        getBooleanValue(getFieldValueByFieldName(CalloutFields.didAnIncidentOccurOnSite, report)) ||
                        getBooleanValue(getFieldValueByFieldName(CalloutFields.didAnEventOccurOnSite, report)),
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.summary.location.title",
                    fieldValue: getSiteLocation(report),
                    isRequired: false,
                    shouldAutoTranslate: false,
                },
            },
            ...getPdfUnknownFields(report, Object.values(CalloutFields), images),
        ],
    },
];
