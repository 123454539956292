import { combineReducers, configureStore, StateFromReducersMapObject } from "@reduxjs/toolkit";
import { apiMiddleware } from "redux-api-middleware";

import { apiAccessMiddleware, apiAuthMiddleware, apiBodyMiddleware, apiErrorMiddleware, apiRefreshMiddleware, trackingMiddleware } from "./middleware";
import AccessReducer from "./store/access/AccessReducer";
import AppReducer from "./store/app/AppReducer";
import clientSwitcherReducer from "./store/clientSwitcher/ClientSwitcherReducer";
import { RESET_CLIENTS_STATE } from "./store/clientSwitcher/types";
import FeatureFlagsReducer from "./store/featureFlags/FeatureFlagsReducer";
import FilterReducer from "./store/filter/FilterReducer";
import InsightsReducer from "./store/insights/InsightsReducer";
import LocationsReducer from "./store/locations/LocationsReducer";
import ReportCategoriesReducer from "./store/reportCategories/ReportCategoriesReducer";
import CommentReducer from "./store/reportComments/CommentReducer";
import { exportReportsToExcelSlice } from "./store/reports/exportReportsToExcelSlice";
import { reportsApi } from "./store/reports/reportsApi";
import { reportsSlice } from "./store/reports/reportsSlice";
import { scheduleApi } from "./store/schedule/scheduleApi";
import SiteObjectsReducer from "./store/siteObjects/SiteObjectsReducer";
import SitesReducer from "./store/sites/SitesReducer";
import UserReducer from "./store/user/UserReducer";
import UsersReducer from "./store/users/UsersReducer";

const reducers = {
    access: AccessReducer,
    app: AppReducer,
    filter: FilterReducer,
    featureFlags: FeatureFlagsReducer,
    insights: InsightsReducer,
    locations: LocationsReducer,
    reports: reportsSlice.reducer,
    reportComments: CommentReducer,
    reportCategories: ReportCategoriesReducer,
    user: UserReducer,
    users: UsersReducer,
    siteObjects: SiteObjectsReducer,
    clientSwitcher: clientSwitcherReducer,
    sites: SitesReducer,
    exportReportsSlice: exportReportsToExcelSlice.reducer,
};

const apiReducers = {
    [scheduleApi.reducerPath]: scheduleApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
};

const reducer = {
    ...reducers,
    ...apiReducers,
};

const combinedReducer = combineReducers(reducer);

export type IState = StateFromReducersMapObject<typeof reducer>;

export const rootReducer = (state: IState, action) => {
    if (action.type === RESET_CLIENTS_STATE) {
        const { access, app, featureFlags, user } = state;
        const newState = combinedReducer(undefined, action);
        return { ...newState, access, user, app, featureFlags };
    }
    return combinedReducer(state, action);
};

const middleware = [
    scheduleApi.middleware,
    reportsApi.middleware,
    apiBodyMiddleware,
    apiAccessMiddleware,
    apiAuthMiddleware,
    apiRefreshMiddleware,
    apiErrorMiddleware,
    apiMiddleware,
    trackingMiddleware,
];

export const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => {
        return getDefaultMiddleware({ serializableCheck: false }).concat(...middleware);
    },
});
