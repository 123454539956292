import { LabelText } from "./IncidentsLocationsTile.styled";

const TEXT_MARGIN = 20;
const CORRECTION_ANGLE = 4;

type PropsType = {
    cx: number;
    cy: number;
    midAngle: number;
    startAngle: number;
    endAngle: number;
    innerRadius: number;
    outerRadius: number;
    payload: { value: number };
    direction?: "up" | "down";
};

export const CustomPieChartLabel = ({ cx, cy, midAngle, outerRadius, payload, direction }: PropsType): JSX.Element => {
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + TEXT_MARGIN;
    const correction = direction === "up" ? CORRECTION_ANGLE : -CORRECTION_ANGLE;
    const angle = direction ? midAngle + correction : midAngle;
    const sin = Math.sin(-RADIAN * angle);
    const cos = Math.cos(-RADIAN * angle);
    const x = cx + radius * cos;
    const y = cy + radius * sin;

    return (
        <LabelText x={x} y={y} textAnchor={x > cx ? "start" : "end"}>
            {payload.value}
        </LabelText>
    );
};
