import { Box, Breakpoints, Headline, Icon, Skeleton, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { isEndOfMonth, isStartOfMonth } from "src/helpers/date";
import styled from "styled-components";

import { TranslatableEmptyInboxComponent } from "../../../../components/shared/NoContent/EmptyInboxContent";
import { Tooltip } from "../../../../components/shared/Tooltip";
import InsightsSelectors from "../../../../store/insights/InsightsSelectors";
import { getDataTimeRange } from "../../../../store/insights/PatrolDeviations/PatrolDeviationHelpers";
import { usePatrolDeviations } from "../../../../store/insights/PatrolDeviations/PatrolDeviationsHooks";
import * as PatrolDeviationsSelectors from "../../../../store/insights/PatrolDeviations/PatrolDeviationsSelectors";
import { DataTimeRange, PatrolDeviationData } from "../../../../store/insights/PatrolDeviations/PatrolDeviationsTypes";
import { RequestStatus } from "../../../../store/RequestStatus";
import { SectionWrapper, TooltipContainer } from "../PatrolTasks.styled";
import { useTooltipContentConfig } from "../TooltipContentConfig";
import { DeviationsTable } from "./DeviationsTable";
import { DeviationsTableWrapper } from "./DeviationsTable.styles";

type Props = {
    siteIds: string[];
    patrolDeviations: PatrolDeviationData[];
    isLoading: boolean;
    hasSingleSite?: boolean;
};

const StyledStack = styled(Stack)`
    margin-top: ${Spacing.L}px;

    @media (max-width: ${Breakpoints.M}) {
        margin-top: ${Spacing.M}px;
    }
    @media (max-width: ${Breakpoints.XS}) {
        margin-top: ${Spacing.S}px;
    }
`;

export const PatrolDeviations = ({ siteIds, patrolDeviations, isLoading, hasSingleSite }: Props) => {
    const { t } = useTranslation();
    const queryStatus = useSelector(PatrolDeviationsSelectors.getPatrolDeviationsQueryStatus);
    const { loadDailyDeviationsByCategoryLevel1Async } = usePatrolDeviations(siteIds);
    const { deviationsSectionTooltipContent } = useTooltipContentConfig(hasSingleSite);
    const isEmpty = !patrolDeviations?.length && queryStatus === RequestStatus.success;
    const selectedStartDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const selectedEndDate = useSelector(InsightsSelectors.getSelectedEndDate);
    const isFirstMonthFull = isStartOfMonth(selectedStartDate);
    const isLastMonthFull = isEndOfMonth(selectedEndDate);
    const range = getDataTimeRange(selectedStartDate, selectedEndDate);

    return (
        <SectionWrapper data-testid="insights-tasks-deviations-section" display="flex" direction="column">
            <TooltipContainer>
                <Headline bold>{t("insights.siteTasks.category.deviations")}</Headline>
                <Tooltip id="patrol-deviations-tooltip" label={deviationsSectionTooltipContent} place="top-start">
                    <Icon variant={"Info"} size="M"></Icon>
                </Tooltip>
            </TooltipContainer>
            {isLoading && queryStatus !== RequestStatus.success ? (
                <Stack direction="column" gap="XS" marginTop="M">
                    <Skeleton height={48} mode="rectangular" width="100%" />
                    <Skeleton height={64} mode="rectangular" width="100%" />
                    <Skeleton height={64} mode="rectangular" width="100%" />
                    <Skeleton height={64} mode="rectangular" width="100%" />
                    <Skeleton height={64} mode="rectangular" width="100%" />
                    <Skeleton height={48} mode="rectangular" width="100%" />
                </Stack>
            ) : null}
            {patrolDeviations?.length && queryStatus === RequestStatus.success ? (
                <Box>
                    <DeviationsTableWrapper>
                        <DeviationsTable
                            selectedStartDate={selectedStartDate}
                            selectedEndDate={selectedEndDate}
                            isFirstMonthFull={isFirstMonthFull}
                            isLastMonthFull={isLastMonthFull}
                            requestLevel2={loadDailyDeviationsByCategoryLevel1Async}
                        />
                    </DeviationsTableWrapper>
                    {range === DataTimeRange.halfYear && (!isFirstMonthFull || !isLastMonthFull) ? (
                        <StyledStack paddingHorizontal="S">
                            <Text small bold color="neutral">
                                * {t("insights.patrolDeviations.table.notFullMonth")}
                            </Text>
                        </StyledStack>
                    ) : null}
                </Box>
            ) : null}
            {isEmpty ? <TranslatableEmptyInboxComponent subtitle={"insights.patrolTours.noDataSubtitle"} title={"insights.patrolTours.noDataTitle"} /> : null}
        </SectionWrapper>
    );
};
