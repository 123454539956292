import { Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";
import { PlacementSide } from "./TooltipPlacementProvider";

export const TooltipStyled = styled.div<{ visible: boolean; isAlignLeft?: boolean; placementSide?: PlacementSide }>`
    width: max-content;
    --tooltip-background-color: ${Palette.Purple600};
    max-width: 180px;
    background-color: var(--tooltip-background-color);
    color: ${Palette.Gray100};
    text-align: ${(props) => (props.isAlignLeft ? "left" : "center")};
    border-radius: ${Spacing.XXS}px;
    padding: ${Spacing.XXS}px ${Spacing.XS}px;
    visibility: ${(props) => (props.visible ? `visible` : `hidden`)};

    @media (max-width: ${Breakpoints.S}) {
        max-width: 120px;
    }

    button {
        pointer-events: all;
    }

    &::after {
        content: " ";
        position: absolute;
        border-style: solid;
        border-color: transparent;
        border-width: 6px;
    }

    ${({ placementSide }) => {
        if (placementSide === "left") {
            return `
                &::after {
                    top: 50%;
                    left: 100%;
                    transform: translate(-1px, -50%);
                    border-left-color: var(--tooltip-background-color);
                }
            `;
        } else if (placementSide === "right") {
            return `
                &::after {
                    top: 50%;
                    left: 0;
                    transform: translate(calc(-100% + 1px), -50%);
                    border-right-color: var(--tooltip-background-color);
                }
            `;
        } else if (placementSide === "bottom") {
            return `
                &::after {
                    top: 0;
                    left: 50%;
                    transform: translate(-50%, calc(-100% + 1px));
                    border-bottom-color: var(--tooltip-background-color);
                }
            `;
        } else {
            return `
                &::after {
                    top: 100%;
                    left: 50%;
                    transform: translate(-50%, -1px);
                    border-top-color: var(--tooltip-background-color);
                }
            `;
        }
    }}
`;

export const StyledButton = styled.button`
    pointer-events: auto;
    text-decoration: underline;
    cursor: pointer;
`;
