import styled from "styled-components";
import { Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";

export const FooterWrapperStyled = styled(Stack).attrs({
    pv: "S",
    ph: "L",
    justifyContent: "space-between",
})`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: ${Palette.Navy800};
    border-top: solid 1px ${Palette.Navy300};

    align-items: center;
    flex-direction: row;

    @media (max-width: ${Breakpoints.XS}) {
        align-items: flex-start;
        flex-direction: column;
    }
`;

export const ButtonsStyled = styled(Stack)`
    gap: ${Spacing.M}px;
    flex-direction: row;
    width: 560px;

    @media (max-width: ${Breakpoints.XS}) {
        gap: ${Spacing.S}px;
        flex-direction: column-reverse;
        width: 100%;
    }
`;
