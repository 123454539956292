import { ButtonText, Icon, Stack, Text } from "@secuis/ccp-react-components";
import { useMemo, useState } from "react";

import { Column } from "../../shared/Table/Table";
import { Tooltip } from "../../shared/Tooltip";
import { SortButtonEmptyPlaceholderStyled } from "../Schedule.styles";

type Props = {
    column: Column;
    header: string;
    sortItemsByColumn: () => void;
    sortKey: string;
    tooltipText?: string;
};

const HeaderCell = ({ header, column, sortItemsByColumn, sortKey, tooltipText }: Props) => {
    const [isHover, setIsHover] = useState(false);

    const showSortButton = useMemo(() => sortKey === column.key || isHover, [sortKey, column, isHover]);

    return (
        <Stack
            gap="XXS"
            alignItems="center"
            onMouseEnter={() => {
                setIsHover(true);
            }}
            onMouseLeave={() => {
                setIsHover(false);
            }}
        >
            <Text small bold color="neutral">
                {header}
            </Text>
            {tooltipText && (
                <Tooltip id={`tooltip-${column.id}`} label={tooltipText}>
                    <Icon size="S" variant="Info" color="neutral" />
                </Tooltip>
            )}
            {showSortButton ? <ButtonText onClick={sortItemsByColumn} icon="SortArrowUp" color="secondary" /> : <SortButtonEmptyPlaceholderStyled />}
        </Stack>
    );
};

export const renderHaderCell = (props: Props) => <HeaderCell {...props} />;
