export enum SessionStorageKey {
    ReportFilters = "reportFilters",
    InsightsFilters = "insightsFilters",
    UserId = "userId",
    ClientId = "clientId",
    ClientLabel = "clientLabel",
    ClientName = "clientName",
}

export const resetState = (): void => {
    sessionStorage.clear();
};

export const saveState = (state: unknown, key: SessionStorageKey): void => {
    const serializedState = JSON.stringify(state);
    sessionStorage.setItem(key, serializedState);
};

export const saveValue = (value: string, key: SessionStorageKey): void => {
    sessionStorage.setItem(key, value);
};

export const resetValue = (key: SessionStorageKey): void => {
    sessionStorage.removeItem(key);
};

export const loadObjectFromState = (key: SessionStorageKey): unknown => {
    try {
        const serializedState = sessionStorage.getItem(key);
        return JSON.parse(serializedState);
    } catch (error) {
        return null;
    }
};

export const loadValueFromState = (key: SessionStorageKey): string => {
    try {
        const item = sessionStorage.getItem(key);
        return item;
    } catch (error) {
        return null;
    }
};

export const clearUserData = (): void => {
    const userId = loadValueFromState(SessionStorageKey.UserId);
    resetState();
    if (userId) {
        saveValue(userId, SessionStorageKey.UserId);
    }
};
