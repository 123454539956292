import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CategoryLevel1 } from "src/models/ReportCategoryModel";

import { SUMMARY_REPORT_CATEGORIES } from "../../../shared/constants";
import { ALL_ITEMS_KEY } from "./CategoriesDropdown.constants";
import { CategoryDropdownItem, CategoryDropdownOption } from "./CategoriesDropdown.types";

export const useCategoriesDropdown = (onChange?: (categories: CategoryLevel1[]) => void) => {
    const { t } = useTranslation();
    const [selectedKeys, setSelectedKeys] = useState<CategoryDropdownOption[]>([ALL_ITEMS_KEY, ...SUMMARY_REPORT_CATEGORIES]);

    useEffect(() => {
        onChange(selectedKeys.filter((category) => category !== ALL_ITEMS_KEY).map((cat) => cat as CategoryLevel1));
    }, [onChange, selectedKeys]);

    const onItemSelect = useCallback((category: CategoryDropdownOption) => {
        setSelectedKeys(updateSelectedCategoriesBasedOnCurrentState(category));
    }, []);

    const items: CategoryDropdownItem[] = useMemo(
        () => [
            { label: t("filters.allCategories"), value: ALL_ITEMS_KEY },
            ...SUMMARY_REPORT_CATEGORIES.map((category) => ({
                value: category,
                label: t(`incident.category.${category}`),
            })),
        ],
        [t],
    );

    return {
        dropdownItems: items,
        selectedItems: selectedKeys,
        onItemSelect,
    };
};

const updateSelectedCategoriesBasedOnCurrentState = (clickedCategory: CategoryDropdownOption) => (previousSelections: CategoryDropdownOption[]) => {
    let updatedSelections: CategoryDropdownOption[] = [];
    const isRemoving = previousSelections.includes(clickedCategory);
    const isAllItem = clickedCategory === ALL_ITEMS_KEY;

    if (isRemoving) {
        updatedSelections = isAllItem ? [] : previousSelections.filter((key) => ![ALL_ITEMS_KEY, clickedCategory].includes(key));
    } else {
        updatedSelections = isAllItem ? [...SUMMARY_REPORT_CATEGORIES] : [...previousSelections, clickedCategory];
    }

    if (updatedSelections.length === SUMMARY_REPORT_CATEGORIES.length) {
        updatedSelections = [...updatedSelections, ALL_ITEMS_KEY];
    }

    return updatedSelections;
};
