import { Breakpoints, Palette, useHasMaxWidth } from "@secuis/ccp-react-components";
import { get } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, CartesianGrid, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";
import { ChartCoordinate } from "recharts/types/util/types";
import { mapIncidentsToVisualValues } from "src/helpers/graphs";

import { getSeverityLevelTranslationKey } from "../../../helpers/SeverityLevelHelper";
import { useGraphOverviewRedirection } from "../../../store/insights/GraphOverviewHooks";
import { CustomizedGraphTooltip } from "./CustomizedGraphTooltip";

interface InsightsGraphOneProps {
    category: string;
    data: Record<string, number>;
}

export const InsightsSeverityGraph = ({ category, data }: InsightsGraphOneProps) => {
    const { t } = useTranslation();
    const [activeName, setActiveName] = useState<string>("");
    const [tooltipActiveLabel, setTooltipActiveLabel] = useState<string>("");
    const [clickCoordinates, setClickCoordinates] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [tooltipWidth, setTooltipWidth] = useState<number>();
    const [barHeight, setBarHeight] = useState<number>(190);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
    const [yAxisWidth, setYAxisWidth] = useState(60);
    const responsiveContainerRef = useRef(null);
    const { redirectToSeverityLevelReports } = useGraphOverviewRedirection();
    const tooltipRedirectId = "severity-levels-link";

    const setFocus = useCallback((event) => {
        if (event.target.closest(`#${tooltipRedirectId}`)) {
            return;
        }
        if (responsiveContainerRef.current?.current?.contains(event.target)) {
            const parentElementClassList = event.target?.parentElement?.classList;
            const isBar = ["recharts-bar-rectangle", "recharts-active-bar"].some((x) => parentElementClassList?.contains(x));

            setTooltipVisible(isBar);
        } else {
            setTooltipVisible(false);
        }
    }, []);

    const setFocusMobile = useCallback(
        (event) => {
            const barsArray = Array.from(event.target?.querySelectorAll(".recharts-rectangle")) as SVGAElement[];

            const selectedBarHeight = +barsArray.find((x) => x.getAttribute("name") === tooltipActiveLabel)?.getAttribute("height");

            if (selectedBarHeight) setBarHeight(selectedBarHeight);

            if (!responsiveContainerRef.current?.current?.contains(event.target)) {
                setTooltipVisible(false);
            }
        },
        [tooltipActiveLabel],
    );

    const handleMouseDown = useCallback(
        (e) => {
            return isMobile ? setFocusMobile(e) : setFocus(e);
        },
        [isMobile, setFocus, setFocusMobile],
    );

    useEffect(() => {
        window.addEventListener("mousedown", handleMouseDown);

        return () => window.removeEventListener("mousedown", handleMouseDown);
    }, [handleMouseDown]);

    const severityData = useMemo(() => {
        if (!Object.keys(data)?.length) {
            return [{ key: "", name: "", value: null, visualValue: null }];
        }
        const dataArr = Object.keys(data)
            .reverse()
            .map((d) => {
                return {
                    key: d,
                    name: t(getSeverityLevelTranslationKey(parseInt(d))),
                    value: data[d],
                };
            });
        return mapIncidentsToVisualValues(dataArr);
    }, [data, t]);

    const chartCenterPoint = useMemo(() => {
        if (!isMobile) {
            return 75;
        }
        return 25;
    }, [isMobile]);

    const updateYAxisWidth = useCallback(
        (tickValue) => {
            const tickWidth = tickValue?.toString()?.length;
            //if number has more than 2 digits, Y-axis needs to resized to not have label cut
            if (tickWidth < 3) {
                return;
            }
            const newWidth = 60 + (tickWidth - 2) * 10;
            if (newWidth > yAxisWidth) {
                setTimeout(() => {
                    setYAxisWidth(newWidth);
                });
            }
        },
        [yAxisWidth],
    );

    const formatTick = useCallback(
        (value) => {
            // the only way to access domain values in order to resize y-axis width. ref on yAxis does not work in recharts
            updateYAxisWidth(value);
            return value;
        },
        [updateYAxisWidth],
    );

    const handleCoordinatesChange = (coordinates: ChartCoordinate) => {
        if (coordinates?.x && coordinates?.y) {
            setClickCoordinates(coordinates);
        }
    };

    const handleWidthChange = (width: number) => {
        if (width) setTooltipWidth(width);
    };

    const RenderCustomTooltip = useCallback(
        (payload) => {
            if (!payload?.payload || !payload?.payload[0]?.value) {
                return null;
            }
            const value = get(payload, "payload.0.payload.value");
            const content = (
                <>
                    {value} {t("insights.overview.event", { count: value })}
                </>
            );

            return CustomizedGraphTooltip({
                redirectElementId: tooltipRedirectId,
                active: tooltipVisible,
                content,
                widthHandler: handleWidthChange,
                buttonLabel: t("insights.overview.seeReports", { count: value }),
                onClick: () => {
                    const key = payload?.payload[0]?.payload?.key;
                    if (key) {
                        redirectToSeverityLevelReports(category, key);
                    }
                },
            });
        },
        [redirectToSeverityLevelReports, category, tooltipVisible, t],
    );

    const yAxis = useMemo(() => {
        const maxYValue = Math.max(...severityData.map((s) => s.value));
        const defaultProps = {
            dataKey: "value",
            axisLine: false,
            tick: { fill: "#fff" },
            tickCount: 3,
            tickFormatter: formatTick,
            tickLine: false,
            tickMargin: 20,
            width: yAxisWidth,
        };
        if (maxYValue === 1) {
            return <YAxis type="number" {...defaultProps} ticks={[0, 1, 2]} domain={[0, 2]} />;
        }
        return <YAxis type="number" {...defaultProps} />;
    }, [severityData, yAxisWidth, formatTick]);

    const onMouseDownHandler = (e: CategoricalChartState) => {
        if (isMobile && e?.activeLabel) {
            setTooltipActiveLabel(e.activeLabel);
        }

        handleCoordinatesChange(e?.activeCoordinate);
        setTooltipVisible(true);
    };

    const xAxisAngle = useMemo(() => {
        return isMobile ? -40 : 0;
    }, [isMobile]);

    const xAxisTextAnchor = useMemo(() => {
        return isMobile ? "end" : "middle";
    }, [isMobile]);

    const xAxisHeight = useMemo(() => {
        return isMobile ? 80 : 30;
    }, [isMobile]);

    const isTicksGapTooNarrow = useMemo((): boolean => {
        const MAX_ALLOWED_ADJACENTS_LENGTH = 14;

        for (let i = 1; i <= severityData.length - 1; i++) {
            if (severityData[i].name.length + severityData[i - 1].name.length > MAX_ALLOWED_ADJACENTS_LENGTH) {
                return true;
            }
        }

        return false;
    }, [severityData]);

    return (
        <ResponsiveContainer width="100%" height={170} ref={responsiveContainerRef}>
            <BarChart
                data={severityData}
                barSize={7.45}
                barGap={30}
                margin={{
                    top: 0,
                    right: 14,
                    left: -14,
                    bottom: 0,
                }}
                onMouseDown={(e) => onMouseDownHandler(e)}
            >
                <XAxis
                    type="category"
                    dataKey="name"
                    axisLine={false}
                    tickLine={false}
                    tick={{ fill: "#fff", fontSize: isTicksGapTooNarrow ? "13px" : "16px" }}
                    tickMargin={10}
                    angle={xAxisAngle}
                    textAnchor={xAxisTextAnchor}
                    height={xAxisHeight}
                    interval={0}
                />
                {yAxis}
                <Tooltip
                    trigger="click"
                    allowEscapeViewBox={{ x: true, y: true }}
                    content={RenderCustomTooltip}
                    cursor={false}
                    offset={0}
                    position={{ x: Math.floor(clickCoordinates.x - tooltipWidth / 2), y: chartCenterPoint - barHeight }}
                    wrapperStyle={{ outline: "none" }}
                />
                <CartesianGrid stroke="#2E4869" vertical={false} />
                <Bar
                    dataKey="visualValue"
                    radius={[10, 10, 0, 0]}
                    onMouseOver={(e) => setActiveName(e.name)}
                    onMouseLeave={() => setActiveName("")}
                    onMouseDown={(e) => setBarHeight(e.height)}
                >
                    {severityData.map((entry, index) => (
                        <Cell cursor="pointer" fill={entry.name === activeName ? Palette.Purple300 : Palette.Purple200} key={`cell-${index}`} />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};
