import { Text } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { ISiteObject } from "../../../../models/SiteObjectModel";
import { SiteCardItemStyled, SiteCardWrapperStyled, Styled } from "./shared/styles";

type Props = {
    allSites: ISiteObject[];
    filterApplied: boolean;
    siteIdsWithDamages?: string[];
    siteIdsWithoutDamages?: string[];
};

export const EmptySiteCard = ({ allSites, filterApplied, siteIdsWithDamages, siteIdsWithoutDamages }: Props) => {
    const { t } = useTranslation();
    const [headerText, tooltipText] = useMemo(() => {
        if (!filterApplied) {
            if (allSites.length === siteIdsWithoutDamages?.length) {
                if (allSites.length === 1) {
                    return [allSites[0]?.displayName, t("insights.damages.allSitesTooltip")];
                }
                return [t("insights.damages.allSites"), t("insights.damages.allSitesTooltip")];
            }
            return [t("insights.damages.otherSitesNoCount"), t("insights.damages.otherSitesTooltip")];
        }
        if (siteIdsWithDamages.length) {
            return [t("insights.damages.otherSites", { count: siteIdsWithoutDamages?.length }), t("insights.damages.otherSitesTooltip")];
        }
        return [t("insights.damages.yourSitesFiltered", { count: siteIdsWithoutDamages?.length }), t("insights.damages.otherSitesTooltip")];
    }, [allSites, filterApplied, siteIdsWithDamages, siteIdsWithoutDamages, t]);

    return siteIdsWithoutDamages?.length > 0 ? (
        <SiteCardWrapperStyled hasWholeWidth>
            <Styled tooltipText={tooltipText} id="empty-card" iconVariant="Info" headerText={headerText}></Styled>
            <SiteCardItemStyled>
                <Text small>{t("insights.damages.noDamages")}</Text>
            </SiteCardItemStyled>
        </SiteCardWrapperStyled>
    ) : null;
};
