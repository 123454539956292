import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import InsightHeader from "../../../../components/Insights/common/InsightHeader";

const OverviewHeader: React.FC<{ reportsCount: number; children?: React.ReactNode }> = ({ reportsCount, children = [] }) => {
    const { t } = useTranslation();

    const headerText = useMemo(() => {
        const hasCount = Number.isFinite(reportsCount);
        return hasCount ? `${reportsCount} ${reportsCount === 1 ? t("insights.overview.subtitle") : t("insights.overview.subtitles")}` : null;
    }, [reportsCount, t]);
    return (
        <InsightHeader title={t("insights.overview.title")} subTitle={headerText}>
            {children ?? children}
        </InsightHeader>
    );
};

export default OverviewHeader;
