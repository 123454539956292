import { Spacing, Stack } from "@secuis/ccp-react-components";
import { Fragment } from "react";
import { ValueTile } from "src/components/ValueTile";

import { HorizontalLine } from "../../../../../components/shared/HorizontalLine";
import { StatisticData } from "../types";

type Props = {
    stats: StatisticData[];
    isLoading?: boolean;
    spacing?: Spacing | number;
};

export const StatsList = ({ stats, isLoading, spacing = Spacing.M }: Props) => {
    return (
        <Stack direction="column" flex={1}>
            {stats.map(({ id, title, label, icon, value, previousValue, open }, index) => (
                <Fragment key={id}>
                    {!!index && <HorizontalLine marginTop={0} marginBottom={spacing} />}
                    <ValueTile
                        data-testid={id}
                        title={title}
                        icon={icon}
                        value={value}
                        onRedirect={open}
                        previousValue={previousValue}
                        isLoading={isLoading}
                        label={label}
                    />
                </Fragment>
            ))}
        </Stack>
    );
};
