import { Palette, Title } from "@secuis/ccp-react-components";
import { ChartWrapper } from "src/components/Insights/ChartWrapper";
import InsightTile from "src/components/Insights/common/InsightTile";
import styled from "styled-components";

const chartHeight = "256px";

export const InsightTileStyled = styled(InsightTile)`
    flex: 1 1 400px;
    min-width: 0;
    background-color: ${Palette.Navy800};
`;

export const TitleStyled = styled(Title)`
    white-space: nowrap;
`;

export const ChartWrapperStyled = styled(ChartWrapper)`
    padding: 0;
    height: ${chartHeight};
    min-height: ${chartHeight};
`;
