import { Headline, Stack, Subtitle } from "@secuis/ccp-react-components";

import { ChildrenProps } from "../../../models/ChildrenPropModel";
import { HeaderContainer } from "./InsightHeader.styled";

type Props = ChildrenProps & {
    title: string;
    subTitle?: string;
};

const InsightHeader = ({ children, title, subTitle }: Props) => {
    return (
        <HeaderContainer>
            <Stack direction="column" mr="S">
                <Headline bold data-testid="insights-overview-title">
                    {title}
                </Headline>
                {subTitle && <Subtitle>{subTitle}</Subtitle>}
            </Stack>
            {children}
        </HeaderContainer>
    );
};

export default InsightHeader;
