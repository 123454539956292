import { Box, Icon, Stack, Text, Tooltip } from "@secuis/ccp-react-components";
import millify from "millify";
import { useTranslation } from "react-i18next";

import { CellWrapperStyled, TotalStyled } from "./OtherHeaderCell.styles";

type OtherHeaderCellProps = {
    totalCount: number;
};

export const OtherHeaderCell = ({ totalCount }: OtherHeaderCellProps) => {
    const { t } = useTranslation();

    return (
        <CellWrapperStyled disabled={true}>
            <Stack alignItems="center">
                <Stack>
                    <Box mr="XS">
                        <Text micro truncate={true} data-testid="matrix-table-data-category-name">
                            {t("incidentMatrix.table.otherColumn.header")}
                        </Text>
                    </Box>

                    <Tooltip message={t("incidentMatrix.table.otherColumn.tooltip")} place="top-end">
                        <Icon variant="Info" />
                    </Tooltip>
                </Stack>
            </Stack>
            <TotalStyled small color="secondary" data-testid="matrix-table-data-category-total">
                {t("incidentMatrix.table.total")}: {millify(totalCount)}
            </TotalStyled>
        </CellWrapperStyled>
    );
};
