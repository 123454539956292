import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CategoryLevel1 } from "src/models/ReportCategoryModel";

import { REPORT_CATEGORY_ICON_MAP } from "../../shared/constants";
import { SUMMARY_REPORT_CATEGORIES } from "../shared/constants";
import { useSummaryPeriod } from "../shared/hooks";
import { useIncidentCategoriesCount } from "../shared/hooks/useIncidentCategoriesCount";
import { CategoryStats } from "./IncidentCategoriesWidget.types";

export const useIncidentCategoriesWidget = () => {
    const { t } = useTranslation();
    const { currentPeriod, previousPeriod } = useSummaryPeriod();
    const [activeCategories, setActiveCategories] = useState<CategoryLevel1[]>([]);
    const { isLoading: isLoadingCurrent, queryResult: currentValues } = useIncidentCategoriesCount(currentPeriod.start, currentPeriod.end);
    const { isLoading: isLoadingPrevious, queryResult: oldValues } = useIncidentCategoriesCount(previousPeriod.start, previousPeriod.end);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const openInsights = (category: CategoryLevel1) => alert(`Open Incident page for ${category} when the page is ready.`);

    const categoriesStats: CategoryStats[] = useMemo(
        () =>
            SUMMARY_REPORT_CATEGORIES.filter((category) => activeCategories.includes(category))
                .map(
                    (category) =>
                        ({
                            id: category,
                            icon: REPORT_CATEGORY_ICON_MAP[category],
                            value: currentValues?.[category] ?? 0,
                            previousValue: oldValues?.[category] ?? 0,
                            // open: () => openInsights(category), temporary disable redirecting
                        }) satisfies Partial<CategoryStats>,
                )
                .map(
                    (stat) =>
                        ({
                            ...stat,
                            title: t(`incident.category.${stat.id}`),
                            label: t("common.event", { count: stat.value }),
                        }) satisfies CategoryStats,
                ),
        [t, currentValues, oldValues, activeCategories],
    );

    return {
        categoriesStats,
        isLoading: isLoadingCurrent || isLoadingPrevious,
        updateActiveCategories: setActiveCategories,
    };
};
