import { HighlightsType } from "../../../models";
import { generateStringForQuery, getReportQueryTimeRangeCondition } from "../../../sql/scripts/queryUtilities";

export const getDamagedReportsCategoriesQuery = (highlightsType: HighlightsType, siteIds: string[], start: Date, end: Date, withSiteZone = false) => {
    return {
        sql: `SELECT r.location_id, r.category_level2, count(r.id) AS damages_count
                FROM reports r
                INNER JOIN ${highlightsType} c ON r.category_key=c.category_key
                WHERE ${getReportQueryTimeRangeCondition("$startDate", "$endDate", withSiteZone, "r")}
                    AND r.location_id IN (${generateStringForQuery(siteIds)})
                GROUP BY r.location_id, r.category_level2;`,
        params: {
            $startDate: start.toISOString(),
            $endDate: end.toISOString(),
        },
    };
};
