import { faker } from "@faker-js/faker";
import { sample, shuffle } from "lodash";

import { IImageData } from "../../models/ReportImagesModels";
import { ReportTemplateEnum } from "../../models/ReportModel";
import { REPORT_IMAGES, SITE_LOCATION_SUB_LEVELS, SITE_LOCATIONS } from "../data";
import db from "../db";
import { getPreviousMonths, getRandomCategory, getRandomDates, getReportProperties } from "../helpers";
import { Report, SiteObjects } from "../types";

type ReportsConfig = {
    type: ReportTemplateEnum;
    categories: {
        category?: string;
        maxCount: number;
    }[];
}[];

const createReports = (reportsConfig: ReportsConfig, siteObjects: SiteObjects, howManyMonths: number) => {
    if (howManyMonths <= 0) {
        return;
    }

    const reports: Report[] = [];
    const previousMonths = getPreviousMonths(howManyMonths);
    const dates: number[] = [];

    for (let i = 0; i < previousMonths.length; i++) {
        reportsConfig.forEach((reportConfig) => {
            reportConfig.categories.forEach((report) => {
                if (report.maxCount === 0) {
                    return;
                }

                // based on config, generate (min, max) reports for category
                const reportsAmount = faker.number.int({ min: 1, max: report.maxCount });

                for (let i = 1; i <= reportsAmount; i++) {
                    const siteObject = sample(siteObjects);

                    // categories are optional for some types of reports
                    const { level1 = null, level2 = null, level3 = null, severityLevel = null } = getRandomCategory(reportConfig.type) || {};
                    const siteLocation = sample(SITE_LOCATIONS);
                    const siteLocationSubLevels = sample(SITE_LOCATION_SUB_LEVELS);
                    const imageAttachment = getReportImage(level3);
                    const reportImages = imageAttachment ? [imageAttachment] : [];

                    reports.push({
                        id: faker.string.uuid(),
                        locationId: siteObject.id,
                        categoryLevel1: level1,
                        categoryLevel2: level2,
                        categoryLevel3: level3,
                        template: reportConfig.type,
                        childTemplate: reportConfig.type,
                        siteLocation: siteLocation,
                        siteLocationSubLevels: siteLocationSubLevels,
                        rootSiteLocation: siteLocation,
                        commentsCount: 0,
                        imageCount: reportImages.length,
                        images: reportImages,
                        countryCode: siteObject.countryCode,
                        type: reportConfig.type,
                        subType: "",
                        severityLevel: severityLevel,
                        visitorIds: [],
                        properties: [],
                        reportedBy: faker.person.firstName() + " " + faker.person.lastName(),
                        createDateTime: null,
                        reportDateTime: null,
                        submitDateTime: null,
                        sourceId: faker.string.numeric(6),
                        isRead: false,
                    });
                }

                const { month, year } = previousMonths[i];

                // generate random dates for given month in year that will
                // be assigned later to generated reports
                dates.push(...getRandomDates(reportsAmount, month, year));
            });
        });
    }

    // shuffle reports because initially they are ordered by categories
    const shuffledReports = shuffle(reports);

    // assign dates to shuffled reports
    const reportsWithDate = assignRandomDatesToReports(shuffledReports, dates);

    reportsWithDate.forEach((report) => {
        report.properties = getReportProperties(report);

        try {
            db.reports.create(report);
        } catch (error) {
            console.error("Error creating report", error);
        }
    });
};

export const assignRandomDatesToReports = (reports: Report[], dates: number[]): Report[] => {
    dates.sort((a, b) => a - b);

    return reports.map((report, index) => {
        const date = new Date(dates[index]).toISOString();
        return {
            ...report,
            createDateTime: dates[index],
            reportDateTime: date,
            submitDateTime: date,
        };
    });
};

export const isTypeWithCategory = (type: ReportTemplateEnum) => {
    const typesWithCategory = [
        ReportTemplateEnum.incident,
        ReportTemplateEnum.alarmResponse,
        ReportTemplateEnum.callout,
        ReportTemplateEnum.tourException,
        ReportTemplateEnum.tourMultiException,
    ];
    return typesWithCategory.includes(type);
};

export const getReportImage = (category: string): IImageData | null => {
    const imageId = REPORT_IMAGES[category] || null;

    return imageId
        ? {
              format: "Jpeg",
              smallSizeUrl: `${imageId}_small.jpg`,
              mediumSizeUrl: `${imageId}_medium.jpg`,
              largeSizeUrl: `${imageId}_large.jpg`,
              reportFieldName: "picture_1",
          }
        : null;
};

export default createReports;
