import { Palette } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { ChildrenProps } from "../../../models/ChildrenPropModel";
import { InsightTileHeader, InsightTileHeaderProps } from "./InsightTileHeader";
import { Tile } from "./Tile.styled";

export const TileContainer = styled(Tile)<{ color?: Palette }>`
    height: fit-content;
    width: 100%;
    background-color: ${({ color }) => (color ? color : Palette.Navy750)};
`;

type Props = InsightTileHeaderProps & {
    className?: string;
    headerContent?: React.ReactNode;
    color?: Palette;
};

const InsightTile = ({
    id,
    children,
    className,
    iconVariant,
    headerContent,
    headerText,
    tooltipText,
    tooltipPlace,
    additionalInfo,
    color,
}: ChildrenProps<Props>) => {
    return (
        <TileContainer data-testid="insights-tile-container" className={className} color={color}>
            <InsightTileHeader
                id={id}
                iconVariant={iconVariant}
                headerText={headerText}
                tooltipText={tooltipText}
                tooltipPlace={tooltipPlace}
                additionalInfo={additionalInfo}
            >
                {headerContent}
            </InsightTileHeader>
            {children}
        </TileContainer>
    );
};

export default InsightTile;
