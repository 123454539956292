import { Background, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const StackStyled = styled(Stack).attrs({
    direction: "column",
    padding: "S",
})`
    min-height: 460px;
    background: ${Background.content};
`;
