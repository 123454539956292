import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";

import InsightsActions from "../../../store/insights/InsightsActions";
import InsightsSelectors from "../../../store/insights/InsightsSelectors";
import { DateFilter } from "../../shared/filters";

type Props = {
    onCalendarOpen?: () => void;
};

export const InsightsDateFilter = ({ onCalendarOpen }: Props) => {
    const dispatch = useDispatch();
    const preselectedStartDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const preselectedEndDate = useSelector(InsightsSelectors.getSelectedEndDate);

    const onDateChange = useCallback(
        (startDate: Date, endDate: Date) => {
            dispatch(InsightsActions.selectDateRange(startDate, endDate));
        },
        [dispatch],
    );

    return <DateFilter startDate={preselectedStartDate} endDate={preselectedEndDate} onDateChange={onDateChange} onCalendarOpen={onCalendarOpen} />;
};
