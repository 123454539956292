import { Icon, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { DataItem } from "../Matrix.types";

export const IconStyled = styled(Icon)`
    margin-left: ${Spacing.XS}px;
`;

type ToggleVisibleItemsProps = {
    items: DataItem[];
    maxItems: number;
    showMoreItems: () => void;
    showLessItems: () => void;
    showMoreItemsText: string;
    showLessItemsText: string;
};

export const ToggleVisibleItems = ({ items, maxItems, showMoreItems, showLessItems, showMoreItemsText, showLessItemsText }: ToggleVisibleItemsProps) => {
    const isShowingLess = items.length <= maxItems;
    const handleClick = isShowingLess ? showMoreItems : showLessItems;
    const buttonText = isShowingLess ? showMoreItemsText : showLessItemsText;
    const iconVariant = isShowingLess ? "ArrowDown" : "ArrowUp";

    return (
        <button onClick={handleClick}>
            <Stack direction="row" alignItems="center" data-testid="matrix-toggle-visible-items">
                <Text>{buttonText}</Text>
                <IconStyled variant={iconVariant} size="L" />
            </Stack>
        </button>
    );
};
