import {
    FILTER_APPLY_FILTERS,
    FILTER_APPLY_PERSISTED_STATE,
    FILTER_CLEAR_CATEGORIES,
    FILTER_CLEAR_DATES_SELECTION,
    FILTER_CLEAR_REGIONS,
    FILTER_SELECT_REGIONS,
    FILTER_SELECT_REPORT_TYPE,
    FILTER_SELECT_SEVERITY_LEVELS,
    FILTER_SELECT_SITE_OBJECTS,
    FILTER_SELECT_TO_DATE,
    IFilterState,
    SHOW_ONLY_UNREAD_CHANGE,
} from "./types";
import { formatToRawDate, addDays, RawDateString } from "../../helpers/date";

export const selectRegions = (regions: string[]) => ({ type: FILTER_SELECT_REGIONS, payload: regions });
export const selectSeverities = (severities: string[]) => ({
    type: FILTER_SELECT_SEVERITY_LEVELS,
    payload: severities,
});
export const selectSitesObjects = (sites: string[]) => ({ type: FILTER_SELECT_SITE_OBJECTS, payload: sites });
export const selectReportTypes = (types: string[]) => ({ type: FILTER_SELECT_REPORT_TYPE, payload: types });
export const clearCategories = () => ({ type: FILTER_CLEAR_CATEGORIES });
export const selectDates = (selectedStartDate: RawDateString, selectedEndDate: RawDateString) => ({
    type: FILTER_SELECT_TO_DATE,
    payload: {
        selectedStartDate: selectedStartDate,
        selectedEndDate: parseEndDateIfItsToday(selectedEndDate),
    },
});
export const clearRegions = () => ({ type: FILTER_CLEAR_REGIONS });

export const applyFilters = (
    selectedSeverityLevels: string[],
    selectedCategoriesOne: string[],
    selectedCategoriesTwo: string[],
    selectedCategoriesThree: string[],
    selectedReportTypes: string[],
    selectedSiteLocations: string[],
    selectedRegions: string[],
    showOnlyUnread?: boolean,
) => ({
    type: FILTER_APPLY_FILTERS,
    payload: {
        selectedSeverityLevels,
        selectedCategoriesOne,
        selectedCategoriesTwo,
        selectedCategoriesThree,
        selectedReportTypes,
        selectedSiteLocations,
        showOnlyUnread,
        selectedRegions,
    },
});

export const applyPersistedState = (persistedState: IFilterState) => ({
    type: FILTER_APPLY_PERSISTED_STATE,
    payload: {
        selectedRegions: persistedState.selectedRegions,
        selectedSeverityLevels: persistedState.selectedSeverityLevels,
        selectedCategory1: persistedState.selectedCategories1,
        selectedCategory2: persistedState.selectedCategories2,
        selectedCategory3: persistedState.selectedCategories3,
        selectedReportTypes: persistedState.selectedReportTypes,
        selectedSiteLocations: persistedState.selectedSiteLocations,
        selectedStartDate: persistedState.selectedStartDate,
        selectedEndDate: parseEndDateIfItsToday(persistedState.selectedEndDate),
        showOnlyUnread: persistedState.showOnlyUnread,
    },
});

const clearDatesSelection = () => ({ type: FILTER_CLEAR_DATES_SELECTION });

const setShowOnlyUnread = (
    showOnlyUnread?: boolean,
): {
    type: string;
    payload: boolean;
} => ({ type: SHOW_ONLY_UNREAD_CHANGE, payload: showOnlyUnread });

const parseEndDateIfItsToday = (selectedEndDate: RawDateString | null): RawDateString | null => {
    // NOTE: While filtering reports based on site local time, user's "today"
    // may not include some reports taking place in the future date (from site point of view).
    // https://secsisip.atlassian.net/browse/GLOB-4793

    const today = formatToRawDate(new Date());

    return selectedEndDate === today ? formatToRawDate(addDays(new Date(), 1)) : selectedEndDate;
};

export default {
    selectRegions,
    applyFilters,
    clearDatesSelection,
    applyPersistedState,
    showOnlyUnread: setShowOnlyUnread,
    selectSeverities,
    selectSitesObjects,
    clearCategories,
    selectReportTypes,
    clearRegions,
};
