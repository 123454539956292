import { Breakpoints, Skeleton, Spacing, Stack, Theme } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const StackStyled = styled(Stack).attrs({
    flexWrap: "nowrap",
})`
    column-gap: ${Spacing.S}px;
    row-gap: ${Spacing.XXS}px;
`;

export const SkeletonStyled = styled(Skeleton).attrs({ mode: "rectangular" })`
    min-height: 88px;
`;

export const TileWrapperStyled = styled(Stack).attrs({
    direction: "column",
    gap: "XXS",
})<{ isClickable?: boolean }>`
    padding: ${Spacing.S}px;

    @media (max-width: ${Breakpoints.XS}) {
        padding-left: 0;
        padding-right: 0;
    }

    @media (hover: hover) {
        ${({ isClickable }) =>
            isClickable &&
            `&:hover {
            background: ${Theme.hover.dark};
            }`}
    }
`;
