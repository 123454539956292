import { formatDateTimeByTimezone, getLocalDateByReferenceTimeAndOffsetDateTime } from "../../../../helpers/reportTemplates/dynamicTemplateDateTimeHelper";
import { getFieldValueByFieldName, getSiteLocation } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../../models/ReportModel";
import { AlarmResponseFields } from "../../../../models/reportTemplates/AlarmResponse";
import { DescriptionSectionPdf } from "../../../PdfReports/shared/PdfDescriptionSection";
import { PdfReportText } from "../../../PdfReports/shared/PdfReportText";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { DateSummaryFieldPdf, TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { getPdfUnknownFields } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";

export const getAlarmResponseReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
    {
        component: DescriptionSectionPdf,
        props: {
            sectionTitle: "reportdetails.description.title",
        },
        children: [
            {
                component: PdfReportText,
                props: {
                    title: getFieldValueByFieldName(AlarmResponseFields.title, report),
                    description: getFieldValueByFieldName(AlarmResponseFields.description, report),
                    shouldAutoTranslate: true,
                    images,
                },
            },
        ],
    },
    {
        component: SummarySectionPdf,
        props: {
            label: "reportdetails.detailedInfo.alarmReportSummary",
        },
        children: [
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.summary.location.title",
                    fieldValue: getSiteLocation(report),
                    shouldAutoTranslate: false,
                },
            },
            {
                component: DateSummaryFieldPdf,
                props: {
                    fieldName: "common.dates.onSiteArrivalDateTime",
                    fieldValue: getLocalDateByReferenceTimeAndOffsetDateTime(
                        getFieldValueByFieldName(AlarmResponseFields.timeArrivedOnSite, report),
                        getFieldValueByFieldName(AlarmResponseFields.timeDepartedFromSite, report),
                        report.reportDateTime,
                    ),
                },
            },
            {
                component: DateSummaryFieldPdf,
                props: {
                    fieldName: "common.dates.onSiteDepartureDateTime",
                    fieldValue: formatDateTimeByTimezone(
                        report.reportDateTime.split("T")[0],
                        getFieldValueByFieldName(AlarmResponseFields.timeDepartedFromSite, report),
                        report.reportDateTime,
                    ),
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.onSiteContact",
                    fieldValue: getFieldValueByFieldName(AlarmResponseFields.onSiteContact, report),
                    shouldAutoTranslate: false,
                },
            },
            ...getPdfUnknownFields(report, Object.values(AlarmResponseFields), images),
        ],
    },
];
