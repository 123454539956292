import { Stack } from "@secuis/ccp-react-components";

import { BlueCards } from "../../../components/Insights/Cards/BlueCards";
import { TrendingEvents } from "./TrendingEvents";

export const Trends = () => {
    return (
        <Stack direction="column" gap="M">
            <TrendingEvents />
            <BlueCards />
        </Stack>
    );
};
