import { ToasterContext } from "@secuis/ccp-react-components";
import { useCallback, useContext, useEffect, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { formatToRawDate, getDateStringByTimezone } from "src/helpers/date";

import { useHubContext } from "../../hooks/CommonHooks";
import { useFilterableReportTypes } from "../../hooks/ReportTypeHooks";
import { useSessionState } from "../../hooks/SessionStateHook";
import { useTracking } from "../../hooks/TrackingHooks";
import { NotificationContextModel, NotificationType } from "../../models/NotificationModel";
import { customReportFilterOption, ReportTemplateEnum } from "../../models/ReportModel";
import { useReportCategories } from "../reportCategories/ReportCategoriesHooks";
import UserSelectors from "../user/UserSelectors";
import AppSelectors from "./AppSelectors";

export const useSharedRedirection = () => {
    const navigate = useNavigate();
    const redirectableContextTypes = useMemo(
        () => [
            NotificationType.ReportShareRedirect,
            NotificationType.TourSessionShareRedirect,
            NotificationType.CommentMention,
            NotificationType.TourSessionCommentMention,
            NotificationType.HighIncidentNotification,
            NotificationType.ModerateIncidentNotification,
            NotificationType.SevereIncidentNotification,
        ],
        [],
    );

    const canHandleRedirect = useCallback(
        (context: NotificationContextModel) => {
            return context && redirectableContextTypes.includes(context.type);
        },
        [redirectableContextTypes],
    );

    const handleRedirect = useCallback(
        (context: NotificationContextModel) => {
            if (!canHandleRedirect(context)) {
                return;
            }
            switch (context.type) {
                case NotificationType.CommentMention:
                case NotificationType.ReportShareRedirect:
                case NotificationType.SevereIncidentNotification:
                case NotificationType.HighIncidentNotification:
                case NotificationType.ModerateIncidentNotification: {
                    const newPath = `/locations/${context.locationId}/reports/${context.reportId}?source=${context.type}`;
                    if (location.pathname !== newPath) {
                        navigate(newPath);
                    }
                    break;
                }
                case NotificationType.TourSessionCommentMention:
                case NotificationType.TourSessionShareRedirect: {
                    const newPath = `/locations/${context.locationId}/reports/${context.tourSessionId}?source=${context.type}&type=${ReportTemplateEnum.patrolTour}`;
                    if (location.pathname !== newPath) {
                        navigate(newPath);
                    }
                    break;
                }
                default: {
                    return;
                }
            }
        },
        [canHandleRedirect, navigate],
    );

    return { canHandleRedirect, handleRedirect };
};

export const useNotificationContext = (): void => {
    const { hubContext } = useHubContext();
    const navigate = useNavigate();
    const location = useLocation();
    const { filterableReportTypes } = useFilterableReportTypes();

    useEffect(() => {
        if (!hubContext) {
            return;
        }
        const context: NotificationContextModel = JSON.parse(window.atob(hubContext));

        if (context.type === NotificationType.DailyReportSummary) {
            const { startTime, endTime } = context;
            const start = formatToRawDate(new Date(getDateStringByTimezone(startTime)));
            const end = formatToRawDate(new Date(getDateStringByTimezone(endTime)));
            const types = filterableReportTypes.filter((type) => type !== ReportTemplateEnum.patrolTour).join(",");

            const newPath = `/reports?selectedStartDate=${start}&selectedEndDate=${end}&selectedReportTypes=${types},${customReportFilterOption}&showOnlyUnread=true`;
            if (location.pathname !== newPath) {
                navigate(newPath);
            }
        }
    }, [hubContext]);
};

export const useApplicationData = (): {
    loaded: boolean;
    loadApplicationData: () => void;
} => {
    const { info: user } = useSelector(UserSelectors.getUserInfo);
    const { stateInitialized, loadSessionState } = useSessionState();
    const { loadReportCategories } = useReportCategories();
    const { initializeTracking } = useTracking();
    const initializedRef = useRef(false);

    const loadApplicationData = useCallback(() => {
        if (!initializedRef.current) {
            loadReportCategories();
            initializeTracking();
            loadSessionState(user?.id);
            initializedRef.current = true;
        }
    }, [user, initializeTracking, loadReportCategories, loadSessionState]);

    return { loadApplicationData, loaded: initializedRef.current && stateInitialized };
};

export const useInAppNotifications = () => {
    const { addToast } = useContext(ToasterContext);
    const inAppNotifications = useSelector(AppSelectors.getInAppNotifications);
    const { errorInfo } = useSelector(AppSelectors.getErrors);

    useEffect(() => {
        if (inAppNotifications.length) {
            const lastNotification = inAppNotifications[inAppNotifications.length - 1];
            addToast(lastNotification);
        }
        if (errorInfo.length) {
            const lastError = errorInfo[errorInfo.length - 1];
            addToast({
                title: lastError.name,
                message: lastError.message,
                type: "high",
            });
        }
        // addToast from SCL is recreated on each render making this effect stuck in infinite loop
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inAppNotifications, errorInfo]);
};
