import { Badge, Box, Breakpoints, Icon, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { getFullAddressOrCountry, ISiteObject } from "../../../models/SiteObjectModel";

type CardsIndicatorProps = {
    incidentsAmount: number;
    isSite: boolean;
};

export const CardsIndicator = ({ incidentsAmount, isSite }: CardsIndicatorProps) => {
    const { t } = useTranslation();

    return (
        <Box>
            <Stack alignItems="center" gap="XS">
                {!incidentsAmount ? (
                    <>
                        <Icon size="S" variant="StatusNew" color="secondary" />
                        <Text small bold>
                            {isSite ? t("insights.map.noIncidentsSite") : t("insights.map.noIncidentsArea")}
                        </Text>
                    </>
                ) : (
                    <>
                        <Badge color="high" label={`${incidentsAmount}`} />
                        <Text small bold>
                            {isSite ? t("insights.map.incidentsSite", { count: incidentsAmount }) : t("insights.map.incidentsArea", { count: incidentsAmount })}
                        </Text>
                    </>
                )}
            </Stack>
        </Box>
    );
};

type SiteDetailsProps = {
    site: ISiteObject;
    incidents: {
        location_id: string;
        count: number;
    }[];
    indicatorAlign?: "vertical" | "horizontal";
};

export const SiteDetails = ({ site, incidents = [], indicatorAlign = "horizontal" }: SiteDetailsProps) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const incidentsAmount = useMemo(() => {
        return incidents.find((incident) => incident.location_id === site.id)?.count || 0;
    }, [site, incidents]);

    const SiteStatus = useMemo(() => {
        if (!isMobile) {
            return <Icon size="S" variant="BuildingFilled" />;
        }
        if (incidentsAmount > 0) {
            return <Badge color="high" label={`${incidentsAmount}`} />;
        }
        return <Icon size="S" variant="StatusNew" color="secondary" />;
    }, [incidentsAmount, isMobile]);

    return (
        <Box data-testid="map-site-details" marginRight="M">
            <Stack alignItems="center" gap="S" marginBottom="XXS">
                <Stack alignItems="center" gap="XS">
                    {SiteStatus}
                    <Text small bold>
                        {site.name}
                    </Text>
                </Stack>
                {!isMobile && indicatorAlign === "horizontal" && <CardsIndicator isSite incidentsAmount={incidentsAmount} />}
            </Stack>
            <Stack marginBottom="XXS">
                <Text color="secondary" micro>
                    {getFullAddressOrCountry(site)}
                </Text>
            </Stack>
            {!isMobile && indicatorAlign === "vertical" && <CardsIndicator isSite incidentsAmount={incidentsAmount} />}
        </Box>
    );
};
