import { Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { flexColumn } from "../../../styles/templates/Flex";

export const Tile = styled.div`
    ${flexColumn}
    border-radius: ${Spacing.XXS}px;
    background-color: ${Palette.Navy750};
    padding: ${Spacing.S}px;

    @media (max-width: ${Breakpoints.XS}) {
        padding-left: ${Spacing.XS}px;
        padding-right: ${Spacing.XS}px;
    }
`;

export const TileContent = styled.div`
    background-color: ${Palette.Navy800};
    border-radius: ${Spacing.XXS}px;
    padding: ${Spacing.L}px;

    @media (max-width: ${Breakpoints.XS}) {
        padding: ${Spacing.S}px;
    }
`;

export const TileHeaderLinkWrapper = styled(Stack)`
    text-transform: uppercase;

    @media (max-width: ${Breakpoints.XS}) {
        align-self: end;
    }
`;
