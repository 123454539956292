import { Breakpoints, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { ReactComponent as SwitchIcon } from "../../assets/icons/switcher_icon.svg";
import { clearUserData } from "../../helpers/SessionStorageHelper";
import ClientSwitcherActions from "../../store/clientSwitcher/ClientSwitcherActions";
import ClientSwitcherSelectors from "../../store/clientSwitcher/ClientSwitcherSelectors";
import { BannerWrapper } from "./SwitcherBanner.styled";

export const SwitcherBanner = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const selectedClient = useSelector(ClientSwitcherSelectors.getSelectedClient);
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const navigate = useNavigate();

    const handleSwitchClient = () => {
        dispatch(ClientSwitcherActions.resetClients());
        clearUserData();
        navigate("/");
    };

    return (
        <BannerWrapper data-testid="switcher-banner-viewingAs" direction="row" pv="XS" ph="L" mh={isMobile ? "XS" : "0"} alignItems="center" mt="S">
            <Stack gap={isMobile ? "0" : "M"} direction={isMobile ? "column" : "row"} alignItems={isMobile ? "flex-start" : "center"}>
                <Text color="dark" bold uppercase micro>
                    {t("switcher.banner.viewingAs")}
                </Text>
                <Text bold color="dark">
                    {selectedClient.name}
                </Text>
            </Stack>
            <button data-testid="handle-switch-client-button" aria-label={t("switcher.banner.openSwitcher")} onClick={handleSwitchClient}>
                <SwitchIcon aria-hidden />
            </button>
        </BannerWrapper>
    );
};
