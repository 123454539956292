import { differenceInDays, subDays } from "date-fns";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

type MatrixPeriod = {
    start: Date;
    end: Date;
};

type useMatrixPeriodResult = {
    currentPeriod: MatrixPeriod;
    previousPeriod: MatrixPeriod;
};

export const useMatrixPeriod = (): useMatrixPeriodResult => {
    const currentPeriodStart = useSelector(InsightsSelectors.getSelectedStartDate);
    const currentPeriodEnd = useSelector(InsightsSelectors.getSelectedEndDate);

    const { previousPeriodStart, previousPeriodEnd } = useMemo(() => {
        return calculatePreviousPeriod(currentPeriodStart, currentPeriodEnd);
    }, [currentPeriodStart, currentPeriodEnd]);

    return {
        currentPeriod: { start: currentPeriodStart, end: currentPeriodEnd },
        previousPeriod: { start: previousPeriodStart, end: previousPeriodEnd },
    };
};

const calculatePreviousPeriod = (start: Date, end: Date): { previousPeriodStart: Date; previousPeriodEnd: Date } => {
    const daysDifference = Math.max(differenceInDays(end, start), 0);
    const previousPeriodEnd = start;
    const previousPeriodStart = subDays(previousPeriodEnd, daysDifference);

    return { previousPeriodStart, previousPeriodEnd };
};
