import { IsoStringDate } from "src/helpers/date";
import { WorkerExecResult } from "src/models/DbModel";
import { generateStringForQuery } from "src/sql/scripts/queryUtilities";

import { getReportQueryTimeRangeCondition, WithSiteZoneQueryParams } from "../../../../sql/scripts/queryUtilities";
import { IncidentData } from "./Matrix.types";

type Site = {
    id: string;
    total: number;
    max: number;
};

type MatrixDataQueryResult = {
    site: Site;
    [key: string]: Site | IncidentData;
};

export const getMatrixDataQuery = ({
    startDate,
    endDate,
    categories,
    level,
    withSiteZone = false,
}: {
    startDate: IsoStringDate;
    endDate: IsoStringDate;
    categories: string[];
    level: number;
} & WithSiteZoneQueryParams) => {
    return {
        sql: `
            SELECT
                location_id,
                ${categories.map((category) => `COUNT(CASE WHEN category_level${level} = '${category}' THEN 1 END) AS ${category}`).join(", ")}
            FROM reports
            WHERE ${getReportQueryTimeRangeCondition("$startDate", "$endDate", withSiteZone)}
            GROUP BY location_id
        `,
        params: {
            $startDate: startDate,
            $endDate: endDate,
        },
    };
};

export const parseMatrixDataQueryResult = ({ results }: WorkerExecResult): MatrixDataQueryResult[] => {
    const columns = results[0]?.columns;
    const values = results[0]?.values;

    if (!values?.length) {
        return [];
    }

    return values.map((valueRow) => {
        let totalIncidentCount = 0;
        let maxIncidentCount = 0;

        const categories = columns.reduce(
            (acc, columnName, index) => {
                if (columnName !== "location_id") {
                    const categoryIncidentCount = valueRow[index] as number;
                    totalIncidentCount += categoryIncidentCount;
                    if (categoryIncidentCount > maxIncidentCount) {
                        maxIncidentCount = categoryIncidentCount;
                    }

                    acc[columnName] = {
                        categoryIncidentCount: categoryIncidentCount,
                        totalIncidentCount: 0,
                    };
                }
                return acc;
            },
            {} as { [key: string]: { categoryIncidentCount: number | string; totalIncidentCount: number } },
        );

        // assign calculated totalIncidentCount value to each category
        Object.keys(categories).forEach((key) => {
            categories[key].totalIncidentCount = totalIncidentCount;
        });

        const site = {
            id: valueRow[columns.indexOf("location_id")],
            total: totalIncidentCount,
            max: maxIncidentCount,
        };

        const siteData = {
            site,
            ...categories,
        };

        return siteData;
    });
};

export const getIncidentCountQuery = ({
    startDate,
    endDate,
    categories,
    level,
    siteIds,
    withSiteZone = false,
}: {
    startDate: IsoStringDate;
    endDate: IsoStringDate;
    categories: string[];
    level: number;
    siteIds: string[];
} & WithSiteZoneQueryParams) => {
    return {
        sql: `
            SELECT
                COUNT(*) AS total_incidents
            FROM reports
            WHERE
                ${getReportQueryTimeRangeCondition("$startDate", "$endDate", withSiteZone)}
                AND location_id IN (${generateStringForQuery(siteIds)})
                AND category_level${level} IN (${generateStringForQuery(categories)})
        `,
        params: {
            $startDate: startDate,
            $endDate: endDate,
        },
    };
};

export const parseIncidentCountQuery = ({ results }: WorkerExecResult): { totalIncidents: number } => {
    return {
        totalIncidents: results?.[0]?.values?.[0]?.[0] ?? 0,
    };
};
