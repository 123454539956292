import { PropsWithChildren } from "react";
import { QaProps } from "src/models";
import { CSSProperties } from "styled-components";

import { StackStyled } from "./WidgetBase.styles";

type Props = PropsWithChildren &
    QaProps & {
        style?: CSSProperties;
        className?: string;
    };

export const WidgetBase = (props: Props) => {
    return <StackStyled {...props} />;
};
