import { createReducer } from "@reduxjs/toolkit";

import { RequestStatus } from "../RequestStatus";
import {
    CLEAR_CLIENTS_SEARCH,
    GET_AVAILABLE_CLIENTS_FAILURE,
    GET_AVAILABLE_CLIENTS_REQUEST,
    GET_AVAILABLE_CLIENTS_SUCCESS,
    GET_AVAILABLE_CLIENTS_TOTAL_COUNT_FAILURE,
    GET_AVAILABLE_CLIENTS_TOTAL_COUNT_REQUEST,
    GET_AVAILABLE_CLIENTS_TOTAL_COUNT_SUCCESS,
    GET_CLIENT_AUTHORIZATIONS_FAILURE,
    GET_CLIENT_AUTHORIZATIONS_REQUEST,
    GET_CLIENT_AUTHORIZATIONS_SUCCESS,
    IClientSwitcherState,
    IS_USER_AUTHORIZED_AT_FAILURE,
    IS_USER_AUTHORIZED_AT_REQUEST,
    IS_USER_AUTHORIZED_AT_SUCCESS,
    SELECT_CLIENT,
} from "./types";

export const defaultState: IClientSwitcherState = {
    initialClientCount: 0,
    initialStatus: RequestStatus.undefined,
    availableClients: {
        status: RequestStatus.undefined,
        totalCount: 0,
        results: [],
        skip: 0,
    },
    selectedClient: null,
    userAuthorizations: {},
    clientAuthorizations: {
        data: [],
        status: RequestStatus.undefined,
    },
};

export const clientSwitcherReducer = createReducer(defaultState, {
    [GET_AVAILABLE_CLIENTS_REQUEST]: (state) => {
        state.availableClients.status = RequestStatus.loading;
    },
    [GET_AVAILABLE_CLIENTS_SUCCESS]: (state, action) => {
        state.availableClients.status = RequestStatus.success;
        state.availableClients.totalCount = action.payload.totalCount;
        state.availableClients.results = action.meta.skip ? [...state.availableClients.results, ...action.payload.results] : action.payload.results;
        state.availableClients.skip = action.meta.skip;
    },
    [GET_AVAILABLE_CLIENTS_FAILURE]: (state) => {
        state.availableClients.status = RequestStatus.error;
    },
    [GET_AVAILABLE_CLIENTS_TOTAL_COUNT_REQUEST]: (state) => {
        state.initialStatus = RequestStatus.loading;
    },
    [GET_AVAILABLE_CLIENTS_TOTAL_COUNT_SUCCESS]: (state, action) => {
        state.initialStatus = RequestStatus.success;
        state.availableClients.results = action.payload.results;
        state.initialClientCount = action.payload.totalCount;
        if (action.payload.totalCount === 1) {
            state.selectedClient = action.payload.results[0];
        }
    },
    [GET_AVAILABLE_CLIENTS_TOTAL_COUNT_FAILURE]: (state) => {
        state.initialStatus = RequestStatus.error;
    },
    [SELECT_CLIENT]: (state, action) => {
        state.selectedClient = action.payload;
        state.userAuthorizations = {};
    },
    [CLEAR_CLIENTS_SEARCH]: (state) => {
        state.availableClients.results = [];
        state.availableClients.skip = 0;
        state.availableClients.status = RequestStatus.undefined;
        state.availableClients.totalCount = 0;
    },
    [IS_USER_AUTHORIZED_AT_REQUEST]: (state, action) => {
        state.userAuthorizations[action.meta.locationId] = { authorized: false, status: RequestStatus.loading };
    },
    [IS_USER_AUTHORIZED_AT_FAILURE]: (state, action) => {
        state.userAuthorizations[action.meta.locationId] = { authorized: false, status: RequestStatus.error };
    },
    [IS_USER_AUTHORIZED_AT_SUCCESS]: (state, action) => {
        state.userAuthorizations[action.meta.locationId] = { authorized: action.payload.length > 0, status: RequestStatus.success };
    },
    [GET_CLIENT_AUTHORIZATIONS_REQUEST]: (state) => {
        state.clientAuthorizations = { data: [], status: RequestStatus.loading };
    },
    [GET_CLIENT_AUTHORIZATIONS_FAILURE]: (state) => {
        state.clientAuthorizations = { data: [], status: RequestStatus.error };
    },
    [GET_CLIENT_AUTHORIZATIONS_SUCCESS]: (state, action) => {
        state.clientAuthorizations = { data: action.payload ?? [], status: RequestStatus.success };
    },
});

export default clientSwitcherReducer;
