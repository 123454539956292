import { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { createContext } from "src/helpers/createContext";

import { useCategories } from "./Matrix/hooks/useCategories";

enum MatrixCategoryLevel {
    Level1 = 1,
    Level2 = 2,
    Level3 = 3,
}

const [IncidentMatrixContext, useIncidentMatrixContext] = createContext<{
    matrixCategory: string;
    matrixCategoryLevel: MatrixCategoryLevel;
    setMatrixCategory: (categoryKey: string) => void;
}>({
    defaultValue: {
        matrixCategory: "",
        matrixCategoryLevel: MatrixCategoryLevel.Level1,
        setMatrixCategory: () => {},
    },
    providerName: "IncidentMatrixProvider",
});

export const IncidentMatrixProvider = ({ children }: PropsWithChildren) => {
    const [matrixCategory, setMatrixCategory] = useState("");
    const [matrixCategoryLevel, setMatrixCategoryLevel] = useState(MatrixCategoryLevel.Level1);
    const { level } = useCategories(matrixCategory);

    const contextValue = useMemo(() => ({ matrixCategory, matrixCategoryLevel, setMatrixCategory }), [matrixCategory, matrixCategoryLevel, setMatrixCategory]);

    useEffect(() => {
        setMatrixCategoryLevel(level);
    }, [matrixCategory]);

    return <IncidentMatrixContext.Provider value={contextValue}>{children}</IncidentMatrixContext.Provider>;
};

export { useIncidentMatrixContext };
