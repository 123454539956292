import { PropsWithChildren } from "react";
import { useSelector } from "react-redux";

import { AuthAppContainer } from "./Authentication.styles";
import { useAuthContext } from "./components/AuthGate";
import { OnboardingModalProvider } from "./components/Onboarding/OnboardingModalProvider";
import { Loading } from "./components/shared/Loading";
import ClientSwitcherPage from "./pages/ClientSwitcher/ClientSwitcherPage";
import { useInAppNotifications, useNotificationContext } from "./store/app/AppHooks";
import ClientSwitcherSelectors from "./store/clientSwitcher/ClientSwitcherSelectors";

export const ClientSwitcher: React.FC<PropsWithChildren> = ({ children }) => {
    const { isAuthDataFetched } = useAuthContext();
    const selectedClient = useSelector(ClientSwitcherSelectors.getSelectedClient);
    const isLoading = !isAuthDataFetched;
    const shouldDisplaySwitcher = isAuthDataFetched && !selectedClient;

    useNotificationContext();
    useInAppNotifications();
    return (
        <AuthAppContainer>
            {isLoading ? <Loading /> : <OnboardingModalProvider>{shouldDisplaySwitcher ? <ClientSwitcherPage /> : children}</OnboardingModalProvider>}
        </AuthAppContainer>
    );
};
