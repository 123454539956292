import { useSqlQuery } from "src/sql/hooks";
import { useFilteredSites } from "src/store/insights/FilterHooks";

import { getIncidentCountQuery, parseIncidentCountQuery } from "../Matrix.queries";
import { useCategories, useMatrixPeriod } from ".";

type useMatrixIncidentCountProps = {
    categoryKey: string;
};

type useMatrixIncidentCountResult = {
    isLoading: boolean;
    currentPeriodIncidentCount: number;
    previousPeriodIncidentCount: number;
};

export const useMatrixIncidentCount = ({ categoryKey }: useMatrixIncidentCountProps): useMatrixIncidentCountResult => {
    const { currentPeriod, previousPeriod } = useMatrixPeriod();
    const { categories, level } = useCategories(categoryKey);
    const { siteIds } = useFilteredSites();

    const { isLoading: isCurrentPeriodLoading, queryResult: currentPeriodIncidentCount } = useSqlQuery(getIncidentCountQuery, parseIncidentCountQuery, {
        startDate: currentPeriod.start.toISOString(),
        endDate: currentPeriod.end.toISOString(),
        categories,
        level,
        siteIds,
    });

    const { isLoading: isPreviousPeriodLoading, queryResult: previousPeriodIncidentCount } = useSqlQuery(getIncidentCountQuery, parseIncidentCountQuery, {
        startDate: previousPeriod.start.toISOString(),
        endDate: previousPeriod.end.toISOString(),
        categories,
        level,
        siteIds,
    });

    const isLoading = isCurrentPeriodLoading || isPreviousPeriodLoading;

    return {
        isLoading,
        currentPeriodIncidentCount: previousPeriodIncidentCount?.totalIncidents ?? 0,
        previousPeriodIncidentCount: currentPeriodIncidentCount?.totalIncidents ?? 0,
    };
};
