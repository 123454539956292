import { Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import { styled } from "styled-components";

export const WrapperStyled = styled(Stack).attrs({
    ph: "M",
    gap: "S",
    alignItems: "center",
})<{ isDisabled: boolean }>`
    height: 100%;
    background-color: ${(props) => (props.isDisabled ? Palette.Navy550 : Palette.Purple300)};
    border-top-right-radius: ${Spacing.XXS}px;
    border-bottom-right-radius: ${Spacing.XXS}px;
`;
