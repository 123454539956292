import { IncidentsLocationsType } from "../../store/insights/InsightsModel";

export const mapIncidentsLocationsToColors = (
    incidentsLocations: IncidentsLocationsType[],
    colorsMap: { [k: string]: string },
): (IncidentsLocationsType & { sourceName: string; color: string })[] => {
    if (incidentsLocations) {
        const dataWithColors = Object.entries(colorsMap).map(([name, color]) => {
            const itemName = name.split(". ")[1];
            const incident = incidentsLocations.find((location) => location?.name === name);
            // if no incidents in location: value = 0
            const value = incident?.value || 0;

            return {
                name: itemName,
                sourceName: name,
                color: color,
                value,
            };
        });
        return dataWithColors.sort((a, b) => {
            if (a.value > b.value) {
                return -1;
            }
            return 1;
        });
    }
    return [];
};
