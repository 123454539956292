import { Breakpoints, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { get } from "lodash";
import { useTranslation } from "react-i18next";
import { Line, LineChart, ResponsiveContainer, Tooltip, YAxis } from "recharts";
import { formatDate } from "src/helpers/date";
import styled from "styled-components";

import { TooltipStyled } from "../../Graphs/CustomizedGraphTooltip.styles";

const GraphTooltipStyled = styled(TooltipStyled)`
    transform: translate(calc(-50% - 10px), calc(-100% - 20px));
`;

type TooltipProps = {
    value: number;
    date: string;
};

const ChartTooltip = ({ value, date }: TooltipProps) => {
    const { t } = useTranslation();

    return (
        <GraphTooltipStyled visible>
            {date && (
                <Text small bold>
                    {formatDate(new Date(date), "longDate")}
                </Text>
            )}
            <Text small>
                {t("insights.siteTasks.category.deviations")}: {value}
            </Text>
        </GraphTooltipStyled>
    );
};

export const PatrolDeviationsChart = ({ data = [], topAmount }) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const renderTooltip = (payload) => {
        if (!payload?.payload?.length) {
            return null;
        }
        const { name, value = 0 } = get(payload, "payload.0.payload", {});

        return <ChartTooltip value={value} date={name} />;
    };

    return (
        <ResponsiveContainer width="99%" height={isMobile ? 18 : 32}>
            <LineChart
                data={data.map((d) => ({ name: d.date, value: d.value.count }))}
                margin={{
                    top: 5,
                    left: 0,
                    right: 0,
                    bottom: 5,
                }}
            >
                {!isMobile && <Tooltip allowEscapeViewBox={{ x: true, y: true }} content={renderTooltip} cursor={false} wrapperStyle={{ outline: "none" }} />}
                <YAxis domain={[0, topAmount]} hide />
                <Line dot={false} type="linear" dataKey="value" stroke="#F7F7F7" strokeWidth={1} isAnimationActive={false} />
            </LineChart>
        </ResponsiveContainer>
    );
};
