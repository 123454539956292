import { getTimezoneOffset, isAfter, subDays, zonedTimeToUtc } from "src/helpers/date";

const getTime = (hours: string, minutes: string) => `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;

const trimTimeTz = (timeLiteral: string) => {
    const match = RegExp(/^(\d{1,2}:\d{1,2})\s*([ap]m)?\s*\w*$/i).exec(timeLiteral);
    if (!match) {
        return timeLiteral;
    }
    const time = match[1];
    const time12hAbbr = match[2];
    return time12hAbbr ? `${time} ${time12hAbbr.toLowerCase()}` : time;
};

const formatTime = (timeLiteral: string): string => {
    const trimmedTime = trimTimeTz(timeLiteral);
    const isAM = trimmedTime.toLowerCase().endsWith("am");
    const isPM = trimmedTime.toLowerCase().endsWith("pm");
    if ((isAM || isPM) && /(\d{1,2}):(\d{1,2})/.test(trimmedTime)) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, hours, minutes] = RegExp(/(\d{1,2}):(\d{1,2})/).exec(trimmedTime);
        let formattedHours = hours;
        if (isPM) {
            const numHours = Number(hours);
            if (numHours === 12) {
                formattedHours = "00";
            } else {
                formattedHours = `${Number(hours) + 12}`;
            }
        }
        if (isAM && hours === "12") {
            formattedHours = "00";
        }
        return getTime(formattedHours, minutes);
    }

    const time24h = trimmedTime.split(":");
    if (time24h[0] && time24h[1]) {
        return getTime(time24h[0], time24h[1]);
    }

    return trimmedTime;
};

const getDateTime = (date: string, time: string): Date => new Date(`${date}T${formatTime(time)}`);

export const formatDateTimeByTimezone = (localDateLiteral: string, localTimeLiteral: string, offsetDateLiteral: string): Date | null => {
    if (!localDateLiteral || !localTimeLiteral || !offsetDateLiteral) {
        return null;
    }
    const [timeZone] = offsetDateLiteral.match(/(.)(\d{2}:\d{2})$/) || [null];
    const timeZoneOffset = getTimezoneOffset(timeZone);
    const parsedDateTime = getDateTime(localDateLiteral, localTimeLiteral);
    const dateTimeByTimezoneOffset = zonedTimeToUtc(parsedDateTime, "UTC").getTime() - timeZoneOffset;

    return new Date(dateTimeByTimezoneOffset);
};

export const getLocalDateByReferenceTimeAndOffsetDateTime = (time: string, timeToCompare: string, offsetDateTime: string): Date | null => {
    if (!time || !timeToCompare || !offsetDateTime) {
        return null;
    }
    const [date] = offsetDateTime.split("T");
    const dateTimeCompare = formatDateTimeByTimezone(date, timeToCompare, offsetDateTime);
    const dateTime = formatDateTimeByTimezone(date, time, offsetDateTime);

    if (isAfter(dateTime, dateTimeCompare)) {
        return subDays(dateTime, 1);
    }
    return dateTime;
};
