import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { createDateFromRawString, formatDate, formatTime, isWithinInterval, subMonths } from "src/helpers/date";
import { exportReportsToExcelSlice } from "src/store/reports/exportReportsToExcelSlice";
import { useLazyFetchReportsToExportQuery, useLazyPrefetchReportsToExportQuery } from "src/store/reports/reportsApi";
import { utils, writeFileXLSX, writeXLSX } from "xlsx";

import { featureFlags } from "../data/featureFlags";
import { ISearchReportsRequestBody } from "../models/ReportFilterModels";
import { reportTypeTranslation, SiteZoneEnum } from "../models/ReportModel";
import AccessSelectors from "../store/access/AccessSelectors";
import AppSelectors from "../store/app/AppSelectors";
import { useFilteredSites, useReportTypeItems } from "../store/filter/FilterHooks";
import FilterSelectors from "../store/filter/FilterSelectors";
import LocationsSelector from "../store/locations/LocationsSelectors";
import ReportsSelectors from "../store/reports/ReportsSelectors";
import { useFeatureFlag } from "./featureFlags";

export const useExport = () => {
    const { t } = useTranslation();
    const locations = useSelector(LocationsSelector.getAuthorizedLocations);
    const { exportState, totalCount, reports } = useSelector(ReportsSelectors.getExportReportsStore);
    const [prefetchReports] = useLazyPrefetchReportsToExportQuery();
    const [fetchReports] = useLazyFetchReportsToExportQuery();
    const dispatch = useDispatch();
    const userId = useSelector(AccessSelectors.getUserId);
    const { siteIds: selectedSitesIds } = useFilteredSites();
    const { getReportTypeFilterSelection } = useReportTypeItems();
    const reportListFilters = useSelector(FilterSelectors.getAllFilters);
    const isInNativeHubEmbedded = useSelector(AppSelectors.getIsEmbeddedInNativeHub);
    const areSitesTimezonesEnabled = useFeatureFlag(featureFlags.sitesTimezones);

    const searchParams = useMemo(() => {
        return {
            locationIds: selectedSitesIds,
            reportCategories1: reportListFilters.selectedCategories1,
            reportCategories2: reportListFilters.selectedCategories2,
            reportCategories3: reportListFilters.selectedCategories3,
            reportTypes: reportListFilters.selectedReportTypes,
            siteLocations: reportListFilters.selectedSiteLocations,
            severityLevels: reportListFilters.selectedSeverityLevels,
            fromDateTime: reportListFilters.selectedStartDate,
            toDateTime: reportListFilters.selectedEndDate,
            excludedReportTypes: [],
        };
    }, [selectedSitesIds, reportListFilters]);

    const body: ISearchReportsRequestBody = useMemo(() => {
        const reportTypeFilter = getReportTypeFilterSelection(searchParams.reportTypes);
        return {
            locationIds: selectedSitesIds,
            userId,
            reportCategories1: searchParams.reportCategories1,
            reportCategories2: searchParams.reportCategories2,
            reportCategories3: searchParams.reportCategories3,
            reportTypes: reportTypeFilter.selectedReportTypes,
            excludedReportTypes: reportTypeFilter.excludedReportTypes,
            siteLocations: searchParams.siteLocations,
            severityLevels: searchParams.severityLevels,
            from: searchParams.fromDateTime,
            to: searchParams.toDateTime,
            isReadReport: null,
        };
    }, [getReportTypeFilterSelection, searchParams, userId, selectedSitesIds]);

    const keys = useMemo(
        () => ({
            reportType: t("filters.title.reportType"),
            site: t("reports.export.site"),
            categoryLevel1: t("reports.export.category1"),
            categoryLevel2: t("reports.export.category2"),
            categoryLevel3: t("reports.export.category3"),
            siteLocation: t("filters.title.siteLocation"),
            country: t("filters.locations.label"),
            date: t("datepicker.label.date"),
            time: t("reports.export.time"),
        }),
        [t],
    );

    const getReportType = useCallback(
        (type: string) => {
            return reportTypeTranslation[type] ? t(reportTypeTranslation[type]) : type;
        },
        [t],
    );
    const getReportCategory = useCallback((category: string | null) => (category ? t(`incident.category.${category}`) : ""), [t]);
    const getSiteZone = useCallback((siteZone: string | null) => (SiteZoneEnum[siteZone] ? t(`siteLocations.${SiteZoneEnum[siteZone]}`) : siteZone), [t]);

    const saveFile = useCallback(() => {
        const exportReports = reports.map((r) => ({
            [keys.reportType]: getReportType(r.type),
            [keys.site]: locations.siteObjects.find((l) => l.id === r.locationId).name,
            [keys.categoryLevel1]: getReportCategory(r.categoryLevel1),
            [keys.categoryLevel2]: getReportCategory(r.categoryLevel2),
            [keys.categoryLevel3]: getReportCategory(r.categoryLevel3),
            [keys.siteLocation]: getSiteZone(r.siteLocation),
            [keys.country]: t(`country.${r.countryCode.toLowerCase()}`),
            [keys.date]: formatDate(r.reportDateTime),
            [keys.time]: formatTime(r.reportDateTime),
        }));
        const ws = utils.json_to_sheet(exportReports);
        const wb = utils.book_new();
        const fileName = `${t("reports.export.fileName")}.xlsx`;
        utils.book_append_sheet(wb, ws, fileName);

        if (isInNativeHubEmbedded) {
            const base64 = writeXLSX(wb, { type: "base64" });
            const targetOrigin = "/";
            const topic = "FILE_SAVE";
            const message = null;
            const payload = {
                topic,
                message,
                file: { base64, fileName: fileName, type: "application/vnd.ms-excel" },
                timestamp: new Date(),
            };
            window.parent.postMessage(payload, targetOrigin);
        } else {
            writeFileXLSX(wb, `${fileName}`);
        }
        dispatch(exportReportsToExcelSlice.actions.fileCreated());
    }, [dispatch, getReportType, isInNativeHubEmbedded, keys, locations.siteObjects, reports, t]);

    const prefetchReportsToExport = useCallback(() => {
        prefetchReports({ body, useSiteTimezone: areSitesTimezonesEnabled });
    }, [body, prefetchReports, areSitesTimezonesEnabled]);

    const requestReports = useCallback(() => {
        fetchReports({ pageSize: totalCount, body, useSiteTimezone: areSitesTimezonesEnabled });
    }, [body, totalCount, fetchReports, areSitesTimezonesEnabled]);

    const isExceedingThreeMonths = useMemo(() => {
        return !isWithinInterval(createDateFromRawString(reportListFilters.selectedStartDate), {
            start: subMonths(new Date(), 3),
            end: new Date(),
        });
    }, [reportListFilters.selectedStartDate]);

    return {
        requestReports,
        prefetchReportsToExport,
        isExceedingThreeMonths,
        exportState,
        totalCount,
        saveFile,
    };
};
