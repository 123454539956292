import { Breakpoints, ButtonIcon, Color, Icon, Stack, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useOnEnterSpaceKeyDown } from "src/hooks/WebAccessibilityHooks";

import { TABLE_TOTAL_KEY } from "../../../../store/insights/InsightsModel";
import { CompareTableParent } from "../../../../store/insights/InsightsPatrolTasksModel";
import { useInsightsPageContext } from "../../InsightsPageProvider";
import { getScannedCheckpointsPercentage, getTrendType } from "../PatrolTasks.helpers";
import {
    CheckpointIconStyled,
    headerStyles,
    indexCellStyles,
    LinkWrapper,
    LocationCellStyled,
    locationCellStyles,
    mobileIndexCellStyles,
    StyledTable,
} from "./Compare.styled";

type Category = "tours" | "deviations" | "checkpoints";

const Cell = ({ cell, row }) => {
    const { count, histCount } = cell || {};
    const isTotal = row?.id === TABLE_TOTAL_KEY;
    // NOTE: According to design requirements - if we add new data column, we should hide checkpoint icon for XS screens
    const showCategoryIcon = useHasMaxWidth(Breakpoints.XS);
    const category = Object.keys(row).filter((rowKey) => row[rowKey] === cell)[0] as Category;
    let currentValue: number;
    let previousValue: number;
    let valueToDisplay: string;

    if (category === "checkpoints") {
        currentValue = getScannedCheckpointsPercentage(count.missed, count.scanned);
        previousValue = getScannedCheckpointsPercentage(histCount.missed, histCount.scanned);
        valueToDisplay = `${currentValue}%`;
    } else {
        currentValue = count as number;
        previousValue = histCount;
        valueToDisplay = `${currentValue}`;
    }

    const trend = getTrendType(previousValue, currentValue);

    return (
        <Stack alignItems="center" gap="XXS">
            {showCategoryIcon && <CategoryIcon category={category} />}
            <Text small={isTotal} bold={isTotal}>
                {valueToDisplay}
            </Text>
            {trend && <Icon size={isTotal ? "S" : "M"} variant={trend} />}
        </Stack>
    );
};

const LocationHeader = (header: string) => {
    const { t } = useTranslation();
    const isSmallScreen = useHasMaxWidth(Breakpoints.S);
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    if (isMobile) {
        return null;
    }

    return isSmallScreen ? <CategoryIcon category={header as Category} color="neutral" /> : t(`insights.compareTable.header.${header}`);
};

const IndexCell = ({ row, onClick, isExpanded }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const handleClick = () => {
        onClick?.();
    };

    const handleNavigate = (to: string) => {
        navigate(to);
    };

    const handleLinkKeyDown = useOnEnterSpaceKeyDown(handleNavigate);

    if (row?.id === TABLE_TOTAL_KEY || !row?.id) {
        return null;
    }

    if (!row?.children) {
        const siteUrl = `/insights/site/${row.siteId}`;
        return (
            <Stack justifyContent="space-between">
                <LinkWrapper
                    onClick={() => handleNavigate(siteUrl)}
                    onKeyDown={(e) => handleLinkKeyDown(e, siteUrl)}
                    tabIndex={0}
                    role="link"
                    aria-label={t("insights.patrolDeviations.openSite")}
                >
                    <Icon color="neutral" variant="ArrowForward" />
                </LinkWrapper>
            </Stack>
        );
    }

    return (
        <Stack justifyContent="space-between">
            <ButtonIcon
                data-testid="arrow-button"
                icon={isExpanded ? "ArrowUp" : "ArrowDown"}
                aria-label={t(isExpanded ? "table.collapseRow" : "table.expandRow")}
                iconSize="M"
                mode="stateless"
                onClick={handleClick}
            />
        </Stack>
    );
};

const LocationCell = ({ row, hasMobileLayout }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    if (row?.id === TABLE_TOTAL_KEY) {
        return (
            <LocationCellStyled>
                <Text small bold color="neutral">
                    {t("insights.exceptionTrend.total_count")}
                </Text>
            </LocationCellStyled>
        );
    }
    if (!row?.children) {
        return (
            <LinkWrapper>
                <LocationCellStyled
                    data-testid="insights-tasks-compare-open-site"
                    gap="XXS"
                    onClick={() => navigate(`/insights/site/${row.siteId}`)}
                    pl={hasMobileLayout ? "0" : "L"}
                >
                    <Icon variant="BuildingFilled" />
                    <Text truncate>{row?.id}</Text>
                </LocationCellStyled>
            </LinkWrapper>
        );
    }

    return (
        <LocationCellStyled>
            <Text truncate>{row?.id + ` (${row?.children.length})`}</Text>
        </LocationCellStyled>
    );
};

type ComparedPropertyKey = keyof Omit<CompareTableParent, "children" | "id">;
type Props = {
    tourSessions: CompareTableParent[];
};

export const CompareTable = ({ tourSessions }: Props) => {
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const isTablet = useHasMaxWidth(Breakpoints.S);
    const { isFilterActive } = useInsightsPageContext();
    const hasMobileLayout = isMobile || (isFilterActive && isTablet);

    if (!tourSessions) {
        return null;
    }

    const compareKeys = getKeysExceptIdAndChildren(tourSessions[0]) as ComparedPropertyKey[];

    const locationIdColumn = {
        header: t("insights.compareTable.header.location"),
        key: "id",
        id: "location-id",
        Cell: ({ row }) => <LocationCell hasMobileLayout={hasMobileLayout} row={row} />,
        style: hasMobileLayout ? locationCellStyles : null,
    };
    const expandButtonColum = {
        key: "button",
        id: "expand-button",
        Cell: IndexCell,
        style: hasMobileLayout ? mobileIndexCellStyles : indexCellStyles,
    };
    const compareTableColumns = compareKeys.map((compareKey) => ({
        header: LocationHeader(compareKey),
        key: compareKey,
        id: compareKey,
        Cell: Cell,
        headerStyle: hasMobileLayout ? headerStyles : null,
    }));

    const columns = hasMobileLayout
        ? [locationIdColumn, expandButtonColum, ...compareTableColumns]
        : [locationIdColumn, ...compareTableColumns, expandButtonColum];

    return <StyledTable isFilterActive={isFilterActive} expandable data={tourSessions} columns={columns} />;
};

const getKeysExceptIdAndChildren = <T extends object>(object: T): string[] => Object.keys(object).filter((key) => !(key === "id" || key === "children"));

const CategoryIcon = ({ category, color }: { category: Category; color?: Color }) => {
    if (category === "checkpoints") {
        return <CheckpointIconStyled color={color} />;
    }

    return <Icon color={color} variant={category === "tours" ? "Route" : "Deviations"} />;
};
