import { getFieldValueByFieldName, getSiteLocation } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../models/ReportModel";
import { TourMultiExceptionFields } from "../../../models/reportTemplates/TourMultiException";
import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { Description } from "../SharedComponents/Description";
import { ExceptionQuestionsFields } from "../SharedComponents/ExceptionQuestionsField";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";
import { getBaseReportConfig } from "./baseReportConfig";
import { getUnknownFieldsComponents } from "./DynamicTemplateUtils";

export const getTourMultiExceptionReportConfig = ({
    report,
    siteObject,
    sharedReportId,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfig({ report, siteObject, sharedReportId }),
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.tourMultiException.comments",
            fullyTranslated: true,
        },
        children: [
            {
                component: Description,
                props: {
                    description: getFieldValueByFieldName(TourMultiExceptionFields.comments, report),
                    sharedReportId: sharedReportId,
                    shouldAutoTranslate: true,
                },
            },
        ],
    },
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.detailedInfo.tourMultiExceptionSummary",
            collapsedLabel: "reportdetails.summary.moreDetails",
            expandedLabel: "reportdetails.summary.lessDetails",
        },
        children: [
            {
                component: ExceptionQuestionsFields,
                props: {
                    fieldValue: getFieldValueByFieldName(TourMultiExceptionFields.exceptionQuestions, report),
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.summary.checkpoint",
                    fieldValue: getFieldValueByFieldName(TourMultiExceptionFields.checkpoint, report),
                    mobileDirection: "column",
                    shouldAutoTranslate: false,
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.summary.location.title",
                    fieldValue: getSiteLocation(report),
                    mobileDirection: "column",
                    shouldAutoTranslate: false,
                },
            },
            ...getUnknownFieldsComponents(report, sharedReportId, Object.values(TourMultiExceptionFields)),
        ],
    },
];
