import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { IsoStringDate } from "src/helpers/date";
import { getGraphMaxValue, getLettersFromNumber } from "src/helpers/graphs";
import { useSqlQuery } from "src/sql/hooks";
import { useFilteredSites } from "src/store/insights/FilterHooks";
import { useAuthorizedLocations } from "src/store/locations/LocationsHooks";

import type { GraphData } from "../shared/components/Graph/Graph.types";
import { useSummaryPeriod } from "../shared/hooks";
import type { WidgetViewMode } from "../Summary.types";
import { getSiteReportsCountQuery, parseSiteReportsCountResult } from "./SiteReportsWidget.queries";
import { SiteReportsCountData, SiteReportsGraphData } from "./SiteReportsWidget.types";

const CHART_BAR_COUNT = 5;

export const useSiteReportsWidget = () => {
    const [viewMode, setViewMode] = useState<WidgetViewMode>("most");
    const { currentPeriod } = useSummaryPeriod();
    const { siteReportsCountData, isLoading } = useSiteReportsCountQuery(currentPeriod.start, currentPeriod.end);
    const siteReportsGraphData = useSiteReportsGraphData(siteReportsCountData, CHART_BAR_COUNT);
    const isSwitcherVisible = siteReportsCountData?.length > CHART_BAR_COUNT;

    useEffect(() => {
        if (!isSwitcherVisible) {
            setViewMode("most");
        }
    }, [isSwitcherVisible]);

    const handleSetViewMode = (mode: WidgetViewMode) => {
        setViewMode(mode);
    };

    return {
        viewMode,
        graphData: siteReportsGraphData[viewMode],
        isSwitcherVisible,
        isLoading,
        isEmpty: !isLoading && !siteReportsCountData?.length,
        setViewMode: handleSetViewMode,
    };
};

const useSiteReportsCountQuery = (startDate: IsoStringDate, endDate: IsoStringDate) => {
    const { siteIds } = useFilteredSites();
    const queryParams = { siteIds, startDate, endDate };
    const { isLoading, queryResult } = useSqlQuery(getSiteReportsCountQuery, parseSiteReportsCountResult, queryParams);

    return { siteReportsCountData: queryResult, isLoading };
};

const useSiteReportsGraphData = (siteReportsCountData?: SiteReportsCountData, resultCount = 5): SiteReportsGraphData => {
    const { t } = useTranslation();
    const { siteObjects } = useAuthorizedLocations();

    const siteReportsCountGraphData = useMemo(() => {
        const getSiteName = (locationId: string) => {
            const site = siteObjects.find((x) => x.id === locationId);
            return site?.displayName || "";
        };

        const getTooltip = (siteName: string, reportsCount: number) => {
            return [siteName, `${reportsCount} ${reportsCount === 1 ? t("common.reports") : t("common.reports_plural")}`];
        };

        const getGraphData = (data: SiteReportsCountData): GraphData => {
            return data.map((item, index) => {
                const siteName = getSiteName(item.locationId);
                const name = getLettersFromNumber(index);

                return {
                    key: name,
                    name: name,
                    value: item.totalCount,
                    label: siteName,
                    tooltip: getTooltip(siteName, item.totalCount),
                };
            });
        };

        if (!siteReportsCountData?.length) {
            return {
                most: { data: [], maxValue: 0 },
                least: { data: [], maxValue: 0 },
            };
        }

        const mostData = getGraphData(siteReportsCountData.slice(0, resultCount));
        const leastData = getGraphData(siteReportsCountData.slice(-resultCount).reverse());

        return {
            most: {
                data: mostData,
                maxValue: getGraphMaxValue(mostData[0].value),
            },
            least: {
                data: leastData,
                maxValue: getGraphMaxValue(leastData[leastData.length - 1].value),
            },
        };
    }, [siteReportsCountData, resultCount, siteObjects, t]);

    return siteReportsCountGraphData;
};
