import { Breakpoints, Icon, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";

import { getTrend } from "./PatrolTasks.helpers";
import { SectionTitle } from "./PatrolTasks.styled";

type Props = {
    startValue: number;
    endValue: number;
    isAbsoluteValue?: boolean;
};

export const TrendIndicator = ({ startValue, endValue, isAbsoluteValue }: Props) => {
    const { trendResult, iconVariant } = getTrend(startValue, endValue, isAbsoluteValue);
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    return (
        <Stack direction="column" data-testid="trend-indicator">
            <Stack gap="XXS" alignItems="center">
                <SectionTitle bold>{trendResult}</SectionTitle>
                <Icon size={isMobile ? "M" : "L"} color="primary" variant={iconVariant} />
            </Stack>
        </Stack>
    );
};
