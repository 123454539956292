import { createReducer } from "@reduxjs/toolkit";

import { mapToUser } from "../../models/UserModel";
import { RequestStatus } from "../RequestStatus";
import { ISiteObjectsState, SITE_OBJECT_USERS_FAILURE, SITE_OBJECT_USERS_REQUEST, SITE_OBJECT_USERS_SUCCESS } from "./types";

const defaultState: ISiteObjectsState = {
    usersBySiteObjectId: {},
};

const deletedUserStatus = "Deleted";

const siteObjectsReducer = createReducer(defaultState, {
    [SITE_OBJECT_USERS_REQUEST]: (state, action) => {
        state.usersBySiteObjectId[action.meta.locationId] = {
            status: RequestStatus.loading,
            users: [],
        };
    },
    [SITE_OBJECT_USERS_SUCCESS]: (state, action) => {
        state.usersBySiteObjectId[action.meta.locationId] = {
            status: RequestStatus.success,
            users: action.payload.filter((e) => e.user.Status !== deletedUserStatus).map((e) => ({ ...mapToUser(e.user), roles: e.roleName })),
        };
    },
    [SITE_OBJECT_USERS_FAILURE]: (state, action) => {
        state.usersBySiteObjectId[action.meta.locationId] = {
            status: RequestStatus.error,
            users: [],
        };
    },
});

export default siteObjectsReducer;
