import { formatDateTimeByTimezone, getLocalDateByReferenceTimeAndOffsetDateTime } from "../../../helpers/reportTemplates/dynamicTemplateDateTimeHelper";
import { getBooleanValue, getFieldValueByFieldName, getSiteLocation } from "../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../models/ReportModel";
import { AmazonZone6Fields } from "../../../models/reportTemplates/AmazonZone6";
import { DynamicTemplateSection } from "../../shared/Section/DynamicTemplateSection";
import { ConditionalTextField } from "../SharedComponents/ConditionalTextField";
import { DateRangeSummaryField } from "../SharedComponents/DateRangeSummaryField";
import { Description } from "../SharedComponents/Description";
import { TextAreaSummaryField } from "../SharedComponents/TextArea";
import { TextSummaryField } from "../SharedComponents/TextSummaryField";
import { getBaseReportConfig } from "./baseReportConfig";
import { conditionalRender, getUnknownFieldsComponents } from "./DynamicTemplateUtils";

export const getConditionalAnswer = (fieldName: string, report: DynamicReportType): string => {
    const value = getFieldValueByFieldName(fieldName, report);
    if (!value) {
        return value;
    }
    const dashIndex = value.indexOf("-");
    if (dashIndex === -1) {
        return value;
    }
    return value.substring(0, dashIndex).trim();
};

export const getAmazonZone6ReportConfig = ({ report, siteObject, sharedReportId }: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfig({ report, siteObject, sharedReportId }),
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportdetails.amazon.description",
            fullyTranslated: true,
        },
        children: [
            {
                component: Description,
                props: {
                    description: getFieldValueByFieldName(AmazonZone6Fields.description, report),
                    sharedReportId: sharedReportId,
                    imagesData: report.images,
                    shouldAutoTranslate: true,
                },
            },
        ],
    },
    conditionalRender(getFieldValueByFieldName(AmazonZone6Fields.reasonOfIncident, report), () => {
        return {
            component: DynamicTemplateSection,
            props: {
                label: "reportdetails.amazon.reasonOfIncident",
                fullyTranslated: true,
            },
            children: [
                {
                    component: Description,
                    props: {
                        description: getFieldValueByFieldName(AmazonZone6Fields.reasonOfIncident, report),
                        sharedReportId: sharedReportId,
                        imagesData: [],
                        isRequired: false,
                        shouldAutoTranslate: true,
                    },
                },
            ],
        };
    }),
    conditionalRender(getFieldValueByFieldName(AmazonZone6Fields.actionsToBeTakenRecommendation, report), () => {
        return {
            component: DynamicTemplateSection,
            props: {
                label: "reportdetails.amazon.actionsToBeTakenRecommendation",
                fullyTranslated: true,
            },
            children: [
                {
                    component: Description,
                    props: {
                        description: getFieldValueByFieldName(AmazonZone6Fields.actionsToBeTakenRecommendation, report),
                        sharedReportId: sharedReportId,
                        imagesData: [],
                        isRequired: false,
                        shouldAutoTranslate: true,
                    },
                },
            ],
        };
    }),
    {
        component: DynamicTemplateSection,
        props: {
            label: "reportDetails.amazon.summary.reportSummary",
            collapsedLabel: "reportdetails.summary.moreDetails",
            expandedLabel: "reportdetails.summary.lessDetails",
        },
        children: [
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.summary.location.title",
                    fieldValue: getSiteLocation(report),
                    mobileDirection: "column",
                    shouldAutoTranslate: false,
                },
            },
            {
                component: DateRangeSummaryField,
                props: {
                    from: getFieldValueByFieldName(AmazonZone6Fields.dateTimeStarted, report),
                    to: getFieldValueByFieldName(AmazonZone6Fields.dateTimeEnded, report),
                    fieldName: "reportdetails.amazon.summary.eventDuration",
                    desktopDirection: "row",
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportDetails.amazon.summary.title",
                    fieldValue: getFieldValueByFieldName(AmazonZone6Fields.title, report),
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.amazon.summary.whatAlarmsWentOff",
                    fieldValue: getFieldValueByFieldName(AmazonZone6Fields.whatAlarmsWentOff, report),
                    isRequired: false,
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportdetails.amazon.summary.whatCausedTheAlarmsToActivate",
                    fieldValue: getFieldValueByFieldName(AmazonZone6Fields.whatCausedTheAlarmsToActivate, report),
                    isRequired: false,
                    mobileDirection: "column",
                },
            },
            {
                component: TextAreaSummaryField,
                props: {
                    title: "reportdetails.amazon.summary.additionalInformationOnWhatActivatedTheAlarm",
                    content: getFieldValueByFieldName(AmazonZone6Fields.additionalInformationOnWhatActivatedTheAlarm, report),
                    isRequired: false,
                },
            },
            {
                component: ConditionalTextField,
                props: {
                    headerName: "reportdetails.amazon.summary.whereThereAnyDeviationsFromTheProcessDescribedAbove",
                    headerValue: getConditionalAnswer(AmazonZone6Fields.whereThereAnyDeviationsFromTheProcessDescribedAbove, report),
                    optionalContentName: "reportdetails.amazon.summary.ifYesExplainDeviation",
                    optionalContentValue: getFieldValueByFieldName(AmazonZone6Fields.ifYesExplainDeviation, report),
                    isRequired: false,
                },
            },
            {
                component: ConditionalTextField,
                props: {
                    headerName: "reportdetails.amazon.summary.ifADeviationOccurredFromTheExpectedProcess",
                    headerValue: getConditionalAnswer(AmazonZone6Fields.ifADeviationOccurredFromTheExpectedProcess, report),
                    optionalContentName: "reportdetails.amazon.summary.ifYesExplainContribution",
                    optionalContentValue: getFieldValueByFieldName(AmazonZone6Fields.ifYesExplainContribution, report),
                    isRequired: false,
                },
            },
            {
                component: ConditionalTextField,
                props: {
                    headerName: "reportdetails.amazon.summary.wasTheExpectedProcessDescribedInPolicy",
                    headerValue: getConditionalAnswer(AmazonZone6Fields.wasTheExpectedProcessDescribedInPolicy, report),
                    optionalContentName: "reportdetails.amazon.summary.ifYesExplainTheSource",
                    optionalContentValue: getFieldValueByFieldName(AmazonZone6Fields.ifYesExplainTheSource, report),
                    isRequired: false,
                },
            },
            {
                component: TextAreaSummaryField,
                props: {
                    title: "reportdetails.amazon.summary.otherCommentsOrRelevantInformation",
                    content: getFieldValueByFieldName(AmazonZone6Fields.otherCommentsOrRelevantInformation, report),
                    isRequired: false,
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "common.policeInvolved",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonZone6Fields.policeInvolved, report)),
                    isRequired: false,
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "common.medicalsInvolved",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonZone6Fields.medicalServicesInvolved, report)),
                    isRequired: false,
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "common.fireDeptInvolved",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonZone6Fields.fireDepartmentInvolved, report)),
                    isRequired: false,
                    mobileDirection: "column",
                },
            },
            {
                component: DateRangeSummaryField,
                props: {
                    from: getLocalDateByReferenceTimeAndOffsetDateTime(
                        getFieldValueByFieldName(AmazonZone6Fields.timeOnSite, report),
                        getFieldValueByFieldName(AmazonZone6Fields.timeOffSite, report),
                        report.reportDateTime,
                    ),
                    to: formatDateTimeByTimezone(
                        report.reportDateTime.split("T")[0],
                        getFieldValueByFieldName(AmazonZone6Fields.timeOffSite, report),
                        report.reportDateTime,
                    ),
                    fieldName: "reportdetails.amazon.summary.timeOnSite",
                    isRequired: false,
                    desktopDirection: "row",
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "reportDetails.amazon.summary.reference",
                    fieldValue: getFieldValueByFieldName(AmazonZone6Fields.reference, report),
                    isRequired: false,
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "common.supervisorNotified",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonZone6Fields.siteSupervisorNotified, report)),
                    isRequired: false,
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "common.facilityManagementActionReq",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonZone6Fields.facilityManagementActionRequired, report)),
                    isRequired: false,
                    mobileDirection: "column",
                },
            },
            {
                component: TextSummaryField,
                props: {
                    fieldName: "common.socActionRequired",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonZone6Fields.socActionRequired, report)),
                    isRequired: false,
                    mobileDirection: "column",
                },
            },
            ...getUnknownFieldsComponents(report, sharedReportId, Object.values(AmazonZone6Fields)),
        ],
    },
];
