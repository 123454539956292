import { Box, Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const BoxStyled = styled(Box)`
    background: ${Palette.Navy750};
    margin-bottom: ${Spacing.M}px;
    padding: ${Spacing.L}px;
    position: relative;

    @media (max-width: ${Breakpoints.M}) {
        padding: ${Spacing.M}px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        padding: ${Spacing.S}px;
    }
`;
