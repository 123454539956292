import { Text } from "@secuis/ccp-react-components";
import React, { ComponentType, useState } from "react";
import { RuleSet } from "styled-components";

import { CellStyled, HeaderRowStyled, RowGroupStyled, TableStyled } from "./Table.styles";
import { TableRowVariant } from "./Table.types";
import { TableRow } from "./TableRow";

export type Column = {
    id: string;
    header?: string | JSX.Element;
    headerStyle?: RuleSet<object>;
    key: string;
    Cell?: ComponentType<any>;
    HeaderCell?: ComponentType<any>;
    style?: RuleSet<object>;
};

type TableData = { [k: string]: any }[];

export type Props = {
    data: TableData;
    columns: Column[];
    childrenColumns?: Column[];
    /** to be used as styled component to override default styles */
    className?: string;
    expandable?: boolean;
    isAllExpanded?: boolean;
    rowGroupsEnabled?: boolean;
    getRowVariant?: (data: unknown) => TableRowVariant;
    onScroll?: (e: React.UIEvent<HTMLDivElement>) => void;
};

export const TableComponent = ({
    columns,
    data,
    expandable,
    className,
    childrenColumns,
    isAllExpanded,
    rowGroupsEnabled = false,
    getRowVariant,
    onScroll,
}: Props) => {
    const [expandedRows, setExpandedRows] = useState<number[]>([]);

    const toggleRow = (rowIndex: number) => {
        if (expandedRows.includes(rowIndex)) {
            setExpandedRows((prev) => prev.filter((x) => x !== rowIndex));
        } else {
            setExpandedRows((prev) => [...prev, rowIndex]);
        }
    };

    const GroupWrapper = rowGroupsEnabled
        ? ({ children, onScroll = null }) => (
              <RowGroupStyled role="rowgroup" columnCount={columns.length} onScroll={onScroll}>
                  {children}
              </RowGroupStyled>
          )
        : React.Fragment;

    return (
        <TableStyled role="table" className={className} columnCount={columns.length} rowGroupsEnabled={rowGroupsEnabled}>
            <GroupWrapper>
                <HeaderRowStyled role="row">
                    {columns.map(({ id, headerStyle, header, HeaderCell, ...rest }) => (
                        <CellStyled role="columnheader" key={`header-cell-${id}`} justifyContent="flex-start" pb="S" pt="M" styles={headerStyle}>
                            {HeaderCell ? (
                                <HeaderCell column={{ id, headerStyle, header, HeaderCell, ...rest }} header={header} />
                            ) : (
                                <Text small bold color="neutral">
                                    {header || ""}
                                </Text>
                            )}
                        </CellStyled>
                    ))}
                </HeaderRowStyled>
            </GroupWrapper>

            <GroupWrapper onScroll={onScroll}>
                {data.map((d, index) => (
                    <React.Fragment key={index}>
                        <TableRow
                            key={index}
                            data={d}
                            index={index}
                            isParent={true}
                            columns={columns}
                            isExpandable={expandable}
                            isExpanded={expandedRows.includes(index)}
                            toggleRow={toggleRow}
                            getRowVariant={getRowVariant}
                        />
                        {d.children && (isAllExpanded || expandedRows.includes(index))
                            ? d.children.map((child, i) => (
                                  <TableRow
                                      key={i}
                                      data={child}
                                      isParent={false}
                                      index={index}
                                      columns={childrenColumns || columns}
                                      getRowVariant={getRowVariant}
                                  />
                              ))
                            : null}
                    </React.Fragment>
                ))}
            </GroupWrapper>
        </TableStyled>
    );
};
