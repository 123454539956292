import { Box, Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

import { criticalCardHeight } from "../Cards/Cards.styled";

const expandedMobileStyles = css`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    max-height: 100%;
    min-width: 100vw;
`;

const defaultMobileStyles = css`
    min-height: 400px;
    max-height: 400px;
    min-width: auto;
`;

export const MapWrapper = styled(Stack)<{ isMapExpanded: boolean; navHeight: number }>`
    position: relative;
    min-height: 650px;
    max-height: 60vh;
    border: 1px solid rgba(117, 153, 191, 0.3);
    border-radius: 4px;

    & canvas {
        border-radius: 4px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        ${({ isMapExpanded }) => (isMapExpanded ? expandedMobileStyles : defaultMobileStyles)}
        min-height: ${({ navHeight, isMapExpanded }) => (isMapExpanded ? `calc(100% - ${navHeight - 2}px)` : null)};
    }
`;

export const StyledHeader = styled(Stack)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: ${Spacing.S}px;
    background: linear-gradient(180deg, ${Palette.Navy900} 19.27%, rgba(1, 21, 35, 0) 85.94%);
    z-index: 1;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
`;

export const StyledFooter = styled(Box)<{ isMobileMapExpanded: boolean }>`
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    z-index: 1;
    border-radius: ${({ isMobileMapExpanded }) => (isMobileMapExpanded ? `${Spacing.S}px ${Spacing.S}px 0px 0px;` : `${Spacing.XXS}px`)};
    border-top: 2px solid rgba(117, 153, 191, 0.3);
    background-color: ${Palette.Navy900};
`;

export const FooterContentWrapper = styled.div<{ isDetailsExpanded: boolean; maxHeightOverride?: number }>`
    padding: ${Spacing.S}px ${Spacing.S}px 0 ${Spacing.S}px;
    gap: ${Spacing.S}px;
    overflow: ${({ isDetailsExpanded }) => (isDetailsExpanded ? `auto` : "hidden")};
    max-height: ${({ maxHeightOverride }): string => (!maxHeightOverride ? "auto" : `${maxHeightOverride}px`)};
    scrollbar-color: ${Palette.Navy500} ${Palette.Navy700};
    scrollbar-width: thin;
`;

export const ToggleButtonWrapper = styled.div`
    position: absolute;
    top: ${Spacing.S}px;
    right: ${Spacing.S}px;
`;

export const StyledMap = styled.div`
    flex: 1 1 100%;

    .mapboxgl-ctrl-bottom-left,
    .mapboxgl-ctrl-bottom-right {
        display: none;
    }
`;

export const CardsWrapper = styled.div<{ isDetailsExpanded: boolean }>`
    overflow: ${({ isDetailsExpanded }) => (isDetailsExpanded ? "auto" : "hidden")};
    max-height: ${({ isDetailsExpanded }) => (isDetailsExpanded ? `${criticalCardHeight + 16}px` : 0)};
`;

export const TextSkeletonWrapper = styled.div`
    width: 300px;
    margin-bottom: ${Spacing.S}px;
`;

export const DetailsWrapper = styled(Stack)<{ rightMargin: number }>`
    margin-right: ${({ rightMargin }) => (rightMargin ? `${rightMargin}px` : `94px`)};
    @media (max-width: ${Breakpoints.XS}) {
        margin-right: 0;
    }
`;
