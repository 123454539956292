import { Breakpoints } from "@secuis/ccp-react-components";
import queryString from "query-string";
import { MutableRefObject, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import ResizeObserver from "resize-observer-polyfill";

import { getArrayFromString } from "../helpers/ArrayHelper";
import { applyPersistedState } from "../store/filter/FilterActions";
import { IFilterState } from "../store/filter/types";
import ReportCategoriesSelectors from "../store/reportCategories/ReportCategoriesSelectors";
import { IReportCategoriesState } from "../store/reportCategories/types";
import { RequestStatus } from "../store/RequestStatus";
import { useSiteObjectItemsByRegions } from "../store/siteObjects/SiteObjectsHooks";

export const useClickOutsideNode = (onClickOutsideFn: any) => {
    const nodeRef = useRef(null);

    const handleClickOutsideNode = useCallback(
        (event) => {
            if (nodeRef.current.contains(event.target)) {
                return;
            }
            onClickOutsideFn();
        },
        [onClickOutsideFn],
    );

    useEffect(() => {
        // add when mounted
        document.addEventListener("mousedown", handleClickOutsideNode);
        // return function to be called when unmounted
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideNode);
        };
    }, [handleClickOutsideNode]);

    return { nodeRef, handleClickOutsideNode };
};

export const useWindowSize = (): number[] => {
    const [width, setWidth] = useState(window.innerWidth);
    const [height, setHeight] = useState(window.innerHeight);

    useEffect(() => {
        function updateSize() {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
        }
        window.addEventListener("resize", updateSize);
        updateSize();
        return () => window.removeEventListener("resize", updateSize);
    }, []);

    return [width, height];
};

export const useInfiniteScroll = (ref: MutableRefObject<HTMLDivElement>, callback: () => void): void => {
    const [isBottom, setIsBottom] = useState(false);
    const verticalPosition = useRef();

    useEffect(() => {
        if (!isBottom) {
            return;
        }
        callback();
        setIsBottom(false);
    }, [callback, isBottom]);

    const handleScroll = useCallback(
        (event) => {
            const element = event.target;
            if (ref?.current && element !== ref.current) {
                return;
            }
            if (element.scrollTop === verticalPosition.current) {
                return; // the position didn't change meaning the scroll was horizontal
            } else {
                verticalPosition.current = element.scrollTop;
            }

            const distance = 30; // add some distance so the loading starts earlier
            if (element.offsetHeight + element.scrollTop + distance >= element.scrollHeight && !isBottom) {
                setIsBottom(true);
            }
        },
        [isBottom, ref],
    );

    useEffect(() => {
        window.addEventListener("scroll", handleScroll, true);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [handleScroll]);
};

export const useUrlSearchUpdate = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const updateQueryString = useCallback(
        (paramsObject) => {
            const pathname = location.pathname;
            const searchParams = new URLSearchParams(location.search);
            Object.keys(paramsObject).forEach((param) => {
                const paramValue = paramsObject[param];
                if (paramValue) {
                    searchParams.set(param, paramsObject[param]);
                } else {
                    searchParams.delete(param);
                }
            });
            navigate({
                pathname: pathname,
                search: searchParams.toString(),
            });
        },
        [location, navigate],
    );
    return [updateQueryString];
};

export const useQueryString = () => {
    const location = useLocation();
    return queryString.parse(location.search) as { [key: string]: string };
};

export const useResizeObserver = (current: Element, callback?: (entry: DOMRectReadOnly) => void) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);

    const handleResize = useCallback(
        (entries: any[]) => {
            if (!Array.isArray(entries)) {
                return;
            }

            const entry = entries[0];
            setWidth(entry.contentRect.width);
            setHeight(entry.contentRect.height);

            if (callback) {
                callback(entry.contentRect);
            }
        },
        [callback],
    );

    useLayoutEffect(() => {
        if (!current) {
            return;
        }

        let RO = new ResizeObserver((entries: ResizeObserverEntry[]) => handleResize(entries));
        RO.observe(current);

        return () => {
            RO.disconnect();
            RO = null;
        };
    }, [current]);

    return [width, height];
};

const selectAscendants = (
    selectedLevel3Categories: string[],
    selectedLevel2Categories: string[],
    selectedLevel1Categories: string[],
    categories: IReportCategoriesState,
) => {
    const completeCategoriesLevel2 = selectedLevel2Categories;
    const completeCategoriesLevel1 = selectedLevel1Categories;

    if (selectedLevel2Categories.length || selectedLevel3Categories.length)
        categories.reportCategoriesLevel1.forEach((catLvl1) => {
            if (selectedLevel3Categories.length)
                catLvl1.level2Categories.forEach((level2) => {
                    const allLevel3ChildrenSelected = level2.level3Categories.every((c) => selectedLevel3Categories.includes(c.key));
                    if (allLevel3ChildrenSelected) completeCategoriesLevel2.push(level2.key);
                });

            if (completeCategoriesLevel2.length) {
                const allLevel2ChildrenSelected = catLvl1.level2Categories.every((c) => completeCategoriesLevel2.includes(c.key));
                if (allLevel2ChildrenSelected) completeCategoriesLevel1.push(catLvl1.key);
            }
        });

    return { completeCategoriesLevel2, completeCategoriesLevel1 };
};

export const useSetFiltersByParams = () => {
    const dispatch = useDispatch();
    const [wasLoaded, setWasLoaded] = useState<boolean>(false);
    const { sitesByRegions } = useSiteObjectItemsByRegions();

    const reportCategoriesState = useSelector(ReportCategoriesSelectors.getReportCategories);

    const {
        selectedRegions,
        selectedSeverityLevels,
        selectedCategoryOne,
        selectedCategoryTwo,
        selectedCategoryThree,
        selectedSiteLocations,
        selectedReportTypes,
        excludedReportTypes,
        selectedStartDate,
        selectedEndDate,
        showOnlyUnread,
    } = useQueryString();

    useEffect(() => {
        if (
            !wasLoaded &&
            reportCategoriesState.status === RequestStatus.success &&
            (selectedRegions ||
                selectedSeverityLevels ||
                selectedCategoryOne ||
                selectedCategoryTwo ||
                selectedCategoryThree ||
                selectedReportTypes ||
                excludedReportTypes ||
                selectedSiteLocations ||
                selectedStartDate ||
                selectedEndDate ||
                showOnlyUnread)
        ) {
            const categoriesWithAscendantsCalculated = selectAscendants(
                getArrayFromString(selectedCategoryThree),
                getArrayFromString(selectedCategoryTwo),
                getArrayFromString(selectedCategoryOne),
                reportCategoriesState,
            );
            dispatch(
                applyPersistedState({
                    selectedRegions: getArrayFromString(selectedRegions),
                    selectedSeverityLevels: getArrayFromString(selectedSeverityLevels),
                    selectedCategories1: categoriesWithAscendantsCalculated.completeCategoriesLevel1,
                    selectedCategories2: categoriesWithAscendantsCalculated.completeCategoriesLevel2,
                    selectedCategories3: getArrayFromString(selectedCategoryThree),
                    selectedReportTypes: getArrayFromString(selectedReportTypes),
                    excludedReportTypes: getArrayFromString(excludedReportTypes),
                    selectedSiteLocations: getArrayFromString(selectedSiteLocations),
                    selectedStartDate: selectedStartDate ?? null,
                    selectedEndDate: selectedEndDate ?? null,
                    showOnlyUnread: showOnlyUnread === "true",
                } as IFilterState),
            );

            setWasLoaded(true);
        }
    }, [
        dispatch,
        reportCategoriesState,
        selectedCategoryOne,
        selectedCategoryThree,
        selectedCategoryTwo,
        selectedEndDate,
        selectedRegions,
        selectedReportTypes,
        excludedReportTypes,
        selectedSeverityLevels,
        selectedSiteLocations,
        selectedStartDate,
        sitesByRegions,
        showOnlyUnread,
        wasLoaded,
    ]);
};

export const useWithFlexTransition = (
    shouldTriggerAnimation: boolean,
): {
    animate: (callback: () => void) => void;
    flex: string;
} => {
    const [flex, setFlex] = useState("0.00001");
    const animate = useCallback((callback) => {
        setFlex("0.00001");

        // wait for transition to end
        setTimeout(() => {
            callback();
        }, 500);
    }, []);

    useEffect(() => {
        // used to execute it last, to make it render with minimal width to trigger transition
        if (!shouldTriggerAnimation) {
            setTimeout(() => {
                setFlex("1");
            });
        }
    }, [shouldTriggerAnimation]);

    return { animate, flex };
};

export const useSearchParam = (key: string) => {
    const search = useLocation().search;
    return new URLSearchParams(search).get(key);
};

export const useHubContext = () => {
    const [searchParams] = useSearchParams();

    const hubContext = useMemo(() => {
        return searchParams.get("c");
    }, [searchParams]);

    return { hubContext };
};

export const useIsDevice = (portraitBreakpoint: Breakpoints, landscapeBreakpoint: number): boolean => {
    const [width, height] = useWindowSize();
    const landscapeMedia = window.matchMedia("(orientation: landscape)");
    const [isLandscape, setIsLandscape] = useState(landscapeMedia.matches);

    const isMobile = useMemo(() => {
        if (isLandscape) {
            return height <= landscapeBreakpoint;
        }
        return width <= parseInt(portraitBreakpoint);
    }, [height, width, isLandscape, landscapeBreakpoint, portraitBreakpoint]);

    useEffect(() => {
        function onOrientationChange(e) {
            if (e.matches) {
                setIsLandscape(true);
            } else {
                setIsLandscape(false);
            }
        }
        landscapeMedia.addEventListener("change", onOrientationChange);

        return landscapeMedia.removeEventListener("change", onOrientationChange);
    }, [landscapeMedia]);

    return isMobile;
};

export const useChildOverflows = (ref: React.MutableRefObject<any>, byHeight = false): boolean => {
    const [childOverflowsParent, setChildOverflowsParent] = useState<boolean>(false);

    useEffect(() => {
        const client = byHeight ? ref.current?.clientHeight : ref.current?.clientWidth;
        const scroll = byHeight ? ref.current?.scrollHeight : ref.current?.scrollWidth;

        if (client !== scroll && !childOverflowsParent) {
            setChildOverflowsParent(true);
        } else if (client === scroll && childOverflowsParent) {
            setChildOverflowsParent(false);
        }
    }, [ref, ref.current?.clientWidth, ref.current?.scrollWidth, childOverflowsParent, byHeight]);

    return childOverflowsParent;
};
