import { useTranslation } from "react-i18next";
import { ValueTile } from "src/components/ValueTile";

import { useMatrixIncidentCount } from "../../hooks";
import { BarStyled } from "./TrendIndicatorBar.styles";
type TrendIndicatorBarProps = {
    categoryKey: string;
};

export const TrendIndicatorBar = ({ categoryKey }: TrendIndicatorBarProps) => {
    const { isLoading, currentPeriodIncidentCount, previousPeriodIncidentCount } = useMatrixIncidentCount({ categoryKey });
    const { t } = useTranslation();

    return (
        <BarStyled>
            <ValueTile
                isLoading={isLoading}
                value={currentPeriodIncidentCount}
                previousValue={previousPeriodIncidentCount}
                label={t("common.event", { count: currentPeriodIncidentCount })}
                direction="row"
            />
        </BarStyled>
    );
};
