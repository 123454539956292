import { Breakpoints, Icon, Spacing, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { CellStyled } from "../../Matrix.styles";

export const CategoryCellStyled = styled(CellStyled)`
    padding: 0;

    @media (max-width: ${Breakpoints.XS}) {
        min-width: 100px;
    }

    & > div,
    & > div > div {
        display: grid;
        justify-content: start;
    }

    &::before {
        content: none;
    }
`;

export const CategoryNameStyled = styled(Text)`
    padding-right: ${Spacing.S}px;

    @media (max-width: ${Breakpoints.XL}) {
        width: 135px;
    }

    @media (max-width: ${Breakpoints.L}) {
        display: none;
    }
`;

export const CategoryTotalStyled = styled(Text)`
    margin-top: 2px;
`;

export const CategoryIconStyled = styled(Icon)`
    margin-right: 5px;
    position: absolute;

    @media (max-width: ${Breakpoints.L}) {
        position: static;
    }
`;
