import { Breakpoints, Icon, Spacing, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Carousel } from "react-responsive-carousel";
import styled from "styled-components";

import { useInAppGuide } from "../../../components/AppGuide/AppGuideHooks";
import { InsightsSteps } from "../../../components/AppGuide/AppGuideSteps";
import { CategoryLevel1Keys } from "../../../models/ReportCategoryModel";
import { IGraphTile } from "../../../store/insights/InsightsModel";
import { arrowStyles, arrowStylesBack } from "../Common.styled";
import { InsightsGraphTile } from "./InsightsGraphTile";
import { InsightTileStyled } from "./InsightsGraphTile.styled";
import { GetInsightsTooltipData } from "./InsightsOverviewTooltips";
import { NoReportsGraphTile } from "./NoReportsGraphTile";

const GridContainer = styled.div`
    display: flex;
    gap: ${Spacing.S}px;
    flex-wrap: wrap;
    height: fit-content;

    @media only screen and (max-width: ${Breakpoints.XS}) {
        width: 100%;
    }

    .carousel__graphs {
        li:not(:first-child) {
            ${InsightTileStyled} {
                margin-left: ${Spacing.S}px;
            }
        }
        li:last-child {
            ${InsightTileStyled} {
                margin-right: ${Spacing.S}px;
            }
        }
    }
`;

interface ITilesContainer {
    tiles: IGraphTile[];
}

export const TilesContainer = ({ tiles }: ITilesContainer) => {
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const carouselWidth = window.innerWidth ? window.innerWidth - Spacing.S : 350;
    const { startAppGuide } = useInAppGuide();
    const carouselRef = useRef(null);
    const [leftOffsetState, setLeftOffsetState] = useState<number>(0);
    const [availableGraphs, setAvailableGraphs] = useState<string[]>([]);

    const setGraphReadyForGuideHandler = (className: string) => {
        setAvailableGraphs((prev) => [...prev, className]);
    };

    const getTile = useCallback(
        (tile) => {
            return (
                <InsightsGraphTile
                    id={tile.category}
                    key={tile.category}
                    data={tile}
                    headerText={t(`incident.category.${tile.category}`)}
                    tooltipText={GetInsightsTooltipData(tile.category, false, t)}
                    tooltipPlace="bottom"
                    setGraphReadyForGuide={setGraphReadyForGuideHandler}
                />
            );
        },
        [t],
    );

    const getMobileTile = useCallback(
        (tile) => {
            return (
                <InsightsGraphTile
                    id={tile.category}
                    key={tile.category}
                    data={tile}
                    headerText={t(`incident.category.${tile.category}`)}
                    tooltipText={GetInsightsTooltipData(tile.category, true, t)}
                    tooltipPlace="bottom"
                    setGraphReadyForGuide={setGraphReadyForGuideHandler}
                />
            );
        },
        [t],
    );

    useEffect(() => {
        if (carouselRef.current?.state?.itemSize !== leftOffsetState && carouselRef.current?.state?.itemSize > 10)
            setLeftOffsetState(carouselRef.current?.state?.itemSize);
    }, [carouselRef.current?.state?.itemSize, leftOffsetState]);

    useEffect(() => {
        const timer = setTimeout(() => {
            const defaultCategory = `${CategoryLevel1Keys.criminalActOrSuspiciousBehavior}`;
            if (availableGraphs.length) {
                const target = availableGraphs.includes(defaultCategory) ? defaultCategory : `${availableGraphs[0]}`;
                startAppGuide(InsightsSteps(target), "priorityGraph");
            }
        }, 100);

        return () => clearTimeout(timer);
    }, [availableGraphs, startAppGuide]);

    return !tiles.every((x) => Object.keys(x.bySeverityLevel).length === 0 && x.top5.length === 0) ? (
        <GridContainer>
            {!isMobile && (
                <>
                    {getTile(tiles.find((tile) => tile.category === CategoryLevel1Keys.criminalActOrSuspiciousBehavior))}
                    {getTile(tiles.find((tile) => tile.category === CategoryLevel1Keys.healthAndSafety))}
                    {getTile(tiles.find((tile) => tile.category === CategoryLevel1Keys.facility))}
                    {getTile(tiles.find((tile) => tile.category === CategoryLevel1Keys.peopleAndAssets))}
                </>
            )}
            {isMobile && (
                <Carousel
                    className="carousel__graphs"
                    showIndicators={false}
                    width={carouselWidth}
                    showStatus={false}
                    showThumbs={false}
                    swipeable={false}
                    centerMode={true}
                    centerSlidePercentage={87}
                    renderArrowNext={(onClickHandler, hasNext, label) =>
                        hasNext && (
                            <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStyles }}>
                                <Icon color="primary" size="XL" variant="ArrowForward" />
                            </button>
                        )
                    }
                    renderArrowPrev={(onClickHandler, hasPrev, label) =>
                        hasPrev && (
                            <button type="button" onClick={onClickHandler} title={label} style={{ ...arrowStylesBack }}>
                                <Icon color="primary" size="XL" variant="ArrowBack" />
                            </button>
                        )
                    }
                    ref={carouselRef}
                >
                    {getMobileTile(tiles.find((tile) => tile.category === CategoryLevel1Keys.criminalActOrSuspiciousBehavior))}
                    {getMobileTile(tiles.find((tile) => tile.category === CategoryLevel1Keys.healthAndSafety))}
                    {getMobileTile(tiles.find((tile) => tile.category === CategoryLevel1Keys.facility))}
                    {getMobileTile(tiles.find((tile) => tile.category === CategoryLevel1Keys.peopleAndAssets))}
                </Carousel>
            )}
        </GridContainer>
    ) : (
        <NoReportsGraphTile />
    );
};
