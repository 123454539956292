import { RSAA } from "redux-api-middleware";

import { SITE_OBJECT_USERS_FAILURE, SITE_OBJECT_USERS_REQUEST, SITE_OBJECT_USERS_SUCCESS } from "./types";

const getUsersAtLocation = (locationId: string) => ({
    [RSAA]: {
        auth: true,
        endpoint: `/v1/authorization/records/inheritedUsersByLocation/${locationId}/client/site`,
        method: "GET",
        types: [
            {
                meta: { locationId },
                type: SITE_OBJECT_USERS_REQUEST,
            },
            {
                meta: { locationId },
                type: SITE_OBJECT_USERS_SUCCESS,
            },
            {
                meta: { locationId },
                type: SITE_OBJECT_USERS_FAILURE,
            },
        ],
    },
});

export default {
    getUsersAtLocation,
};
