import { createDateFromRawString, endOfDay, RAW_DATE_FORMAT, RawDateString } from "../../helpers/date";
import { ISearchReportsRequestBody, ISearchReportsRequestBodyOld } from "../../models/ReportFilterModels";

//NOTE: Remove when fully migrated to reports api v2
export const parseReportsRequestBody = (
    body: ISearchReportsRequestBody,
    useV2Api: boolean = false,
): ISearchReportsRequestBody | ISearchReportsRequestBodyOld => {
    if (!useV2Api) {
        const { from, to, ...payload } = body;

        return {
            ...payload,
            reportDateTimeStart: createDateForV1(from).toISOString(),
            reportDateTimeEnd: endOfDay(createDateForV1(to)).toISOString(),
        };
    }

    return body;
};

const createDateForV1 = (date: string): Date => {
    const isNotRawDateString = date.length !== RAW_DATE_FORMAT.length;

    if (isNotRawDateString) {
        // NOTE: temp solution for if there is any place that applies the date params in old format (ISO String)
        console.warn(
            "Reports page filters gets date in non-raw format (the old way). Check the code and provide RawDateString instead as it's deprecated. Handling it for you now.",
        );

        return new Date(date);
    } else {
        return createDateFromRawString(date as RawDateString);
    }
};
