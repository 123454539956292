import { HttpResponse } from "msw";

import { ReportTemplateEnum } from "../../../models/ReportModel";
import { GetByDateRange } from "../../../store/insights/InsightsModel";
import { db } from "../../db";

export const getLocationsTourSessionSummaryByTourIdResolver = (payload: GetByDateRange, siteId: string) => {
    const filter = buildFilter(payload, siteId);
    const tourSessions = db.reports.findMany({
        where: filter,
    });
    const byLocationId = tourSessions.reduce((acc, tour) => {
        if (acc[tour.tourName]) {
            acc[tour.tourName].exceptionsCount += tour.exceptionReportIds.length;
            acc[tour.tourName].checkpointsMissed += tour.checkpointsMissedTotal;
            acc[tour.tourName].checkpointsScanned += tour.checkpointsScannedTotal;
            acc[tour.tourName].count += 1;
        } else {
            acc[tour.tourName] = {
                mysTourId: tour.tourSourceId,
                name: tour.tourName,
                exceptionsCount: tour.exceptionReportIds.length,
                checkpointsMissed: tour.checkpointsMissedTotal,
                checkpointsScanned: tour.checkpointsScannedTotal,
                count: 1,
            };
        }

        return acc;
    }, {});

    const result = Object.values(byLocationId);
    return HttpResponse.json(result);
};

const buildFilter = (requestParams: GetByDateRange, siteId: string) => {
    const locationFilter = {
        locationId: {
            equals: siteId,
        },
    };

    const fromDateTime = Date.parse(requestParams.fromDateTime);
    const toDateTime = Date.parse(requestParams.toDateTime);

    const createDateTimeFilter = {
        createDateTime: {
            gte: fromDateTime,
            lt: toDateTime,
        },
    };

    const typeFilter = {
        type: {
            in: [ReportTemplateEnum.patrolTour],
        },
    };

    return {
        ...locationFilter,
        ...createDateTimeFilter,
        ...typeFilter,
    };
};

export default getLocationsTourSessionSummaryByTourIdResolver;
