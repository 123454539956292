import { Modal } from "@secuis/ccp-react-components";
import { isEqual } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useOnEscapeEventListener } from "src/hooks/WebAccessibilityHooks";

import Accordion from "../../../../../components/shared/Accordion/Accordion";
import { IMultiListItem } from "../../../../../components/shared/CheckboxList/CheckboxListItem.types";
import { getAllDescendants, getItemsFromSelectedIds, getNewDropdownSelection } from "../../../../../helpers/MultiDropdownHelper";
import { CustomizedCategories } from "../TrendingEvents";
import { ModalFilters } from "./ModalFilters";

interface CustomizeModalProps {
    reportCategories: IMultiListItem[];
    preselectedCategories: CustomizedCategories;
    isModalOpen: boolean;
    handleSave: (selectedCategories: IMultiListItem[]) => void;
    handleClose: () => void;
}

export const CustomizeModal = ({ reportCategories, isModalOpen, handleSave, handleClose, preselectedCategories }: CustomizeModalProps) => {
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedCategoriesLimit, setSelectedCategoriesLimit] = useState([]);
    const [selectedCategoriesStartingPoint, setSelectedCategoriesStartingPoint] = useState([]);
    const [disabledCheckboxes, setDisabledCheckboxes] = useState([]);
    const { t } = useTranslation();

    useOnEscapeEventListener(handleClose, null, isModalOpen);

    const handleChipRemove = (item) => {
        const newSelection = getNewDropdownSelection(item, selectedCategories);
        setSelectedCategoriesLimit(getItemsFromSelectedIds(newSelection, reportCategories).items);
        setSelectedCategories(newSelection);
    };

    const handleRemoveAll = () => {
        setSelectedCategoriesLimit([]);
        setSelectedCategories([]);
    };

    const handleCategoryChange = useCallback(
        (category: IMultiListItem) => {
            const newSelection = getNewDropdownSelection(category, selectedCategories);
            setSelectedCategories(newSelection);
            setSelectedCategoriesLimit(getItemsFromSelectedIds(newSelection, reportCategories).items);
        },
        [reportCategories, selectedCategories],
    );

    const handleCategoryLoad = useCallback(() => {
        const { level1Categories, level2Categories, level3Categories } = preselectedCategories;
        const selectedCategoriesArray = [...level1Categories, ...level2Categories, ...level3Categories];

        let selection = [];
        selectedCategoriesArray.forEach((element) => {
            selection = getNewDropdownSelection(element, selection);
            const { items } = getItemsFromSelectedIds(selection, reportCategories);
            setSelectedCategoriesLimit(items);
            setSelectedCategoriesStartingPoint(items);
        });
        setSelectedCategories(selection);
    }, [preselectedCategories, setSelectedCategoriesLimit, reportCategories]);

    useEffect(() => {
        if (selectedCategoriesLimit.length === 5) {
            const all = reportCategories.flatMap((x) => [x.value, ...getAllDescendants(x)]);

            setDisabledCheckboxes(all.filter((x) => !selectedCategoriesLimit.find((sel) => sel.value === x)));
        } else {
            setDisabledCheckboxes(selectedCategories.filter((x) => !selectedCategoriesLimit.find((sel) => sel.value === x)));
        }
    }, [reportCategories, selectedCategories, selectedCategoriesLimit]);

    useEffect(() => {
        if (isModalOpen) handleRemoveAll();
    }, [isModalOpen]);

    useEffect(() => {
        if (isModalOpen && preselectedCategories) {
            handleCategoryLoad();
        } else {
            setSelectedCategoriesStartingPoint([]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isModalOpen]);

    return (
        <Modal
            actions={[
                {
                    children: t("common.save"),
                    onClick: () => handleSave(selectedCategoriesLimit),
                    disabled:
                        isEqual(selectedCategoriesLimit, selectedCategoriesStartingPoint) ||
                        (selectedCategoriesLimit.length === 0 && selectedCategoriesStartingPoint.length === 0),
                },
                {
                    children: t("common.cancel"),
                    onClick: handleClose,
                },
            ]}
            isOpen={isModalOpen}
            onClose={handleClose}
            size="L"
            subtitle={t("insights.trendingEvents.modal.subtitle")}
            title={t("insights.trendingEvents.modal.title")}
            actionsDirection="column"
        >
            <Accordion>
                <ModalFilters
                    items={reportCategories}
                    handleCheckChange={handleCategoryChange}
                    selectedIds={selectedCategories}
                    isLoading={false}
                    disabledIds={disabledCheckboxes}
                    selectedItems={selectedCategoriesLimit}
                    handleChipRemove={handleChipRemove}
                    handleClearAll={handleRemoveAll}
                />
            </Accordion>
        </Modal>
    );
};
