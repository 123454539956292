import { Breakpoints, Icon, Palette, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Cell, Pie, PieChart, Tooltip as ChartTooltip } from "recharts";
import { addLineDirectionToData, fillChartVisualValues, mapIncidentsLocationsToColors, removeActiveChartElements } from "src/helpers/graphs";

import { EmptyInboxContent } from "../../../../components/shared/NoContent/EmptyInboxContent";
import { sortByOrder } from "../../../../helpers/ArrayHelper";
import { useGraphOverviewRedirection } from "../../../../store/insights/GraphOverviewHooks";
import { IncidentsLocationsType } from "../../../../store/insights/InsightsModel";
import { CustomizedGraphTooltip } from "../CustomizedGraphTooltip";
import { EmptyTile } from "../InsightsGraphTile.styled";
import { CustomPieChartLabel } from "./CustomPieChartLabel";
import { CustomPieChartLine } from "./CustomPieChartLine";
import {
    CellStyleOverrideWrapper,
    ChartWrapper,
    EmptyTileWrapper,
    InsightTileStyled,
    LegendItemStyled,
    LegendWrapper,
    StyledIcon,
    TileHeader,
    TooltipStyled,
} from "./IncidentsLocationsTile.styled";

const TOOLTIP_HEIGHT = 60;
const LABEL_HEIGHT_INDEX = 15;

type PropsType = {
    incidentsLocations: IncidentsLocationsType[];
};

const locationsLabelsMap = {
    "Public Domain": "siteLocations.publicDomain",
    "Public Access": "siteLocations.publicAccess",
    "Secure Zone": "siteLocations.secureZone",
    "Restricted Area": "siteLocations.restrictedArea",
};

const colorsMap = {
    "1. Public Domain": Palette.Purple200,
    "2. Public Access": Palette.White,
    "3. Secure Zone": Palette.Purple400,
    "4. Restricted Area": Palette.Purple600,
};

const chartOrder = ["1. Public Domain", "4. Restricted Area", "3. Secure Zone", "2. Public Access"];
const legendOrder = ["1. Public Domain", "2. Public Access", "3. Secure Zone", "4. Restricted Area"];

export const IncidentsLocationsTile = ({ incidentsLocations }: PropsType): JSX.Element => {
    const { t } = useTranslation();
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
    const [clickCoordinates, setClickCoordinates] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const responsiveContainerRef = useRef<HTMLDivElement>(null);
    const [tooltipWidth, setTooltipWidth] = useState<number>();
    const dataWithColors = mapIncidentsLocationsToColors(incidentsLocations, colorsMap);
    const tooltipRedirectId = "incidents-locations-link";
    const { redirectToSiteLocationIncidents } = useGraphOverviewRedirection();
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    const handleWidthChange = (width: number) => {
        if (width) {
            setTooltipWidth(width);
        }
    };

    const renderCustomTooltip = useCallback(
        (tooltipData) => {
            if (!tooltipData?.payload?.length || !tooltipData?.payload[0]?.payload || !tooltipData?.payload[0]?.payload?.name) {
                return null;
            }
            const { name, value } = tooltipData?.payload[0].payload ?? { name: "", value: "" };

            const content = (
                <Text small bold>
                    {t(locationsLabelsMap[name])}, {t("insights.incidentsLocations.chart.tooltip", { count: value })}
                </Text>
            );

            return CustomizedGraphTooltip({
                redirectElementId: tooltipRedirectId,
                active: tooltipVisible,
                content,
                widthHandler: handleWidthChange,
                buttonLabel: t("insights.overview.seeReports", { count: value }),
                onClick: () => {
                    const entry = dataWithColors.find((c) => c.name === name);
                    if (entry) {
                        setTooltipVisible(false);
                        redirectToSiteLocationIncidents(entry.sourceName);
                    }
                },
            });
        },
        [dataWithColors, tooltipVisible, redirectToSiteLocationIncidents, t],
    );

    const handleClick = (_, reactEvent) => {
        // set active element manually because of performance
        removeActiveChartElements();
        reactEvent.persist();
        const { target } = reactEvent;
        target.classList.add("active");
        // set tooltip position from react event because rechart event give wrong coordinates
        const { x, y } = responsiveContainerRef.current.getBoundingClientRect();
        const { clientX, clientY } = reactEvent;

        setClickCoordinates({ x: clientX - x, y: clientY - y });
    };

    const setFocus = useCallback(
        (event) => {
            if (event.target.closest(`#${tooltipRedirectId}`)) {
                return;
            }
            if (
                responsiveContainerRef.current?.contains(event.target) &&
                event.target.tagName.toLowerCase() === "path" &&
                event.target.classList.contains("recharts-sector")
            ) {
                setTooltipVisible(true);
            } else {
                removeActiveChartElements();
                setTooltipVisible(false);
            }
        },
        [responsiveContainerRef],
    );

    useEffect(() => {
        window.addEventListener("mousedown", setFocus);

        return () => {
            window.removeEventListener("mousedown", setFocus);
        };
    }, [setFocus]);

    const hasAllEmptyValues = useMemo(() => {
        return dataWithColors.filter((d) => d.value > 0).length === 0;
    }, [dataWithColors]);
    const chartData = useMemo(() => {
        const data = sortByOrder(dataWithColors, chartOrder, "sourceName");

        return addLineDirectionToData(fillChartVisualValues(data), LABEL_HEIGHT_INDEX);
    }, [dataWithColors]);

    return (
        <InsightTileStyled id="incidents-locations">
            <TileHeader>
                <StyledIcon variant="Location" />
                <Text uppercase micro>
                    {t("insights.incidentsLocations.tile.header")}
                </Text>
                <TooltipStyled
                    place={isMobile ? "bottom" : null}
                    label={t("insights.incidentsLocations.tile.tooltip")}
                    id="incidents-locations-headaer-tooltip"
                >
                    <Icon variant="Info" />
                </TooltipStyled>
            </TileHeader>
            {!hasAllEmptyValues ? (
                <>
                    <ChartWrapper ref={responsiveContainerRef}>
                        <CellStyleOverrideWrapper>
                            <PieChart onMouseDown={handleClick} width={300} height={250}>
                                <Pie
                                    data={chartData}
                                    nameKey="name"
                                    dataKey="visualValue"
                                    outerRadius={90}
                                    innerRadius={54}
                                    label={({ ...props }) => <CustomPieChartLabel {...props} />}
                                    labelLine={({ ...props }) => <CustomPieChartLine {...props} />}
                                    isAnimationActive={false}
                                >
                                    {chartData.map((entry) => (
                                        <Cell key={`pie-cell-${entry.name}`} cursor="pointer" fill={entry.color} />
                                    ))}
                                </Pie>
                                <ChartTooltip
                                    allowEscapeViewBox={{ x: true, y: true }}
                                    wrapperStyle={{ outline: "none" }}
                                    trigger="click"
                                    content={renderCustomTooltip}
                                    position={{
                                        x: clickCoordinates.x - tooltipWidth / 2,
                                        y: clickCoordinates.y - TOOLTIP_HEIGHT,
                                    }}
                                    active={tooltipVisible}
                                />
                            </PieChart>
                        </CellStyleOverrideWrapper>
                    </ChartWrapper>
                    <LegendWrapper>
                        {sortByOrder(dataWithColors, legendOrder, "sourceName").map(({ name, color }) => (
                            <LegendItemStyled micro bold key={name} dataItemColor={color}>
                                {t(locationsLabelsMap[name])}
                            </LegendItemStyled>
                        ))}
                    </LegendWrapper>
                </>
            ) : (
                <EmptyTileWrapper>
                    <EmptyTile>
                        <EmptyInboxContent title={t("insights.overview.noReportsLabel")} subtitle={t("insights.overview.noReportsInAllCategories")} />
                    </EmptyTile>
                </EmptyTileWrapper>
            )}
        </InsightTileStyled>
    );
};
