import { Spacing, Stack } from "@secuis/ccp-react-components";
import { PropsWithChildren, ReactNode } from "react";

import { HorizontalLine } from "../HorizontalLine";
import { TitleSection } from "./TitleSection";
import { WrapperStyled } from "./WidgetHeader.styles";

type Props = PropsWithChildren & {
    title?: string;
    extraInfo?: string;
    tooltip?: string;
    titleSection?: ReactNode;
    separator?: boolean;
};

export const WidgetHeader = ({ title, extraInfo, tooltip, titleSection, children, separator = true }: Props) => {
    return (
        <Stack direction="column" paddingBottom="XS">
            <WrapperStyled>
                {titleSection || <TitleSection data-testid="widget-title" title={title} tooltip={tooltip} extraInfo={extraInfo} />}
                {children && (
                    <Stack direction="column" gap="XS" alignItems="flex-end">
                        {children}
                    </Stack>
                )}
            </WrapperStyled>
            {separator && <HorizontalLine marginBottom={0} marginTop={Spacing.XS} />}
        </Stack>
    );
};
