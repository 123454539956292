import { Headline, Icon, Stack, Subtitle } from "@secuis/ccp-react-components";
import { Tooltip } from "src/components/shared/Tooltip";
import { QaProps } from "src/models";

type Props = QaProps & {
    title?: string;
    tooltip?: string;
    extraInfo?: string;
};

export const TitleSection = ({ title, tooltip, extraInfo, ...otherProps }: Props) => {
    return (
        <Stack gap="XXS" direction="column" {...otherProps}>
            {title && (
                <Stack gap="XS" alignItems="center">
                    <Subtitle>{title}</Subtitle>
                    {tooltip && (
                        <Tooltip id={title} label={tooltip} place="top">
                            <Icon variant="Info" />
                        </Tooltip>
                    )}
                </Stack>
            )}
            {extraInfo && (
                <Headline data-testid="extra-info" bold={true} color="secondary">
                    {extraInfo}
                </Headline>
            )}
        </Stack>
    );
};
