import { Stack } from "@secuis/ccp-react-components";

import { TileLoader } from "../../../components/Insights/TileLoader/TileLoader";
import { useGraphOverviewInsights } from "../../../store/insights/GraphOverviewHooks";
import { useIncidentsLocations } from "../../../store/insights/IncidentsLocationsHooks";
import { InsightsSection } from "../Common.styled";
import OverviewHeader from "./Header/OverviewHeader";
import { IncidentsLocationsTile } from "./IncidentsLocations/IncidentsLocationsTile";
import { TilesContainer } from "./TilesContainer";

export const GraphOverview = () => {
    const { isLoading, tiles, reportsCount } = useGraphOverviewInsights();
    const { isLoading: isLocationsOfIncidentsLoading, incidentsLocations } = useIncidentsLocations();
    const inProgress = isLoading && isLocationsOfIncidentsLoading;
    return (
        <Stack direction="column" id="graphOverview">
            <OverviewHeader reportsCount={reportsCount} />
            {inProgress && <TileLoader height={"600px"} />}
            {!inProgress && (
                <InsightsSection direction="column">
                    <TilesContainer tiles={tiles} />
                    <InsightsSection>
                        <IncidentsLocationsTile incidentsLocations={incidentsLocations} />
                    </InsightsSection>
                </InsightsSection>
            )}
        </Stack>
    );
};
