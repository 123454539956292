export type PageName = "Insights Page" | "Report List Page" | "Report Details Page" | "Site Overview Page";

export interface IMixpanelPageViewAdditionalProperties {
    "Has custom groups"?: boolean;
}

export interface IMixpanelPageViewProperties extends IMixpanelPageViewAdditionalProperties {
    Page: string;
    "Page Title": PageName;
}

export const PageNames: Record<string, PageName> = {
    Insights: "Insights Page",
    ReportList: "Report List Page",
    ReportDetails: "Report Details Page",
    SiteOverview: "Site Overview Page",
};
