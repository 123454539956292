import { IImageData } from "./ReportImagesModels";

export enum DynamicReportPropertyTypeEnum {
    boolean = "boolean",
    text = "text",
    resource = "resource",
    image = "image",
    array = "array",
}

export type DynamicReportPropertyType = {
    value: string;
    type: DynamicReportPropertyTypeEnum;
    reportFieldName: string;
};

export const EventReportTemplateName = "Event Report";

export enum ReportTemplateEnum {
    incident = "Incident Report",
    alarmResponse = "* Alarm Response Report (GC)",
    amazonGeneral = "* General Report - Amazon ACS (GC)",
    amazonZone6 = "* Zone 6 Report - Amazon ACS (GC)",
    tourException = "Tour Exception",
    tourMultiException = "Tour Multi Exception",
    maintenance = "Maintenance Report",
    patrolTour = "tourSession",
    siteInspection = "Site Inspection Report",
    safetyInspection = "Safety Inspection Report",
    taskReport = "Task Report",
    activityLog = "Activity log",
    assignment = "Assignment Report",
    vehicleInspection = "Vehicle Inspection Report",
    callout = "Call-out Report",
}

export const customReportFilterOption = "custom";

export type DynamicReportType = {
    categoryLevel1: string;
    categoryLevel2: string;
    categoryLevel3: string;
    clientId: string;
    commentsCount: number;
    createDateTime: string;
    id: string;
    sourceId: string;
    images: IImageData[];
    locationId: string;
    properties: DynamicReportPropertyType[];
    reportDateTime: string;
    reportedBy: string;
    rootSiteLocation: string;
    severityLevel: number | string;
    siteCustomId: string;
    siteLocationSubLevels: string;
    submitDateTime: string;
    template: ReportTemplateEnum;
    childTemplate: string;
    visitorIds: string[] | null;
    visitorsCount?: number;
    siteIanaTimezone?: string;
};

export enum CheckpointScanResult {
    scanned = "Scanned",
    missed = "Missed",
}

export type CheckpointScanType = {
    checkpointId: string;
    checkpointName: string;
    checkpointSortOrder: number;
    checkpointScanId: string;
    result: CheckpointScanResult;
    scannedBy: string;
    scanDateTime: string;
};

export type PatrolTourType = {
    pdfPieChart?: string;
    checkpointScans: CheckpointScanType[];
    commentsCount: number;
    startDateTime: string;
    endDateTime: string;
    id: string;
    locationId: string;
    performedBy: string;
    siteCustomId: string;
    siteLocation: string;
    siteIanaTimezone?: string;
    title: string;
    exceptionReportIds: string[];
    tourName: string;
    tourSourceId: string;
    sourceId: string;
    durationMinutes: number;
    checkpointsTotal: number;
    checkpointsScannedTotal: number;
    checkpointsMissedTotal: number;
    visitorIds: string[] | null;
    visitorsCount?: number;
    relatedReports?: DynamicReportType[];
    exceptions?: any[];
    incompleteReason?: string;
};

export type ReportsListItemType = {
    commentsCount: string;
    countryCode: string;
    createDateTime: string;
    id: string;
    imageCount: string;
    locationId: string;
    reportDateTime: string;
    severityLevel: number | string;
    siteLocation: string;
    submitDateTime: string;
    type: ReportTemplateEnum | string;
    subType: ReportTemplateEnum | string;
    visitorIds: string[] | null;
    properties: { key: string; value: string }[];
    sourceId: string;
    categoryLevel1?: string;
    categoryLevel2?: string;
    categoryLevel3?: string;
    siteLocationSubLevels?: string;
    siteIanaTimezone?: string;
};

// as any added to access key by value
export enum SiteZoneEnum {
    publicDomain = "1. Public Domain" as any,
    publicAccess = "2. Public Access" as any,
    secureZone = "3. Secure Zone" as any,
    restrictedArea = "4. Restricted Area" as any,
}

export const reportTypeTranslation = {
    [ReportTemplateEnum.alarmResponse.toString()]: "common.report.alarmDispatch",
    [ReportTemplateEnum.incident.toString()]: "common.report.plannedTourShort",
    [ReportTemplateEnum.tourException.toString()]: "reportdetails.report.tourException",
    [ReportTemplateEnum.tourMultiException.toString()]: "reportdetails.report.tourMultiException",
    [ReportTemplateEnum.amazonGeneral.toString()]: "reportdetails.amazon.generalReport",
    [ReportTemplateEnum.amazonZone6.toString()]: "reportdetails.amazon.zone6Report",
    [ReportTemplateEnum.maintenance]: "reportdetails.report.maintenanceShort",
    [ReportTemplateEnum.siteInspection]: "reportdetails.report.siteInspectionShort",
    [ReportTemplateEnum.safetyInspection]: "reportdetails.report.safetyInspectionShort",
    [ReportTemplateEnum.taskReport]: "reportdetails.report.taskReport",
    [ReportTemplateEnum.patrolTour]: "reportdetails.report.patrolTour",
    [ReportTemplateEnum.activityLog]: "reportdetails.report.activityLog",
    [ReportTemplateEnum.assignment]: "reportdetails.report.assignment",
    [ReportTemplateEnum.vehicleInspection]: "reportdetails.report.vehicleInspection",
    [ReportTemplateEnum.callout]: "reportdetails.report.calloutMobile",
    [customReportFilterOption]: "filters.customReports",
};

export const isPatrolTour = (entity: unknown): entity is PatrolTourType => {
    return entity ? entity["tourSourceId"] : false;
};

export const reportsWithCategories = [
    ReportTemplateEnum.incident,
    ReportTemplateEnum.callout,
    ReportTemplateEnum.assignment,
    ReportTemplateEnum.alarmResponse,
    ReportTemplateEnum.amazonGeneral,
    ReportTemplateEnum.amazonZone6,
    ReportTemplateEnum.tourException,
    ReportTemplateEnum.tourMultiException,
    ReportTemplateEnum.taskReport,
];

export type ContactInfoType = {
    companyName: string;
    businessId: string;
    address: string;
    phone: string;
    postalAddress: string;
};
