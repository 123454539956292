import { useTranslation } from "react-i18next";
import { ValueTile } from "src/components/ValueTile";
import { CSSProperties } from "styled-components";

import { SummaryGridLayoutStyled } from "../SummaryGrid/SummaryGrid.styles";
import { usePerformanceIndicatorsWidget } from "./PerformanceIndicatorsWidget.hooks";
import { WidgetStyled } from "./PerformanceIndicatorsWIdget.styles";

type Props = {
    style?: CSSProperties;
};

export const PerformanceIndicatorsWidget = ({ style }: Props) => {
    const { t } = useTranslation();
    const { indicators, isLoading } = usePerformanceIndicatorsWidget();
    return (
        <WidgetStyled title={t("insights.summary.performanceIndicators.title")} headerSeparator={false} style={style} isLoading={isLoading}>
            <SummaryGridLayoutStyled>
                {indicators.map(({ value, prevValue, title }) => (
                    <ValueTile key={title} value={value} previousValue={prevValue} title={title} label={t("common.tours", { count: value })} icon="Route" />
                ))}
            </SummaryGridLayoutStyled>
        </WidgetStyled>
    );
};
