import { createReducer } from "@reduxjs/toolkit";

import { getSitesObjectWithUniqueNames } from "../../helpers/LocationsHelper";
import { BaseLocationLabel, mapToGraphNode } from "../../models/BaseLocationModel";
import { RequestStatus } from "../RequestStatus";
import {
    ILocationsState,
    LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_FAILURE,
    LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_REQUEST,
    LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_SUCCESS,
} from "./types";

export const defaultState: ILocationsState = {
    descendantsFetchStatus: RequestStatus.undefined,
    groups: [],
    siteObjects: [],
};

const locationsReducer = createReducer(defaultState, {
    [LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_REQUEST]: (state) => {
        state.descendantsFetchStatus = RequestStatus.loading;
    },
    [LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_SUCCESS]: (state, action) => {
        state.descendantsFetchStatus = RequestStatus.success;
        const groupLocations: Record<string, string[]> = {};
        action.payload.results.forEach((location) => {
            if (location.label === BaseLocationLabel.SiteObject) {
                state.siteObjects.push(mapToGraphNode(location));
                const parentGroups = location.parents.filter((parent) => parent.label === BaseLocationLabel.Group);
                parentGroups.forEach((parentGroup) => {
                    if (groupLocations[parentGroup.id]) {
                        groupLocations[parentGroup.id].push(location.id);
                    } else {
                        groupLocations[parentGroup.id] = [location.id];
                    }
                });
            }
        });
        state.siteObjects = getSitesObjectWithUniqueNames(state.siteObjects);
        action.payload.results.forEach((location) => {
            if (location.label !== BaseLocationLabel.Group) {
                return;
            }
            const parent =
                location.parents.find((parent) => parent.label === BaseLocationLabel.Group) ??
                location.parents.find((parent) => parent.label === BaseLocationLabel.Client);

            state.groups.push({ id: location.id, parentId: parent?.id, name: location.name, locationIds: groupLocations[location.id] ?? [] });
        });
    },
    [LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_FAILURE]: (state) => {
        state.descendantsFetchStatus = RequestStatus.error;
    },
});

export default locationsReducer;
