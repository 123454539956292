import { useCallback, useState } from "react";
import { PatrolTourType } from "src/models/ReportModel";
import { useLazyGetTourSessionQuery } from "src/store/reports/reportsApi";
import { useLazyGetTasksQuery } from "src/store/schedule/scheduleApi";
import { TaskSchedule } from "src/store/schedule/types";

export const useTasks = (locationId: string) => {
    const [getTasks] = useLazyGetTasksQuery();
    const [getTourSession] = useLazyGetTourSessionQuery();

    const [data, setData] = useState<(TaskSchedule & { children?: PatrolTourType[] })[]>([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSuccess, setIsSuccess] = useState(false);

    const requestTasks = useCallback(
        async ({ date, locationId }: { date: string; locationId: string }) => {
            const localData: (TaskSchedule & { children?: PatrolTourType[] })[] = [];
            await getTasks({ date, locationId }).then(async ({ data: tasksData }) => {
                for (const task of tasksData) {
                    if (!task.tourSessionId) {
                        localData.push(task);
                    } else {
                        let localTask: TaskSchedule & { children?: PatrolTourType[] } = task;
                        await getTourSession({ siteObjectId: locationId, reportId: task.tourSessionId })
                            .unwrap()
                            .then((result) => {
                                if (result) {
                                    localTask = { ...task, children: [{ ...result }] as PatrolTourType[] };
                                }
                                localData.push(localTask);
                            });
                    }
                }
            });
            setData(localData);
            setIsLoading(false);
            setIsSuccess(true);
        },
        [locationId, getTasks, getTourSession],
    );

    return {
        getTasks: requestTasks,
        isLoading,
        isSuccess,
        data,
    };
};
