import { useBooleanFlagValue } from "@openfeature/react-sdk";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { featureFlags } from "src/data/featureFlags";
import { ReportTemplateEnum } from "src/models/ReportModel";

import { Databases, QueryResult } from "../../models/DbModel";
import { getIncidentsLocationsCategories } from "../../sql/scripts/insights/incidentsLocationsQueries";
import { IState } from "../../Store";
import ReportCategoriesSelectors from "../reportCategories/ReportCategoriesSelectors";
import { isFinished, RequestStatus } from "../RequestStatus";
import { useFilteredSites } from "./FilterHooks";
import InsightsActions from "./InsightsActions";
import { useDatabase } from "./InsightsLoadHooks";
import { IncidentsLocationsType } from "./InsightsModel";
import InsightsSelectors from "./InsightsSelectors";
import { generateQueryKey } from "./keys";
import { IIncidentsLocationsState } from "./types";
import { useFeatureFlag } from "../../hooks/featureFlags";

const formatIncidentsLocations = (results: QueryResult[]): IncidentsLocationsType[] => {
    if (results?.length) {
        const { values } = results[0];
        return values.map(([name, value]) => ({ name, value }));
    }
    return [];
};

export const useIncidentsLocations = (): {
    isLoading: boolean;
    incidentsLocations: IncidentsLocationsType[];
} => {
    const [queryKey, setQueryKey] = useState<string>();
    const dispatch = useDispatch();
    const { dbReadyToQuery, execute } = useDatabase(Databases.Reports);
    const selectedStartDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const selectedEndDate = useSelector(InsightsSelectors.getSelectedEndDate);
    const { status, reportCategoriesLevel1 } = useSelector(ReportCategoriesSelectors.getReportCategories);
    const { queryStatus, incidentsLocations } = useSelector<IState, IIncidentsLocationsState>(InsightsSelectors.getIncidentsLocations);
    const { siteIds } = useFilteredSites();
    const isAssignmentReportTypeEnabled = useBooleanFlagValue(featureFlags.assignmentReports, false);
    const isCalloutReportTypeEnabled = useBooleanFlagValue(featureFlags.calloutReports, false);
    const areSiteZonesEnabled = useFeatureFlag(featureFlags.sitesTimezones);

    const isLoading = useMemo(() => {
        return !dbReadyToQuery || !isFinished(queryStatus);
    }, [dbReadyToQuery, queryStatus]);

    const reportTypes = useMemo(() => {
        const reportTypesFilter = [ReportTemplateEnum.alarmResponse.toString(), ReportTemplateEnum.incident.toString()];
        if (isAssignmentReportTypeEnabled) {
            reportTypesFilter.push(ReportTemplateEnum.assignment.toString());
        }
        if (isCalloutReportTypeEnabled) {
            reportTypesFilter.push(ReportTemplateEnum.callout.toString());
        }
        return reportTypesFilter;
    }, [isAssignmentReportTypeEnabled, isCalloutReportTypeEnabled]);

    const getIncidentsLocations = useCallback(async () => {
        const allLevel1Categories = reportCategoriesLevel1.map((l) => l.key);

        const incidentsLocationsQuery = getIncidentsLocationsCategories(
            siteIds,
            selectedStartDate,
            selectedEndDate,
            allLevel1Categories,
            reportTypes,
            areSiteZonesEnabled,
        );
        const incidentsLocationsJob = execute(incidentsLocationsQuery.sql, incidentsLocationsQuery.params);
        const incidentsLocationsDB = await Promise.all([incidentsLocationsJob]);

        return formatIncidentsLocations(incidentsLocationsDB[0]?.results);
    }, [execute, selectedStartDate, selectedEndDate, siteIds, reportCategoriesLevel1, reportTypes, areSiteZonesEnabled]);

    useEffect(() => {
        const getLocations = async (key: string) => {
            setQueryKey(key);
            dispatch(InsightsActions.requestIncidentsLocations());
            const incidentsLocations = await getIncidentsLocations();
            dispatch(InsightsActions.incidentsLocationsSuccess(incidentsLocations));
        };
        const newKey = generateQueryKey({ locationIds: siteIds, fromDate: selectedStartDate, toDate: selectedEndDate });
        if (
            !dbReadyToQuery ||
            (queryKey === newKey && (queryStatus === RequestStatus.loading || isFinished(queryStatus))) ||
            status !== RequestStatus.success
        ) {
            return;
        }
        getLocations(newKey);
    }, [dbReadyToQuery, queryStatus, dispatch, getIncidentsLocations, queryKey, siteIds, selectedStartDate, selectedEndDate, status]);

    return { incidentsLocations, isLoading };
};
