import { Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const WrapperStyled = styled(Stack).attrs({
    justifyContent: "space-between",
    alignItems: "flex-start",
    flexWrap: "wrap",
})`
    gap: ${Spacing.S}px;
`;
