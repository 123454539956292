import { Box, ButtonText, ContextMenu, ContextMenuOption } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

type Props = {
    isRead: boolean;
    onMarkAsRead?: () => void;
};

export const ListItemContextMenu = ({ isRead, onMarkAsRead }: Props) => {
    const { t } = useTranslation();

    const contextMenuOptions: ContextMenuOption[] = useMemo(
        () => [
            {
                label: t("reports.status.markAs.read"),
                icon: "EnvelopeOpened",
                onClick: onMarkAsRead,
                disabled: isRead,
            },
        ],
        [isRead, onMarkAsRead, t],
    );

    return (
        <Box>
            <ContextMenu options={contextMenuOptions} sheetCancelLabel={t("common.cancel")}>
                <ButtonText icon="Options" iconSize="L" color="primary" />
            </ContextMenu>
        </Box>
    );
};
