import { TaskSchedule, TaskScheduleStatus } from "src/store/schedule/types";

import { sortByExpectedDuration, sortByPosition, sortByScheduleType, sortByStartTime, sortByStatus } from "./ScheduleTable.helpers";
import { ScheduleTableSortKey } from "./ScheduleTable.types";

export const STATUS_SORTING_ORDER = [
    TaskScheduleStatus.missed,
    TaskScheduleStatus.cancelled,
    TaskScheduleStatus.inProgress,
    TaskScheduleStatus.completed,
    TaskScheduleStatus.scheduled,
];

export const SORT_CONFIG: { [k in ScheduleTableSortKey]: (t: TaskSchedule[]) => TaskSchedule[] } = {
    status: sortByStatus,
    startTime: sortByStartTime,
    expectedDuration: sortByExpectedDuration,
    position: sortByPosition,
    scheduleType: sortByScheduleType,
};
