import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const SectionHeadingStyled = styled(Stack)`
    margin: ${Spacing.M}px 0;
    justify-content: space-between;
    gap: ${Spacing.XS}px;
    align-items: center;

    @media (max-width: ${Breakpoints.M}) {
        margin-bottom: ${Spacing.S}px;
    }
    @media (max-width: ${Breakpoints.XS}) {
        margin: ${Spacing.XS}px 0;
        flex-direction: column;
        align-items: flex-start;
    }
`;
