import { Breakpoints, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useInsightsPageContext } from "src/pages/Insights/InsightsPageProvider";

import { EmptyState, IncidentColorScale, Loader, Pagination, SitesLegend, ToggleVisibleItems } from "./components";
import { getCategoryIncidentCount } from "./helpers";
import { useColumns, useMatrixData, usePagination, useToggleVisibleItems } from "./hooks";
import { TableStyled } from "./Matrix.styles";
import { MatrixProps } from "./Matrix.types";

export const Matrix = ({ categoryKey }: MatrixProps) => {
    const MAX_COLUMNS_COUNT_TO_ENABLE_MAX_WIDTH = 4;
    const MAX_INITIAL_ITEMS_COUNT = 5;
    const MAX_ITEMS_COUNT_WITHOUT_SCROLL = 10;

    const { isLoading, data, categories, maxIncidentCount } = useMatrixData(categoryKey);

    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const isLargeScreen = !useHasMaxWidth(Breakpoints.L);
    const categoriesPerPage = isMobile ? 2 : 4;

    // sites toggle option to show more/less
    const { visibleItems, showMoreItems, showLessItems } = useToggleVisibleItems({
        items: data,
        maxVisibleItems: MAX_INITIAL_ITEMS_COUNT,
    });

    const nonEmptyCategories = categories.filter((category) => getCategoryIncidentCount(visibleItems, category) > 0);

    // categories pagination
    const {
        totalPages,
        currentItems: visibleCategories,
        hiddenItems: hiddenCategories,
        currentPage,
        showPreviousPage,
        showNextPage,
    } = usePagination({
        items: nonEmptyCategories,
        itemsPerPage: categoriesPerPage,
    });

    const isOtherColumnEnabled = isLargeScreen && hiddenCategories.length > 0;

    const { columnsData, columnsCount, rowsData } = useColumns({
        visibleCategories,
        hiddenCategories,
        items: visibleItems,
        maxIncidentCount,
        isOtherColumnEnabled,
    });

    const isColMaxWidthEnabled = columnsData.length <= MAX_COLUMNS_COUNT_TO_ENABLE_MAX_WIDTH && isLargeScreen;
    const { isFilterActive } = useInsightsPageContext();

    const siteLegendItems = visibleItems.map((item) => (item.site && "name" in item.site ? item.site.name : null));

    return isLoading ? (
        <Loader />
    ) : data.length > 0 ? (
        <>
            {categories.length > categoriesPerPage && (
                <Pagination currentPage={currentPage} totalPages={totalPages} showPreviousPage={showPreviousPage} showNextPage={showNextPage} />
            )}

            <TableStyled
                data={rowsData}
                columns={columnsData}
                colMaxWidthEnabled={isColMaxWidthEnabled}
                scrollable={rowsData.length > MAX_ITEMS_COUNT_WITHOUT_SCROLL}
                rowGroupsEnabled={true}
                isFilterActive={isFilterActive}
                categoryColumnCount={columnsCount - 1}
                isOtherColumnEnabled={isOtherColumnEnabled}
            />
            {isMobile && siteLegendItems.length > 0 && <SitesLegend sites={siteLegendItems} />}
            <Stack justifyContent={data.length > MAX_INITIAL_ITEMS_COUNT ? "space-between" : "flex-end"} mt="M" flexWrap="wrap" gap="S">
                {data.length > MAX_INITIAL_ITEMS_COUNT && (
                    <ToggleVisibleItems
                        items={rowsData}
                        maxItems={MAX_INITIAL_ITEMS_COUNT}
                        showMoreItems={showMoreItems}
                        showLessItems={showLessItems}
                        showMoreItemsText={t("incidentMatrix.table.showMore")}
                        showLessItemsText={t("incidentMatrix.table.showLess")}
                    />
                )}

                <IncidentColorScale />
            </Stack>
        </>
    ) : (
        <EmptyState />
    );
};
