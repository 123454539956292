import { faker } from "@faker-js/faker";
import { formatTime } from "src/helpers/date";

import { ScheduleType, TaskSchedule, TaskScheduleStatus } from "./types";

const getRandomNumber = (max: number) => Math.floor(Math.random() * max);

const taskTypes = ["General", "Closing", "Opening"];
const descriptions = ["Check CCTV", "Closing Round", "Opening Reception", null];
const scheduleTypes = [ScheduleType.oneTime, ScheduleType.recurring];
const statuses = [
    TaskScheduleStatus.cancelled,
    TaskScheduleStatus.completed,
    TaskScheduleStatus.inProgress,
    TaskScheduleStatus.missed,
    TaskScheduleStatus.scheduled,
];

export const generateExamples = (count: number, tourIds: string[]): TaskSchedule[] =>
    new Array(count).fill({}).map(() => {
        return {
            taskId: faker.string.uuid(),
            description: descriptions[getRandomNumber(descriptions.length)],
            taskEventId: faker.string.uuid(),
            taskType: taskTypes[getRandomNumber(taskTypes.length)],
            status: statuses[getRandomNumber(statuses.length)],
            startTime: formatTime(faker.date.anytime()),
            expectedDuration: `${getRandomNumber(120)}`,
            position: { id: faker.string.uuid(), name: "RL1" },
            scheduleType: scheduleTypes[getRandomNumber(scheduleTypes.length)],
            assignedTo: { id: faker.string.uuid(), name: faker.person.fullName() },
            tourSessionId: tourIds[getRandomNumber(tourIds.length + count / 2)],
        };
    });
