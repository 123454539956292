import { useFeatureFlag } from "src/hooks/featureFlags";

import { featureFlags } from "../../data/featureFlags";
import { GraphOverview } from "./Graphs/GraphOverview";
import { IncidentMatrix } from "./IncidentMatrix/IncidentMatrix";

export const Events = () => {
    const isIncidentMatrixEnabled = useFeatureFlag(featureFlags.incidentMatrix);

    return isIncidentMatrixEnabled ? <IncidentMatrix /> : <GraphOverview />;
};
