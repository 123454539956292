import { Spacing, Text } from "@secuis/ccp-react-components";
import * as ReactDOMServer from "react-dom/server";
import styled from "styled-components";

const TooltipList = styled.ul`
    width: 50%;
    padding-left: ${Spacing.S}px;

    li {
        white-space: pre-wrap;
        word-wrap: break-word;
    }
`;

const TooltipListContainer = styled.div`
    width: 100%;
    display: flex;
    padding-top: 10px;
    column-gap: ${Spacing.XS}px;
`;
const TooltipContainer = styled.div`
    padding: 11px;
    text-transform: none;
`;

const CriminalCategories = [
    "incident.category.cas_illegal_possession",
    "incident.category.cas_harassment",
    "incident.category.cas_tresspassing",
    "incident.category.cas_theft",
    "incident.category.cas_threat",
    "incident.category.cas_unusual_behavior",
    "incident.category.cas_disastrous_event",
    "incident.category.cas_drugs_and_alcohol",
    "incident.category.cas_robbery",
    "incident.category.cas_burglary",
    "incident.category.cas_vandalism",
    "incident.category.cas_physical_violence",
];

const HealthCategories = [
    "incident.category.has_inspections_audits",
    "incident.category.has_accident",
    "incident.category.has_near_miss",
    "incident.category.has_dispute_confrontation",
    "incident.category.has_public_disturbance",
    "incident.category.has_escort",
    "incident.category.has_medical",
    "incident.category.has_active_hazard",
    "incident.category.has_potential_hazard",
    "incident.category.has_workplace_violation",
    "incident.category.has_other_health_and_safety",
];

const FacilityCategories = [
    "incident.category.fac_water_and_sanitary",
    "incident.category.fac_appliances",
    "incident.category.fac_elevator_stairs_escalators",
    "incident.category.fac_heating_ventilation_air_condition",
    "incident.category.fac_electrical",
    "incident.category.fac_structures_buildings",
    "incident.category.fac_lighting",
    "incident.category.fac_other_facility_related",
    "incident.category.fac_gates_fences",
    "incident.category.fac_doors_windows",
    "incident.category.fac_signage",
    "incident.category.fac_fire_systems",
    "incident.category.fac_access_control_systems",
    "incident.category.fac_security_systems",
];

const PeopleCategories = [
    "incident.category.paa_goods_and_products",
    "incident.category.paa_access_control",
    "incident.category.paa_asset_management",
    "incident.category.paa_lost_and_found",
    "incident.category.paa_improper_disposal",
    "incident.category.paa_vehicle_and_parking",
    "incident.category.paa_other_people_and_assets",
];

const getIncidentCategoriesByParentCategory = (category: string): string[] => {
    switch (category) {
        case "criminal_act_or_suspicious_behavior":
            return CriminalCategories;
        case "facility":
            return FacilityCategories;
        case "health_and_safety":
            return HealthCategories;
        case "people_and_assets":
            return PeopleCategories;
        default:
            throw new Error(`Unable to recognize category ${category}.`);
    }
};

export const GetInsightsTooltipData = (category: string, isMobile: boolean, t: (str: string) => string): string => {
    const incidentCategories = getIncidentCategoriesByParentCategory(category);
    const midPoint = Math.ceil(incidentCategories.length / 2);

    const result = incidentCategories.reduce((resultArray, item, index) => {
        const partIndex = Math.floor(index / midPoint);

        if (!resultArray[partIndex]) {
            resultArray[partIndex] = [];
        }

        resultArray[partIndex].push(item);

        return resultArray;
    }, []);

    return ReactDOMServer.renderToStaticMarkup(
        <TooltipContainer>
            <Text micro={isMobile} small={!isMobile} bold>
                {t(`insights.overview.tooltipHeader.${category}`)}
            </Text>
            <TooltipListContainer>
                {result.map((categoryChunk, index) => (
                    <TooltipList key={"categoryChunk" + index}>
                        {categoryChunk.map((categoryName) => (
                            <li key={categoryName}>
                                <Text micro>{t(categoryName)}</Text>
                            </li>
                        ))}
                    </TooltipList>
                ))}
            </TooltipListContainer>
        </TooltipContainer>,
    );
};
