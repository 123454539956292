import { Breakpoints, Modal, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useOnEscapeEventListener } from "src/hooks/WebAccessibilityHooks";

import { SpacerStyled, TextStyled } from "./ResetModal.styles";

interface CustomizationModalsProps {
    isModalOpen: boolean;
    handleReset: () => void;
    handleOpenClose: () => void;
}

export const ResetModal = ({ isModalOpen, handleReset, handleOpenClose }: CustomizationModalsProps) => {
    const { t } = useTranslation();
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    useOnEscapeEventListener(handleOpenClose, null, isModalOpen);

    return (
        <Modal
            actions={[
                {
                    children: t("insights.trendingEvents.resetModal.resetToDefault"),
                    onClick: handleReset,
                },
                {
                    children: t("common.cancel"),
                    onClick: handleOpenClose,
                },
            ]}
            isOpen={isModalOpen}
            onClose={handleOpenClose}
            size="S"
            subtitle={t("insights.trendingEvents.resetModal.subtitle")}
            title={t("insights.trendingEvents.resetModal.title")}
        >
            <>
                <TextStyled>{t("insights.trendingEvents.resetModal.description")}</TextStyled>
                {!isMobile && <SpacerStyled />}
            </>
        </Modal>
    );
};
