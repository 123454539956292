import { Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { Modal } from "src/components/shared/Modal/Modal";
import { useOnEscapeEventListener } from "src/hooks/WebAccessibilityHooks";

type PropsType = {
    isOpen: boolean;
    onClose: () => void;
};

export const ExceedingExportLimitsModal = ({ isOpen, onClose }: PropsType): JSX.Element => {
    const { t } = useTranslation();

    useOnEscapeEventListener(onClose, null, isOpen);

    return (
        <Modal
            actions={[
                {
                    children: t("reports.export.gotit"),
                    onClick: onClose,
                },
            ]}
            onClose={onClose}
            isOpen={isOpen}
            title={t("excelExport.exceedingThreeMonths")}
            size={"S"}
        >
            <Text>{t("excelExport.exceedThreeMonthsMessage")}</Text>
        </Modal>
    );
};
