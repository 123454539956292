import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useMemo } from "react";

import { formatTime, set } from "../../../../helpers/date";
import { useSqlQuery } from "../../../../sql/hooks";
import { useFilteredSites } from "../../../../store/insights/FilterHooks";
import { useSummaryPeriod } from "../shared/hooks";
import { getDeviantHoursQuery, parseDeviantHours } from "./DeviantHourWidget.queries";
import { LineChartDataset } from "./LineChart";

export const useDeviantHourWidget = () => {
    const { currentPeriod } = useSummaryPeriod();
    const isMobile = useHasMaxWidth(Breakpoints.S);
    const { siteIds } = useFilteredSites();
    const { queryResult, isLoading } = useSqlQuery(getDeviantHoursQuery, parseDeviantHours, {
        siteIds,
        startDate: currentPeriod.start,
        endDate: currentPeriod.end,
    });
    const deviantHours = useMemo(() => queryResult?.topHours ?? [], [queryResult]);
    const deviantHourLabel = useMemo(
        () =>
            deviantHours.length === 1
                ? formatTime(
                      set(new Date(), {
                          hours: parseInt(deviantHours[0]),
                          minutes: 0,
                      }),
                  )
                : undefined,
        [deviantHours],
    );
    const chartData: LineChartDataset[] = useMemo(
        () => [
            {
                id: "totalEvents",
                values: queryResult?.hourEventCounts ?? [],
                highlights: deviantHours.length <= 3 ? deviantHours : [],
            },
        ],
        [queryResult, deviantHours],
    );
    const hoursChartTicks = chartData[0].values.map(({ key }, index) => key);

    return {
        isLoading,
        isEmpty: !queryResult?.totalEvents,
        chartData,
        deviantHourLabel,
        xAxisTicks: isMobile ? hoursChartTicks.filter((_, index) => [0, 6, 12, 18, 23].includes(index)) : hoursChartTicks,
    };
};
