import { useTranslation } from "react-i18next";
import { featureFlags } from "src/data/featureFlags";
import { useFeatureFlag } from "src/hooks/featureFlags";

import InsightHeader from "../../../components/Insights/common/InsightHeader";
import { BoxStyled } from "./IncidentMatrix.styles";
import { IncidentMatrixProvider, useIncidentMatrixContext } from "./IncidentMatrixProvider";
import { Matrix } from "./Matrix";
import { Breadcrumbs, TrendIndicatorBar } from "./Matrix/components";

const IncidentMatrixContent = () => {
    const isIncidentMatrixLevel2and3Enabled = useFeatureFlag(featureFlags.incidentMatrixLevel2and3);
    const { t } = useTranslation();
    const { matrixCategory } = useIncidentMatrixContext();
    return (
        <>
            <Breadcrumbs />
            {isIncidentMatrixLevel2and3Enabled && <TrendIndicatorBar categoryKey={matrixCategory} />}
            <BoxStyled data-testid="matrix-table-wrapper">
                <InsightHeader title={t("incidentMatrix.header")} />
                <Matrix categoryKey={matrixCategory} />
            </BoxStyled>
        </>
    );
};

export const IncidentMatrix = () => (
    <IncidentMatrixProvider>
        <IncidentMatrixContent />
    </IncidentMatrixProvider>
);
