import { Box, Title } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { REPORT_CATEGORY_ICON_MAP } from "../../../../shared/constants";
import { useIncidentMatrixContext } from "../../../IncidentMatrixProvider";
import { BackButton } from "./BackButton";
import { useBreadcrumbs } from "./Breadcrumbs.hooks";
import { IconStyled, StackStyled } from "./Breadcrumbs.styles";

export const Breadcrumbs = () => {
    const { t } = useTranslation();
    const { setMatrixCategory, matrixCategory, matrixCategoryLevel } = useIncidentMatrixContext();
    const breadcrumbs = useBreadcrumbs(matrixCategory);
    const categoryLevel1Icon = breadcrumbs.length > 0 ? REPORT_CATEGORY_ICON_MAP[breadcrumbs[0]] : null;

    return (
        <Box mb="M">
            {matrixCategoryLevel > 1 && <BackButton categoryKey={breadcrumbs[0]} />}
            <StackStyled alignItems="center">
                {categoryLevel1Icon && <IconStyled variant={categoryLevel1Icon} size="L" />}
                {breadcrumbs.map((categoryKey) => {
                    return (
                        <Title key={categoryKey} onClick={() => setMatrixCategory(categoryKey)}>
                            {t(`incident.category.${categoryKey}`)}
                        </Title>
                    );
                })}
            </StackStyled>
        </Box>
    );
};
