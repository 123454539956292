import { formatDateTimeByTimezone, getLocalDateByReferenceTimeAndOffsetDateTime } from "../../../../helpers/reportTemplates/dynamicTemplateDateTimeHelper";
import { getBooleanValue, getFieldValueByFieldName, getSiteLocation } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../../models/ReportModel";
import { AmazonGeneralFields } from "../../../../models/reportTemplates/AmazonGeneral";
import { DescriptionSectionPdf } from "../../../PdfReports/shared/PdfDescriptionSection";
import { PdfReportText } from "../../../PdfReports/shared/PdfReportText";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { DateRangeSummaryFieldPdf, TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { conditionalRender, getPdfUnknownFields } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";

export const getAmazonGeneralReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
    {
        component: DescriptionSectionPdf,
        props: {
            sectionTitle: "reportdetails.amazon.description",
        },
        children: [
            {
                component: PdfReportText,
                props: {
                    description: getFieldValueByFieldName(AmazonGeneralFields.description, report),
                    shouldAutoTranslate: true,
                    images: [],
                },
            },
        ],
    },
    conditionalRender(getFieldValueByFieldName(AmazonGeneralFields.reasonOfIncident, report), () => {
        return {
            component: DescriptionSectionPdf,
            props: {
                sectionTitle: "reportdetails.amazon.reasonOfIncident",
            },
            children: [
                {
                    component: PdfReportText,
                    props: {
                        sectionTitle: "reportdetails.amazon.reasonOfIncident",
                        description: getFieldValueByFieldName(AmazonGeneralFields.reasonOfIncident, report),
                        isRequired: false,
                        shouldAutoTranslate: true,
                    },
                },
            ],
        };
    }),
    conditionalRender(getFieldValueByFieldName(AmazonGeneralFields.actionsToBeTaken, report), () => {
        return {
            component: DescriptionSectionPdf,
            props: {
                sectionTitle: "reportdetails.amazon.actionsToBeTakenRecommendation",
            },
            children: [
                {
                    component: PdfReportText,
                    props: {
                        sectionTitle: "reportdetails.amazon.actionsToBeTakenRecommendation",
                        description: getFieldValueByFieldName(AmazonGeneralFields.actionsToBeTaken, report),
                        isRequired: false,
                    },
                },
            ],
        };
    }),
    {
        component: SummarySectionPdf,
        props: {
            label: "reportDetails.amazon.summary.reportSummary",
        },
        children: [
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.summary.location.title",
                    fieldValue: getSiteLocation(report),
                    shouldAutoTranslate: false,
                },
            },
            {
                component: DateRangeSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.amazon.summary.eventDuration",
                    fieldValue: getFieldValueByFieldName(AmazonGeneralFields.dateTimeStarted, report),
                    fieldValue2: getFieldValueByFieldName(AmazonGeneralFields.dateTimeEnded, report),
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportDetails.amazon.summary.title",
                    fieldValue: getFieldValueByFieldName(AmazonGeneralFields.title, report),
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "common.policeInvolved",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonGeneralFields.policeInvolved, report)),
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "common.medicalsInvolved",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonGeneralFields.medicalServicesInvolved, report)),
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "common.fireDeptInvolved",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonGeneralFields.fireDepartmentInvolved, report)),
                },
            },
            {
                component: DateRangeSummaryFieldPdf,
                props: {
                    fieldValue: getLocalDateByReferenceTimeAndOffsetDateTime(
                        getFieldValueByFieldName(AmazonGeneralFields.timeOnSite, report),
                        getFieldValueByFieldName(AmazonGeneralFields.timeOffSite, report),
                        report.reportDateTime,
                    ),
                    fieldValue2: formatDateTimeByTimezone(
                        report.reportDateTime.split("T")[0],
                        getFieldValueByFieldName(AmazonGeneralFields.timeOffSite, report),
                        report.reportDateTime,
                    ),
                    fieldName: "reportdetails.amazon.summary.timeOnSite",
                    isRequired: false,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportDetails.amazon.summary.reference",
                    fieldValue: getFieldValueByFieldName(AmazonGeneralFields.reference, report),
                    isRequired: false,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "common.supervisorNotified",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonGeneralFields.siteSupervisorNotified, report)),
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "common.facilityManagementActionReq",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonGeneralFields.facilityManagementActionRequired, report)),
                    isRequired: false,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "common.socActionRequired",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonGeneralFields.socActionRequired, report)),
                    isRequired: false,
                },
            },
            ...getPdfUnknownFields(report, Object.values(AmazonGeneralFields), images),
        ],
    },
];
