import { parseSqliteResult } from "src/helpers/parseSqliteResult";
import { CategoryLevel1Keys } from "src/models/ReportCategoryModel";
import { getParameterizedArray, getSqlStringifiedArrayFromObject } from "src/sql/scripts/queryUtilities";

export const query = ({ siteIds, startDate, endDate }: { siteIds: string[]; startDate; endDate }) => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);
    return {
        sql: `
            SELECT strftime('%w',report_date_time) as weekDay, count(id) as totalEvents
            FROM reports
            WHERE category_level1 IS NOT NULL
                AND category_level1 <> ""
                AND category_level1 <> "${CategoryLevel1Keys.internalSecuritas}"
                AND report_date_time >= STRFTIME('%Y-%m-%d %H:%M:%S', $startDate)
                AND report_date_time <= STRFTIME('%Y-%m-%d %H:%M:%S', $endDate)
                AND location_id IN (${stringifiedSites})
            GROUP BY strftime('%w',report_date_time)
        `,
        params: {
            $startDate: startDate,
            $endDate: endDate,
            ...siteIdsParams,
        },
    };
};

export const parseResult = ({ results }) => parseSqliteResult<{ weekDay: string; totalEvents: number }>(results[0], true);
