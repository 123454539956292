import { PersistedState } from "redux-persist";
import { RawDateString } from "../../helpers/date";

export const FILTER_SELECT_REGIONS = "@@filter/FILTER_SELECT_REGIONS";
export const FILTER_APPLY_FILTERS = "@@filter/FILTER_APPLY_FILTERS";
export const FILTER_APPLY_PERSISTED_STATE = "@@filter/FILTER_APPLY_PERSISTED_STATE";
export const FILTER_SELECT_SEVERITY_LEVELS = "@@filter/FILTER_SELECT_SEVERITY_LEVELS";
export const FILTER_SELECT_SITE_OBJECTS = "@@filter/FILTER_SELECT_SITE_OBJECTS";
export const FILTER_SELECT_REPORT_TYPE = "@@filter/FILTER_SELECT_REPORT_TYPE";
export const FILTER_SELECT_TO_DATE = "@@filter/FILTER_SELECT_TO_DATE";
export const FILTER_CLEAR_DATES_SELECTION = "@@filter/FILTER_CLEAR_DATES_SELECTION";
export const FILTER_CLEAR_CATEGORIES = "@@filter/FILTER_CLEAR_CATEGORIES";
export const FILTER_CLEAR_REGIONS = "@@filter/FILTER_CLEAR_REGIONS";
export const SHOW_ONLY_UNREAD_CHANGE = "@@filter/SHOW_ONLY_UNREAD_CHANGE";

export interface IFilterState extends PersistedState {
    selectedRegions: string[];
    selectedSeverityLevels: string[];
    selectedCategories1: string[];
    selectedCategories2: string[];
    selectedCategories3: string[];
    selectedReportTypes: string[];
    selectedSiteLocations: string[];
    selectedStartDate: RawDateString | null;
    selectedEndDate: RawDateString | null;
    showOnlyUnread?: boolean;
}
