import { useTranslation } from "react-i18next";
import { Switcher } from "src/components/shared/Switcher";

import { Graph, GraphWrapperStyled, Widget } from "../shared/components";
import { GraphLegend } from "../shared/components/GraphLegend";
import { WIDGET_VIEW_MODE_OPTIONS } from "../Summary.constants";
import { useSiteReportsWidget } from "./SiteReportsWidget.hooks";

export const SiteReportsWidget = () => {
    const { t } = useTranslation();
    const { viewMode, graphData, isSwitcherVisible, isLoading, isEmpty, setViewMode } = useSiteReportsWidget();

    return (
        <Widget
            data-testid="site-reports-widget"
            title={t("insights.summary.siteReportsWidget.title")}
            tooltip={t(`insights.summary.siteReportsWidget.tooltip.${viewMode}`)}
            topRightCornerSection={isSwitcherVisible && <Switcher options={WIDGET_VIEW_MODE_OPTIONS} onChange={setViewMode} />}
            isEmpty={isEmpty}
            isLoading={isLoading}
            emptyStateInfo={t("common.adjustTheDateAndFilters")}
            footer={<GraphLegend title={t("insights.summary.siteReportsWidget.graphLegend.title")} data={graphData.data} />}
        >
            <GraphWrapperStyled mt="XL">
                <Graph data={graphData.data} maxValue={graphData.maxValue} barSize="md" />
            </GraphWrapperStyled>
        </Widget>
    );
};
