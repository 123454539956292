import { Breakpoints, Palette } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { getCellColor } from "../../../IncidentMatrix.helpers";
import { CellStyled } from "../../Matrix.styles";

type DataCellStyledProps = {
    categoryIncidentCount: number;
    maxIncidentCount: number;
    onClick?: () => void;
};

export const DataCellStyled = styled(CellStyled)<DataCellStyledProps>`
    background: ${(props) => getCellColor(props.categoryIncidentCount, props.maxIncidentCount)};
    color: ${(props) => (props.categoryIncidentCount / props.maxIncidentCount >= 0.5 ? Palette.Gray100 : Palette.Navy800)};

    @media (max-width: ${Breakpoints.XS}) {
        min-width: 100px;
    }

    &:not(:disabled) {
        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, 0);
            z-index: 1;
            pointer-events: none;
            transition: background 0.2s;
        }

        &:hover {
            &::after {
                background: rgba(0, 0, 0, 0.25);
            }

            &::before {
                opacity: 0.75;
                height: 100%;
                background: transparent;
                box-shadow: inset 0 0 0 5px ${Palette.Purple400};
            }
        }

        &:focus {
            &::after {
                background: rgba(0, 0, 0, 0.25);
                box-shadow: inset 0 0 4px 1px ${Palette.Purple400};
            }

            &::before {
                opacity: 0.75;
                height: 100%;
                background: transparent;
                box-shadow: inset 0 0 0 5px ${Palette.Purple400};
            }
        }

        &:active {
            &::after {
                background: rgba(0, 0, 0, 0.25);
            }
        }
    }
`;
