import { generateStringForQuery, getReportQueryTimeRangeCondition } from "../queryUtilities";

export const getIncidentsLocationsCategories = (
    siteIds: string[],
    start: Date,
    end: Date,
    categories: string[],
    reportTypes: string[],
    withSiteZone = false,
) => {
    return {
        sql: `SELECT root_site_location, COUNT(root_site_location)
                FROM reports
                WHERE ${getReportQueryTimeRangeCondition("$startDate", "$endDate", withSiteZone)}
                AND location_id IN (${generateStringForQuery(siteIds)})
                AND template in (${generateStringForQuery(reportTypes)})
                AND category_level1 IN (${generateStringForQuery(categories)})
                GROUP BY root_site_location;`,
        params: {
            $startDate: start.toISOString(),
            $endDate: start.toISOString(),
        },
    };
};
