import { Box, Icon } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { Tooltip } from "../../../../components/shared/Tooltip";

export const AdditionalInfoTooltip = () => {
    const { t } = useTranslation();
    const TooltipContents = useMemo(
        () =>
            `<div>
            ${t("insights.trendingEvents.infoTooltip.defaultDesc")}
            </br>
            </br>
            ${t("insights.trendingEvents.infoTooltip.customDesc")}
        </div>`,
        [t],
    );

    return (
        <Box ml="XS">
            <Tooltip id="indicents-over-time-tooltip" label={TooltipContents} place="top">
                <Icon variant="Info" />
            </Tooltip>
        </Box>
    );
};
