export enum TaskScheduleStatus {
    scheduled = "SCHEDULED",
    inProgress = "IN_PROGRESS",
    completed = "COMPLETED",
    missed = "MISSED",
    cancelled = "CANCELLED",
}

export enum ScheduleType {
    recurring = "RECURRING",
    oneTime = "ONE_TIME",
}

export type TaskSchedule = {
    taskId: string;
    description: string;
    taskEventId: string;
    taskType: string;
    status: TaskScheduleStatus;
    startTime: string;
    expectedDuration: string;
    position: { id: string; name: string };
    scheduleType: ScheduleType;
    tourSessionId: string;
    assignedTo?: {
        id: string;
        name: string;
    };
};
