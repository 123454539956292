import { Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { TourStatus } from "src/components/shared/TourStatus";

import { ReportsListItemType, ReportTemplateEnum } from "../../../../../models/ReportModel";
import { ItemDetailsStyled } from "../ReportsListItem.styles";
import { CategoriesStack } from "./ListItemPatrolTourSummary.styled";
import { PercentageBadge } from "./SharedStyles";

export const ListItemPatrolTourSummary = ({ report }: { report: ReportsListItemType }) => {
    const { t } = useTranslation();
    if (report.type !== ReportTemplateEnum.patrolTour) return null;

    const missed = +(report.properties.find((p) => p.key === "missedCheckpointsTotal")?.value ?? 0);
    const total = +(report.properties.find((p) => p.key === "checkpointsTotal")?.value ?? 0);
    const exceptionIdsValue = report.properties.find((p) => p.key === "exceptionIds")?.value ?? 0;
    const exceptionCount = exceptionIdsValue ? JSON.parse(exceptionIdsValue).length : 0;

    if (!total) {
        return null;
    }

    return (
        <ItemDetailsStyled>
            <Stack gap="XS" direction="row" flexWrap="wrap">
                <PercentageBadge label={`${Math.round(((total - missed) / total) * 100)}%`} mode="outlined" />
                <CategoriesStack direction="row" flexWrap="wrap">
                    <TourStatus color="medium">{`${exceptionCount} ${t("reportlist.deviations", { count: exceptionCount })}`}</TourStatus>
                    <TourStatus color="high">{`${missed} ${t("reportlist.missed")}`}</TourStatus>
                </CategoriesStack>
            </Stack>
        </ItemDetailsStyled>
    );
};
