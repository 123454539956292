import { createSelector } from "reselect";
import { createDateFromRawString, isSameDay } from "src/helpers/date";

import { IState } from "../../Store";
import { defaultState } from "./FilterReducer";

const mapSelectedRegions = ({ filter: { selectedRegions } }: IState) => selectedRegions;
const getSelectedRegions = createSelector([mapSelectedRegions], (selectedRegions) => selectedRegions);

const mapSelectedSeverityLevels = ({ filter: { selectedSeverityLevels } }: IState) => selectedSeverityLevels;
const getSelectedSeverityLevelIds = createSelector([mapSelectedSeverityLevels], (selectedSeverityLevels) => selectedSeverityLevels);

const mapSelectedCategoriesOne = ({ filter: { selectedCategories1: selectedCategoriesOne } }: IState) => selectedCategoriesOne;
const getSelectedCategoryOneIds = createSelector([mapSelectedCategoriesOne], (selectedCategoriesOne) => selectedCategoriesOne);

const mapSelectedCategoriesTwo = ({ filter: { selectedCategories2: selectedCategoriesTwo } }: IState) => selectedCategoriesTwo;
const getSelectedCategoryTwoIds = createSelector([mapSelectedCategoriesTwo], (selectedCategoriesTwo) => selectedCategoriesTwo);

const mapSelectedCategoryThree = ({ filter: { selectedCategories3: selectedCategoryThree } }: IState) => selectedCategoryThree;
const getSelectedCategoryThreeIds = createSelector([mapSelectedCategoryThree], (selectedCategoryThree) => selectedCategoryThree);

const mapSelectedReportTypes = ({ filter: { selectedReportTypes } }: IState) => selectedReportTypes;
const getSelectedReportTypes = createSelector([mapSelectedReportTypes], (selectedReportTypes) => selectedReportTypes);

const mapSelectedSiteLocations = ({ filter: { selectedSiteLocations } }: IState) => selectedSiteLocations;
const getSelectedSiteLocations = createSelector([mapSelectedSiteLocations], (selectedSiteLocations) => selectedSiteLocations);

const mapSelectedStartDate = ({ filter: { selectedStartDate } }: IState) => selectedStartDate;
const getSelectedStartDate = createSelector([mapSelectedStartDate], (selectedStartDate) => selectedStartDate);

const mapSelectedEndDate = ({ filter: { selectedEndDate } }: IState) => selectedEndDate;
const getSelectedEndDate = createSelector([mapSelectedEndDate], (selectedEndDate) => selectedEndDate);

const mapShowOnlyUnread = ({ filter: { showOnlyUnread } }: IState) => showOnlyUnread;
const getShowOnlyUnreadSelector = createSelector([mapShowOnlyUnread], (showOnlyUnread) => showOnlyUnread);

const mapAllFilterSelector = ({ filter }: IState) => filter;
const getAllFiltersToSave = createSelector([mapAllFilterSelector], (filter) => {
    const isDefaultDateRange =
        isSameDay(createDateFromRawString(filter.selectedStartDate), createDateFromRawString(defaultState.selectedStartDate)) &&
        isSameDay(createDateFromRawString(filter.selectedEndDate), createDateFromRawString(defaultState.selectedEndDate));
    return {
        ...filter,
        selectedStartDate: isDefaultDateRange ? null : filter.selectedStartDate,
        selectedEndDate: isDefaultDateRange ? null : filter.selectedEndDate,
    };
});

const getAllFilters = createSelector([mapAllFilterSelector], (filter) => filter);

export default {
    getSelectedRegions,
    getSelectedSeverityLevelIds,
    getSelectedCategoryOneIds,
    getSelectedCategoryTwoIds,
    getSelectedCategoryThreeIds,
    getSelectedReportTypes,
    getSelectedStartDate,
    getSelectedEndDate,
    getSelectedSiteLocations,
    getShowOnlyUnreadSelector,
    getAllFiltersToSave,
    getAllFilters,
};
