import { Breakpoints, Palette, useHasMaxWidth } from "@secuis/ccp-react-components";
import { get } from "lodash";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Bar, BarChart, Cell, LabelList, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { CategoricalChartState } from "recharts/types/chart/generateCategoricalChart";

import { useGraphOverviewRedirection } from "../../../store/insights/GraphOverviewHooks";
import { IKeyValuePair } from "../../../store/insights/InsightsModel";
import { CustomizedLabel } from "./CustomizedGraphLabel";
import { CustomizedGraphTooltip } from "./CustomizedGraphTooltip";

const getFillColor = (currentName: string, activeName: string, clickedName: string) => {
    return currentName === clickedName ? Palette.Purple400 : getOtherFillColor(currentName, activeName);
};

const getOtherFillColor = (currentName: string, activeName: string) => {
    return currentName === activeName ? Palette.Purple300 : Palette.Purple200;
};

interface InsightsGraphOneProps {
    data: IKeyValuePair<string, number>[];
}

export const InsightsCategoriesGraph = ({ data }: InsightsGraphOneProps) => {
    const { t } = useTranslation();
    const [hoverName, setHoverName] = useState<string>("");
    const [clickedName, setClickedName] = useState<string>("");
    const [clickCoordinates, setClickCoordinates] = useState<{ x: number; y: number }>({ x: 0, y: 0 });
    const [tooltipWidth, setTooltipWidth] = useState<number>();
    const [tooltipVisible, setTooltipVisible] = useState<boolean>(false);
    const responsiveContainerRef = useRef(null);
    const [containerWidth, setContainerWidth] = useState<number>(0);
    const { redirectToCategoryLevel2Reports } = useGraphOverviewRedirection();
    const tooltipRedirectId = "top5-category-link";
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    const dataToDisplay = useMemo(() => {
        return data.map((d) => {
            const incidentCategoryTranslation = t(`incident.category.${d.name}`);
            return {
                key: d.name,
                name: `${incidentCategoryTranslation}`,
                value: d.value,
            };
        });
    }, [data, t]);

    const handleWidthChange = (width: number) => {
        if (width) setTooltipWidth(width);
    };

    const handleMouseDown = (e: CategoricalChartState) => {
        if (e?.activeCoordinate) setClickCoordinates(e?.activeCoordinate);
        if (e?.activeLabel) {
            setClickedName(e.activeLabel);
        } else setClickedName("");
    };

    const handleMouseMove = (e: CategoricalChartState) => {
        if (e?.activeLabel && !isMobile) {
            setHoverName(e.activeLabel);
        } else setHoverName("");
    };

    const setFocus = (event) => {
        if (event.target.closest(`#${tooltipRedirectId}`)) {
            return;
        }
        if (
            responsiveContainerRef.current?.current?.contains(event.target) &&
            (event.target.tagName.toLowerCase() === "path" || event.target.tagName.toLowerCase() === "text")
        ) {
            setTooltipVisible(true);
        } else {
            setTooltipVisible(false);
            setClickedName("");
        }
    };

    useEffect(() => {
        window.addEventListener("mousedown", setFocus);

        return () => {
            window.removeEventListener("mousedown", setFocus);
        };
    }, []);

    const RenderCustomTooltip = useCallback(
        (payload) => {
            const value = get(payload, "payload.0.payload.value");

            if (!payload?.payload || !value) {
                return null;
            }
            const content = (
                <>
                    {value} {t("insights.overview.event", { count: value })}
                </>
            );

            return CustomizedGraphTooltip({
                redirectElementId: tooltipRedirectId,
                active: tooltipVisible,
                content,
                widthHandler: handleWidthChange,
                buttonLabel: t("insights.overview.seeReports", { count: payload?.payload[0]?.value }),
                onClick: () => {
                    const key = payload?.payload[0]?.payload?.key;
                    if (key) {
                        setTooltipVisible(false);
                        redirectToCategoryLevel2Reports(key);
                    }
                },
            });
        },
        [redirectToCategoryLevel2Reports, tooltipVisible, t],
    );
    const containerHeight = useMemo(() => {
        // numbers of bars * height + number of margins * gapSize
        return data.length * 26 + (data.length - 1) * 8;
    }, [data]);

    useEffect(() => {
        setContainerWidth(responsiveContainerRef.current?.current?.clientWidth);
    }, [responsiveContainerRef.current?.current?.clientWidth]);

    return (
        <ResponsiveContainer width="100%" height={containerHeight} ref={responsiveContainerRef}>
            <BarChart
                data={dataToDisplay}
                layout="vertical"
                barSize={26}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseLeave={() => setHoverName("")}
                margin={{ right: 0, left: 0, top: -5, bottom: 0 }}
            >
                <XAxis type="number" dataKey="value" tick={false} axisLine={false} domain={[0, data[0]?.value ?? 0]} hide />
                <YAxis type="category" dataKey="name" tick={false} axisLine={false} hide />
                {data?.length > 0 && (
                    <Tooltip
                        key={data[0].name}
                        trigger="click"
                        allowEscapeViewBox={{ x: true, y: true }}
                        content={RenderCustomTooltip}
                        cursor={false}
                        position={{ x: clickCoordinates.x - tooltipWidth / 2, y: clickCoordinates.y - 73 }}
                        active={tooltipVisible}
                        wrapperStyle={{ outline: "none" }}
                    ></Tooltip>
                )}
                <Bar dataKey="value">
                    {dataToDisplay.map((entry, index) => (
                        <Cell cursor="pointer" fill={getFillColor(entry.name, hoverName, clickedName)} key={`cell-${index}`} />
                    ))}
                    <LabelList
                        dataKey="value"
                        position="end"
                        content={<CustomizedLabel x y name width containerWidth={containerWidth} value isMainTooltipVisible={tooltipVisible} />}
                    />
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
};
