import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const TabContentWrapper = styled(Stack)`
    flex: 1;
    min-width: 0;
    flex-direction: column;
    padding: ${Spacing.M}px 40px;

    @media (max-width: ${Breakpoints.XS}) {
        gap: ${Spacing.S}px;
        padding: ${Spacing.S}px;
    }
`;
