import { useEffect } from "react";

import { useSwipeable } from "./Swipeable.hooks";
import { SwipeableContainerRightStyled, SwipeableElementStyled, WrapperStyled } from "./Swipeable.styles";

type Props = {
    children: React.ReactNode;
    contentRight?: React.ReactNode;
    closeOnClick?: boolean;
};

export const Swipeable = ({ children, contentRight, closeOnClick }: Props) => {
    const { swipableRef, containerRightRef, handleClose, handleTouchStart, handleTouchMove, handleTouchEnd } = useSwipeable();

    useEffect(() => {
        if (closeOnClick) {
            document.addEventListener("mouseup", handleClose);
            return () => {
                document.removeEventListener("mouseup", handleClose);
            };
        }
    }, [closeOnClick, handleClose]);

    return (
        <WrapperStyled data-testid="swipeable" ref={swipableRef} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd}>
            <SwipeableElementStyled>{children}</SwipeableElementStyled>
            {contentRight && <SwipeableContainerRightStyled ref={containerRightRef}>{contentRight}</SwipeableContainerRightStyled>}
        </WrapperStyled>
    );
};
