import { Breakpoints, Palette, Spacing, Stack, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const ListItemWrapperStyled = styled(Stack)`
    margin-bottom: ${Spacing.XS}px;

    @media (min-width: ${Breakpoints.S}) and (max-width: ${Breakpoints.M}) {
        margin-bottom: ${Spacing.XS}px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        margin-inline: ${Spacing.S}px;
        overflow: hidden;
    }
`;

export const ListItemStyled = styled(Stack)<{ isActive: boolean; isFocusable: boolean }>`
    width: 100%;
    gap: ${Spacing.XS}px;
    position: relative;
    cursor: pointer;
    border: 2px solid ${({ isActive }) => (isActive ? Palette.Purple300 : "transparent")};
    background-color: ${Palette.Navy750};

    padding: ${Spacing.S}px ${Spacing.L}px ${Spacing.S}px ${Spacing.L}px;

    &:active {
        background-color: ${({ isFocusable }) => (isFocusable ? Palette.Navy850 : Palette.Navy750)};
    }

    @media (min-width: ${Breakpoints.M}) {
        &:hover {
            background-color: ${Palette.Navy800};
            border: 2px solid ${({ isActive }) => (isActive ? Palette.Purple400 : "transparent")};
        }
    }

    @media (max-width: ${Breakpoints.XS}) {
        padding-right: ${Spacing.M}px;
    }
`;

export const ColumnStackStyled = styled(Stack)`
    flex: 1;
    flex-direction: column;
    gap: ${Spacing.XS}px;
`;

export const ColumnActionsStackStyled = styled(Stack).attrs({
    ml: "M",
    alignItems: "center",
    flexDirection: "column",
    gap: "XS",
})``;

export const ReportNamesStyled = styled.div`
    position: relative;
`;

export const TemplateStackStyled = styled(Stack)`
    column-gap: ${Spacing.XS}px;
    row-gap: 0;
`;

export const UnreadDotWrapperStyled = styled.div`
    padding: ${Spacing.XS}px 6px;
    position: absolute;
    left: -${Spacing.M}px;
`;

export const ItemDetailsStyled = styled.div`
    align-items: center;
`;

export const WrapabbleFlexStyled = styled(Stack)`
    flex-wrap: wrap;

    > ${Text} {
        display: inline-block;
        white-space: pre-wrap;
    }
`;

export const ReportIdTextStyled = styled(Text)`
    text-align: right;
    word-break: break-all;
`;
