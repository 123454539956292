import { Box, Stack } from "@secuis/ccp-react-components";
import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";
import millify from "millify";
import { useTranslation } from "react-i18next";

import { REPORT_CATEGORY_ICON_MAP } from "../../../../shared/constants";
import { CategoryCellStyled, CategoryIconStyled, CategoryNameStyled, CategoryTotalStyled } from "./CategoryCell.styles";

type CategoryCellProps = {
    categoryKey: string;
    totalCount: number;
    onClick?: () => void;
    disabled?: boolean;
};

export const CategoryCell = ({ categoryKey, totalCount, onClick, disabled = false }: CategoryCellProps) => {
    const { t } = useTranslation();
    const isNotLargeScreen = useHasMaxWidth(Breakpoints.L);
    const categoryIcon = REPORT_CATEGORY_ICON_MAP[categoryKey];

    return (
        <CategoryCellStyled onClick={onClick} disabled={disabled}>
            <Stack alignItems="flex-start" justifyContent="center" direction="column">
                <Stack alignItems="center">
                    {categoryIcon && <CategoryIconStyled color="white" size={isNotLargeScreen ? "M" : "S"} variant={categoryIcon} />}

                    <Box paddingLeft={categoryIcon ? "M" : null}>
                        <CategoryNameStyled micro truncate={true} data-testid="matrix-table-data-category-name">
                            {t(`incident.category.${categoryKey}`)}
                        </CategoryNameStyled>
                    </Box>
                </Stack>
                <CategoryTotalStyled small color="secondary" data-testid="matrix-table-data-category-total">
                    {t("incidentMatrix.table.total")}: {millify(totalCount)}
                </CategoryTotalStyled>
            </Stack>
        </CategoryCellStyled>
    );
};
