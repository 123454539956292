import { RSAA } from "redux-api-middleware";

import { APP_ROLES } from "../../models/RoleModel";
import {
    CLEAR_CLIENTS_SEARCH,
    GET_AVAILABLE_CLIENTS_FAILURE,
    GET_AVAILABLE_CLIENTS_REQUEST,
    GET_AVAILABLE_CLIENTS_SUCCESS,
    GET_AVAILABLE_CLIENTS_TOTAL_COUNT_FAILURE,
    GET_AVAILABLE_CLIENTS_TOTAL_COUNT_REQUEST,
    GET_AVAILABLE_CLIENTS_TOTAL_COUNT_SUCCESS,
    GET_CLIENT_AUTHORIZATIONS_FAILURE,
    GET_CLIENT_AUTHORIZATIONS_REQUEST,
    GET_CLIENT_AUTHORIZATIONS_SUCCESS,
    IAvailableClient,
    IS_USER_AUTHORIZED_AT_FAILURE,
    IS_USER_AUTHORIZED_AT_REQUEST,
    IS_USER_AUTHORIZED_AT_SUCCESS,
    RESET_CLIENTS_STATE,
    SELECT_CLIENT,
} from "../clientSwitcher/types";

const searchAvailableClients = (userId: string, searchQuery?: string, skip = 0) => ({
    [RSAA]: {
        auth: true,
        endpoint: `/v1/authorizationgraph/userorganization/${userId}/clients`,
        method: "POST",
        body: {
            paging: {
                pageSize: 20,
                skip,
            },
            searchQuery,
            labels: ["client"],
            rolesFilter: APP_ROLES,
        },
        headers: {
            "Cache-Control": "no-cache",
        },
        types: [{ type: GET_AVAILABLE_CLIENTS_REQUEST }, { type: GET_AVAILABLE_CLIENTS_SUCCESS, meta: { skip } }, { type: GET_AVAILABLE_CLIENTS_FAILURE }],
    },
});

const getInitialClientsInfo = (userId: string) => ({
    [RSAA]: {
        auth: true,
        endpoint: `/v1/authorizationgraph/userorganization/${userId}/clients`,
        method: "POST",
        body: {
            paging: {
                pageSize: 10,
            },
            labels: ["client"],
            rolesFilter: APP_ROLES,
        },
        headers: {
            "Cache-Control": "no-cache",
        },
        types: [GET_AVAILABLE_CLIENTS_TOTAL_COUNT_REQUEST, GET_AVAILABLE_CLIENTS_TOTAL_COUNT_SUCCESS, GET_AVAILABLE_CLIENTS_TOTAL_COUNT_FAILURE],
    },
});

const isUserAuthorizedAt = (userId: string, locationId: string) => {
    const meta = { locationId };
    return {
        [RSAA]: {
            auth: true,
            endpoint: `/v1/authorization/users/isAuthorizedAt`,
            method: "POST",
            body: JSON.stringify({
                userId,
                locationIds: [locationId],
                verb: "global.reports.view",
            }),
            headers: {
                "Cache-Control": "no-cache",
            },
            silent: true,
            types: [
                { type: IS_USER_AUTHORIZED_AT_REQUEST, meta: meta },
                { type: IS_USER_AUTHORIZED_AT_SUCCESS, meta: meta },
                { type: IS_USER_AUTHORIZED_AT_FAILURE, meta: meta },
            ],
        },
    };
};

const getClientAuthorizations = (clientId: string) => {
    return {
        [RSAA]: {
            auth: true,
            endpoint: `/v1/authorization/records/byLocationId/${clientId}`,
            method: "GET",
            headers: {
                "Cache-Control": "no-cache",
            },
            silent: true,
            types: [{ type: GET_CLIENT_AUTHORIZATIONS_REQUEST }, { type: GET_CLIENT_AUTHORIZATIONS_SUCCESS }, { type: GET_CLIENT_AUTHORIZATIONS_FAILURE }],
        },
    };
};

export const selectClient = (client: IAvailableClient | undefined) => ({
    type: SELECT_CLIENT,
    payload: client,
});

export const clearFilteredClients = () => ({
    type: CLEAR_CLIENTS_SEARCH,
});

export const resetClients = () => ({
    type: RESET_CLIENTS_STATE,
});

const actions = {
    searchAvailableClients,
    getInitialClientsInfo,
    isUserAuthorizedAt,
    getClientAuthorizations,
    selectClient,
    resetClients,
    clearFilteredClients,
};

export default actions;
