import { Breakpoints, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { SiteHeaderCellStyled } from "./SiteHeaderCell.styles";

export const SiteHeaderCell = () => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { t } = useTranslation();

    return (
        <>
            {!isMobile && (
                <SiteHeaderCellStyled>
                    <Text small color="secondary">
                        {t("incidentMatrix.table.sitesColumn.header")}
                    </Text>
                </SiteHeaderCellStyled>
            )}
        </>
    );
};
