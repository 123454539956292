import { Breakpoints, Icon, Palette, Spacing, Text } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

import InsightTile from "../../../components/Insights/common/InsightTile";
import { Tooltip } from "../../../components/shared/Tooltip";
import { flexColumnJustifyCenterAlignCenter, flexRow } from "../../../styles/templates/Flex";

export const GraphContainer = styled.div`
    ${flexRow}
    height: fit-content;
    column-gap: ${Spacing.S}px;

    @media (max-width: ${Breakpoints.S}) {
        flex-direction: column;
    }
`;

const graphContainerBase = css`
    border-radius: 4px;
    background-color: ${Palette.Navy650};
    min-width: 300px;
    height: 241px;
    padding: ${Spacing.S}px;

    @media (max-width: ${Breakpoints.S}) {
        width: 100%;
        min-width: 260px;

        &:first-child {
            margin-bottom: ${Spacing.S}px;
        }
    }
`;

export const CategoriesGraphContainer = styled.div`
    ${graphContainerBase};
    width: 50%;
    position: relative;

    text {
        // cannot use ccp components, have to hardcode
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 20px;
        letter-spacing: 0.3px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        margin-bottom: ${Spacing.S}px;
        height: 232px;
    }
`;

export const SeverityGraphContainer = styled.div`
    ${graphContainerBase};
    width: 50%;

    @media (max-width: ${Breakpoints.XS}) {
        height: 240px;
    }
`;

export const GraphHeader = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    text-transform: uppercase;
    margin-bottom: ${Spacing.L}px;

    @media (max-width: ${Breakpoints.XS}) {
        margin-bottom: ${Spacing.M}px;
    }
`;

export const SeverityGraphHeader = styled(GraphHeader)`
    margin-bottom: ${Spacing.M}px;
`;

export const HiddenIcon = styled(Icon)`
    visibility: hidden;
`;

export const SeverityLevelTooltip = styled(Tooltip)`
    display: flex;
    width: fit-content;
    margin-left: ${Spacing.XS}px;
`;

export const InsightTileStyled = styled(InsightTile)`
    width: 100%;
    min-width: 400px;
    max-width: 900px;

    @media (max-width: ${Breakpoints.XS}) {
        min-width: initial;
        width: initial;
    }
`;

export const AllReportsContainer = styled.div`
    ${flexRow}
    align-items: center;
    text-transform: uppercase;

    @media (max-width: ${Breakpoints.XS}) {
        align-self: end;
    }
`;

export const LessReportsText = styled(Text)`
    position: absolute;
    bottom: 19px;
`;

export const GraphTitleText = styled(Text)`
    text-align: left;
`;

export const EmptyTile = styled.div`
    height: 232px;
    ${flexColumnJustifyCenterAlignCenter}

    @media (max-width: ${Breakpoints.XS}) {
        height: 480px;
        > div {
            font-size: 16px;
            line-height: 24px;
        }
    }
`;
