import { formatDateTimeByTimezone, getLocalDateByReferenceTimeAndOffsetDateTime } from "../../../../helpers/reportTemplates/dynamicTemplateDateTimeHelper";
import { getBooleanValue, getFieldValueByFieldName, getSiteLocation } from "../../../../helpers/reportTemplates/dynamicTemplatePropertiesHelper";
import { DynamicComponentConfigType, DynamicConfigPropsType } from "../../../../models/DynamicTemplate";
import { DynamicReportType } from "../../../../models/ReportModel";
import { AmazonZone6Fields } from "../../../../models/reportTemplates/AmazonZone6";
import { ConditionalTextFieldPdf } from "../../../PdfReports/shared/PdfConditionalTextField";
import { DescriptionSectionPdf } from "../../../PdfReports/shared/PdfDescriptionSection";
import { PdfReportText } from "../../../PdfReports/shared/PdfReportText";
import { SummarySectionPdf } from "../../../PdfReports/shared/PdfSummarySection";
import { DateRangeSummaryFieldPdf, TextAreaSummaryFieldPdf, TextSummaryFieldPdf } from "../../../PdfReports/shared/PdfSummarySections";
import { getConditionalAnswer } from "../amazonZone6ReportConfig";
import { conditionalRender, getPdfUnknownFields } from "../DynamicTemplateUtils";
import { getBaseReportConfigPdf } from "./baseReportConfigPdf";

export const getAmazonZone6ReportConfigPdf = ({
    report,
    siteObject,
    images,
    sharedReportId,
    user,
}: DynamicConfigPropsType<DynamicReportType>): DynamicComponentConfigType[] => [
    ...getBaseReportConfigPdf(report, siteObject, sharedReportId, user),
    {
        component: DescriptionSectionPdf,
        props: {
            sectionTitle: "reportdetails.amazon.description",
        },
        children: [
            {
                component: PdfReportText,
                props: {
                    description: getFieldValueByFieldName(AmazonZone6Fields.description, report),
                    shouldAutoTranslate: true,
                    images,
                },
            },
        ],
    },
    conditionalRender(getFieldValueByFieldName(AmazonZone6Fields.reasonOfIncident, report), () => {
        return {
            component: DescriptionSectionPdf,
            props: {
                sectionTitle: "reportdetails.amazon.reasonOfIncident",
            },
            children: [
                {
                    component: PdfReportText,
                    props: {
                        description: getFieldValueByFieldName(AmazonZone6Fields.reasonOfIncident, report),
                        shouldAutoTranslate: true,
                    },
                },
            ],
        };
    }),
    conditionalRender(getFieldValueByFieldName(AmazonZone6Fields.actionsToBeTakenRecommendation, report), () => {
        return {
            component: DescriptionSectionPdf,
            props: {
                sectionTitle: "reportdetails.amazon.actionsToBeTakenRecommendation",
            },
            children: [
                {
                    component: PdfReportText,
                    props: {
                        description: getFieldValueByFieldName(AmazonZone6Fields.actionsToBeTakenRecommendation, report),
                        shouldAutoTranslate: true,
                    },
                },
            ],
        };
    }),
    {
        component: SummarySectionPdf,
        props: {
            label: "reportDetails.amazon.summary.reportSummary",
        },
        children: [
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.summary.location.title",
                    fieldValue: getSiteLocation(report),
                    isRequired: true,
                    shouldAutoTranslate: false,
                },
            },
            {
                component: DateRangeSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.amazon.summary.eventDuration",
                    fieldValue: getFieldValueByFieldName(AmazonZone6Fields.dateTimeStarted, report),
                    fieldValue2: getFieldValueByFieldName(AmazonZone6Fields.dateTimeEnded, report),
                    isRequired: true,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportDetails.amazon.summary.title",
                    fieldValue: getFieldValueByFieldName(AmazonZone6Fields.title, report),
                    isRequired: true,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.amazon.summary.whatAlarmsWentOff",
                    fieldValue: getFieldValueByFieldName(AmazonZone6Fields.whatAlarmsWentOff, report),
                    isRequired: false,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.amazon.summary.whatCausedTheAlarmsToActivate",
                    fieldValue: getFieldValueByFieldName(AmazonZone6Fields.whatCausedTheAlarmsToActivate, report),
                    isRequired: false,
                },
            },
            {
                component: TextAreaSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.amazon.summary.additionalInformationOnWhatActivatedTheAlarm",
                    fieldValue: getFieldValueByFieldName(AmazonZone6Fields.additionalInformationOnWhatActivatedTheAlarm, report),
                    isRequired: false,
                },
            },
            {
                component: ConditionalTextFieldPdf,
                props: {
                    fieldName: "reportdetails.amazon.summary.whereThereAnyDeviationsFromTheProcessDescribedAbove",
                    fieldValue: getConditionalAnswer(AmazonZone6Fields.whereThereAnyDeviationsFromTheProcessDescribedAbove, report),
                    shouldTranslateHeaderValue: false,
                    optionalContentName: "reportdetails.amazon.summary.ifYesExplainDeviation",
                    optionalContentValue: getFieldValueByFieldName(AmazonZone6Fields.ifYesExplainDeviation, report),
                    isRequired: false,
                },
            },
            {
                component: ConditionalTextFieldPdf,
                props: {
                    fieldName: "reportdetails.amazon.summary.ifADeviationOccurredFromTheExpectedProcess",
                    fieldValue: getConditionalAnswer(AmazonZone6Fields.ifADeviationOccurredFromTheExpectedProcess, report),
                    shouldTranslateHeaderValue: false,
                    optionalContentName: "reportdetails.amazon.summary.ifYesExplainContribution",
                    optionalContentValue: getFieldValueByFieldName(AmazonZone6Fields.ifYesExplainContribution, report),
                    isRequired: false,
                },
            },
            {
                component: ConditionalTextFieldPdf,
                props: {
                    fieldName: "reportdetails.amazon.summary.wasTheExpectedProcessDescribedInPolicy",
                    fieldValue: getConditionalAnswer(AmazonZone6Fields.wasTheExpectedProcessDescribedInPolicy, report),
                    shouldTranslateHeaderValue: false,
                    optionalContentName: "reportdetails.amazon.summary.ifYesExplainTheSource",
                    optionalContentValue: getFieldValueByFieldName(AmazonZone6Fields.ifYesExplainTheSource, report),
                    isRequired: false,
                },
            },
            {
                component: TextAreaSummaryFieldPdf,
                props: {
                    fieldName: "reportdetails.amazon.summary.otherCommentsOrRelevantInformation",
                    fieldValue: getFieldValueByFieldName(AmazonZone6Fields.otherCommentsOrRelevantInformation, report),
                    isRequired: false,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "common.policeInvolved",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonZone6Fields.policeInvolved, report)),
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "common.medicalsInvolved",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonZone6Fields.medicalServicesInvolved, report)),
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "common.fireDeptInvolved",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonZone6Fields.fireDepartmentInvolved, report)),
                },
            },
            {
                component: DateRangeSummaryFieldPdf,
                props: {
                    fieldValue: getLocalDateByReferenceTimeAndOffsetDateTime(
                        getFieldValueByFieldName(AmazonZone6Fields.timeOnSite, report),
                        getFieldValueByFieldName(AmazonZone6Fields.timeOffSite, report),
                        report.reportDateTime,
                    ),
                    fieldValue2: formatDateTimeByTimezone(
                        report.reportDateTime.split("T")[0],
                        getFieldValueByFieldName(AmazonZone6Fields.timeOffSite, report),
                        report.reportDateTime,
                    ),
                    fieldName: "reportdetails.amazon.summary.timeOnSite",
                    isRequired: false,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "reportDetails.amazon.summary.reference",
                    fieldValue: getFieldValueByFieldName(AmazonZone6Fields.reference, report),
                    isRequired: false,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "common.supervisorNotified",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonZone6Fields.siteSupervisorNotified, report)),
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "common.facilityManagementActionReq",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonZone6Fields.facilityManagementActionRequired, report)),
                    isRequired: false,
                },
            },
            {
                component: TextSummaryFieldPdf,
                props: {
                    fieldName: "common.socActionRequired",
                    fieldValue: getBooleanValue(getFieldValueByFieldName(AmazonZone6Fields.socActionRequired, report)),
                    isRequired: false,
                },
            },
            ...getPdfUnknownFields(report, Object.values(AmazonZone6Fields), images),
        ],
    },
];
