import { Palette } from "@secuis/ccp-react-components";
import { useEffect, useRef, useState } from "react";

const getTextWidth = (textObj: SVGTextElement, nameString: string, valueString: string) => {
    textObj.textContent = nameString + valueString;

    return textObj.getComputedTextLength();
};

const placeTextWithEllipsis = (textObj: SVGTextElement, nameString: string, width: number, valueString: string, isMainTooltipVisible: boolean) => {
    const title = !isMainTooltipVisible ? ` <title>${nameString}</title>` : "";
    const fullText = nameString + valueString;
    textObj.textContent = fullText;

    if (textObj.getComputedTextLength() >= width) {
        for (let x = fullText.length; x > 0; x -= 1) {
            const textWithEllipsis = nameString.substring(0, x) + "... " + valueString;
            textObj.textContent = textWithEllipsis;
            if (textObj.getComputedTextLength() < width) {
                textObj.innerHTML = textWithEllipsis + title;
                return;
            }
        }
        textObj.textContent = "...";
    }
};

export const CustomizedLabel = ({ x, y, name, width: barWidth, containerWidth, value, isMainTooltipVisible }): JSX.Element => {
    const leftMargin = 10;
    const bottomMargin = 18;
    const ref = useRef(null);
    const [additionalWidth, setAdditionalWidth] = useState<number>(0);
    const valueString = ` • ${value}`;
    const textMargins = 30;
    const barAvailableSpace = barWidth - textMargins;

    useEffect(() => {
        if (!ref.current) return;
        const textWidth = getTextWidth(ref.current, name, valueString);

        if (textWidth > barAvailableSpace && barWidth < containerWidth / 2) {
            setAdditionalWidth(barWidth);
            placeTextWithEllipsis(ref.current, name, containerWidth - barWidth - textMargins, valueString, isMainTooltipVisible);
        } else {
            placeTextWithEllipsis(ref.current, name, barAvailableSpace, valueString, isMainTooltipVisible);
        }
    }, [containerWidth, name, value, barWidth, isMainTooltipVisible, valueString, barAvailableSpace]);

    return (
        <text
            x={x + leftMargin + additionalWidth}
            y={y + bottomMargin}
            fill={additionalWidth ? Palette.Gray100 : Palette.Navy800}
            style={{ cursor: "pointer", pointerEvents: "none" }}
            ref={ref}
        ></text>
    );
};
