import { Breakpoints, Palette, useHasMaxWidth } from "@secuis/ccp-react-components";
import ReactDOM from "react-dom";
import { Tooltip as VendorTooltip, TooltipRefProps } from "react-tooltip";
import styled from "styled-components";

import { ChildrenProps } from "../../models/ChildrenPropModel";

export type Place = TooltipRefProps["place"];

type TooltipProps = ChildrenProps & {
    label: string;
    id: string;
    place?: Place;
    disable?: boolean;
    className?: string;
};

const StyledToolTip = styled(VendorTooltip)`
    &:hover {
        cursor: pointer;
    }
`;

const DesktopTooltipPlaceholder = styled.a`
    height: auto;
    display: flex;
    &:hover {
        cursor: pointer;
    }
`;

export const Tooltip = (props: TooltipProps) => {
    const { label, id, place, disable = false, className } = props;
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const renderTooltip = () => {
        return ReactDOM.createPortal(
            <StyledToolTip
                style={{
                    backgroundColor: Palette.Purple300,
                    color: Palette.Navy900,
                    maxWidth: isMobile ? "85vw" : "365px",
                    padding: "5px 5px",
                    borderRadius: "5px",
                    fontSize: "12px",
                    lineHeight: "18px",
                    fontStyle: "normal",
                    fontWeight: "700",
                    letterSpacing: "0.4px",
                    zIndex: "10",
                }}
                opacity="1"
                id={id}
                place={place || "top"}
            />,
            document.body,
        );
    };

    if (disable) {
        return <div>{props.children}</div>;
    }

    return (
        <>
            <DesktopTooltipPlaceholder data-tooltip-content={label} data-tooltip-id={id} data-tooltip-html={label} className={className} tabIndex={0}>
                {props.children}
            </DesktopTooltipPlaceholder>
            {renderTooltip()}
        </>
    );
};
