import { Breakpoints, Icon, Palette, Spacing, Stack, Text, Theme } from "@secuis/ccp-react-components";
import styled, { css } from "styled-components";

import { flexColumn, flexColumnJustifyEnd, flexRow } from "../../../styles/templates/Flex";
import { HighlightsType } from "../../../models";

type NonHighlightsType = "text_card" | "summary_card";

export const cardWidth = 239;
export const cardSpacing = 16;
export const criticalCardWidth = 200;
export const criticalCardHeight = 245;

const summaryCardColors = css`
    background-color: ${Palette.Navy750};
    @media (min-width: ${Breakpoints.XS}) {
        &:hover {
            background-color: ${Palette.Navy800};
        }
        &:active {
            background-color: ${Palette.Navy850};
        }
    }
`;

const textCardColors = css`
    background-color: ${Palette.Navy100};
    @media (min-width: ${Breakpoints.XS}) {
        &:hover {
            background-color: ${Palette.Navy200};
        }
    }
`;

const cardBase = css`
    height: 311px;
    border-radius: ${Spacing.XXS}px;
    text-align: left;
`;

export const CardBaseContainer = styled.div<{ clicked?: boolean; cardType: HighlightsType | NonHighlightsType; cardWidth: number }>`
    ${cardBase}
    width: ${(props) => `${props.cardWidth}px`};
    ${(props) => {
        switch (props.cardType) {
            case "summary_card":
                return summaryCardColors;
            case "text_card":
                return textCardColors;
        }
    }}
`;

export const CardHeader = styled.div`
    ${flexRow};
    flex-grow: 1;
`;

export const CardBody = styled.div`
    ${flexColumnJustifyEnd};
    flex: 1;
`;

export const CardFooter = styled.div`
    margin-top: ${Spacing.S}px;
    align-self: flex-start;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${Spacing.XS}px;
`;

export const PaddedContainer = styled.div`
    padding: ${Spacing.S}px;
    height: 100%;
    ${flexColumn};
`;

export const VerticalLine = styled.div<{ color?: string }>`
    border-left: 2px solid ${(props) => props.color};
    height: 100%;
    margin-right: ${Spacing.S}px;
`;

export const CardHeaderTextWrapper = styled.div`
    min-width: 0;
    flex-grow: 1;
`;

export const CardHeaderText = styled(Text)`
    margin-right: ${Spacing.XS}px;
    margin-left: ${Spacing.XS}px;
`;

export const SummaryCardHeaderText = styled(Text)`
    margin-right: ${Spacing.XS}px;
`;

export const CardDescription = styled.div<{ color?: string }>`
    ${flexRow};
    ${(props) => props.color && `color: ${props.color}`};
`;

export const CardDescriptionText = styled.div<{ isRead?: boolean }>`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    color: ${({ isRead }) => (isRead ? Theme.tertiary : null)};
    ${Text} {
        display: inline;
    }
`;

export const LinkContainer = styled(Stack)`
    &:hover {
        cursor: pointer;
    }
`;

export const TooltipIcon = styled(Icon)`
    margin-bottom: ${Spacing.XXS}px;
`;

const readStyles = css`
    background: ${Palette.Navy750};
    border: none;
    @media (min-width: ${Breakpoints.XS}) {
        &:hover {
            background: ${Palette.Navy800};
        }
        &:active {
            background: ${Palette.Navy850};
        }
    }
`;

const unreadStyles = css`
    background: ${Palette.Navy700};
    border: 2px solid ${Palette.Red300};
    @media (min-width: ${Breakpoints.XS}) {
        &:hover {
            background: ${Palette.Navy800};
            border: 2px solid ${Palette.Red400};
        }
        &:active {
            background: ${Palette.Navy850};
            border: 2px solid ${Palette.Red500};
        }
    }
`;

export const CriticalCardWrapper = styled(Stack)<{ isRead: boolean }>`
    width: ${criticalCardWidth}px;
    height: ${criticalCardHeight}px;
    padding: ${Spacing.S}px;
    flex-shrink: 0;
    border-radius: 4px;
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.25);
    ${({ isRead }) => (isRead ? readStyles : unreadStyles)}
`;
