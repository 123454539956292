import { http, HttpResponse } from "msw";

import { GetByDateRange, GetByDateRangeAndSiteIds } from "../../../store/insights/InsightsModel";
import {
    disabledRequestResolver,
    getDailyTourExceptionsSummaryByCategory1LevelResolver,
    getDailyTourExceptionsSummaryResolver,
    getHourlyTourExceptionsSummaryResolver,
    getLocationsTourSessionSummaryByTourIdResolver,
    getLocationsTourSessionSummaryResolver,
    getReportsInsightsResolver,
} from "../../resolvers";
import { InsightsDbPayload } from "../../types";
import {
    POST_DAILY_TOUR_EXC_SUMMARY_CATEGORY_LEVEL_2_REGEX,
    POST_DAILY_TOUR_EXC_SUMMARY_HOURLY,
    POST_DAILY_TOUR_EXC_SUMMARY_REGEX,
    POST_EXCEPTIONS_INSIGHTS_AGG_URL_REGEX,
    POST_REPORTS_INSIGHTS_URL_REGEX,
    POST_TOUR_SESSIONS_BY_LOCATION_ID_FILTERED_REGEX,
    POST_TOUR_SESSIONS_FILTERED_REGEX,
    PUT_REPORT_URL_REGEX,
    PUT_TOUR_URL_REGEX,
} from "../constants";

export default [
    http.post(POST_EXCEPTIONS_INSIGHTS_AGG_URL_REGEX, () => HttpResponse.json({})),
    http.post(POST_REPORTS_INSIGHTS_URL_REGEX, async ({ request }) => {
        const { minDateTime, siteIds } = (await request.json()) as InsightsDbPayload;

        return getReportsInsightsResolver(minDateTime, siteIds);
    }),
    http.put(PUT_REPORT_URL_REGEX, () => disabledRequestResolver()),
    http.put(PUT_TOUR_URL_REGEX, () => disabledRequestResolver()),
    http.post(POST_DAILY_TOUR_EXC_SUMMARY_CATEGORY_LEVEL_2_REGEX, async ({ request }) => {
        const matches = POST_DAILY_TOUR_EXC_SUMMARY_CATEGORY_LEVEL_2_REGEX.exec(request.url);
        const categoryLevel1 = matches[2];
        const body = (await request.json()) as GetByDateRangeAndSiteIds;
        return getDailyTourExceptionsSummaryByCategory1LevelResolver(categoryLevel1, body);
    }),
    http.post(POST_DAILY_TOUR_EXC_SUMMARY_REGEX, async ({ request }) => {
        const body = (await request.json()) as GetByDateRangeAndSiteIds;
        return getDailyTourExceptionsSummaryResolver(body);
    }),
    http.post(POST_DAILY_TOUR_EXC_SUMMARY_HOURLY, async ({ request }) => {
        const body = (await request.json()) as GetByDateRangeAndSiteIds;
        return getHourlyTourExceptionsSummaryResolver(body);
    }),
    http.post(POST_TOUR_SESSIONS_FILTERED_REGEX, async ({ request }) => {
        const body = (await request.json()) as GetByDateRangeAndSiteIds;
        return getLocationsTourSessionSummaryResolver(body);
    }),
    http.post(POST_TOUR_SESSIONS_BY_LOCATION_ID_FILTERED_REGEX, async ({ request }) => {
        const body = (await request.json()) as GetByDateRange;
        const matches = POST_TOUR_SESSIONS_BY_LOCATION_ID_FILTERED_REGEX.exec(request.url);
        const locationId = matches[2];
        return getLocationsTourSessionSummaryByTourIdResolver(body, locationId);
    }),
];
