import { Breakpoints, Headline, Icon, Skeleton, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { ReactComponent as CheckpointIcon } from "../../../../assets/icons/checkpoints_icon.svg";
import { TranslatableEmptyInboxComponent } from "../../../../components/shared/NoContent/EmptyInboxContent";
import { Tooltip } from "../../../../components/shared/Tooltip";
import { SectionWrapper, TooltipContainer } from "../PatrolTasks.styled";
import { useTooltipContentConfig } from "../TooltipContentConfig";
import { TotalPerformanceTemplate } from "./TotalPerformanceTemplate";

type Props = {
    exceptionsCount: number;
    toursCount: number;
    scannedCheckpointsPercent: number;
    historicalExceptionsCount: number;
    historicalToursCount: number;
    historicalScannedCheckpointsPercent: number;
    isLoading: boolean;
    hasSingleSite?: boolean;
};

export const TotalPerformanceSection = ({
    exceptionsCount,
    toursCount,
    scannedCheckpointsPercent,
    historicalExceptionsCount,
    historicalToursCount,
    historicalScannedCheckpointsPercent,
    isLoading,
    hasSingleSite = false,
}: Props) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { t } = useTranslation();
    const { totalSectionTooltipContent } = useTooltipContentConfig(hasSingleSite);

    return (
        <SectionWrapper display="flex" direction="column" data-testid="insights-tasks-total">
            <TooltipContainer>
                <Headline bold>{t("insights.exceptionTrend.total_count")}</Headline>
                <Tooltip id="totalInfo" label={totalSectionTooltipContent} place="top-start">
                    <Icon variant={"Info"} size="M"></Icon>
                </Tooltip>
            </TooltipContainer>
            {isLoading ? (
                <Stack direction="column" gap="XS" marginTop="M">
                    <Skeleton height={160} mode="rectangular" width="100%" />
                </Stack>
            ) : (
                <>
                    {!toursCount && !exceptionsCount && !historicalExceptionsCount && !historicalToursCount ? (
                        <TranslatableEmptyInboxComponent subtitle={"insights.patrolTours.noDataSubtitle"} title={"insights.patrolTours.noDataTitle"} />
                    ) : (
                        <Stack display="flex" direction={isMobile ? "row" : "column"} mt="S" justifyContent="space-between">
                            {isMobile ? (
                                <>
                                    <Stack display="flex" direction="column" gap="S">
                                        <TotalPerformanceTemplate
                                            icon={<Icon variant="Route" color="neutral" />}
                                            currentCount={toursCount}
                                            historicalCount={historicalToursCount}
                                            isAbsoluteValue
                                            title={t("insights.siteTasks.category.tours")}
                                        />
                                        <TotalPerformanceTemplate
                                            icon={<Icon color="neutral" variant="Deviations" />}
                                            currentCount={exceptionsCount}
                                            historicalCount={historicalExceptionsCount}
                                            isAbsoluteValue
                                            title={t("insights.siteTasks.category.deviations")}
                                        />
                                    </Stack>
                                    <Stack display="flex" direction="column" gap="S">
                                        <TotalPerformanceTemplate
                                            icon={<CheckpointIcon />}
                                            currentCount={scannedCheckpointsPercent}
                                            historicalCount={historicalScannedCheckpointsPercent}
                                            title={t("reportdetails.patrolTour.checkpoints")}
                                        />
                                    </Stack>
                                </>
                            ) : (
                                <Stack display="flex" direction="row" mt="S" justifyContent="space-between">
                                    <TotalPerformanceTemplate
                                        icon={<Icon variant="Route" color="neutral" />}
                                        currentCount={toursCount}
                                        historicalCount={historicalToursCount}
                                        isAbsoluteValue
                                        title={t("insights.siteTasks.category.tours")}
                                    />
                                    <TotalPerformanceTemplate
                                        icon={<Icon color="neutral" variant="Deviations" />}
                                        currentCount={exceptionsCount}
                                        historicalCount={historicalExceptionsCount}
                                        isAbsoluteValue
                                        title={t("insights.siteTasks.category.deviations")}
                                    />
                                    <TotalPerformanceTemplate
                                        icon={<CheckpointIcon />}
                                        currentCount={scannedCheckpointsPercent}
                                        historicalCount={historicalScannedCheckpointsPercent}
                                        title={t("reportdetails.patrolTour.checkpoints")}
                                    />
                                </Stack>
                            )}
                        </Stack>
                    )}
                </>
            )}
        </SectionWrapper>
    );
};
