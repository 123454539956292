import { Breakpoints, ButtonText, Palette, Stack } from "@secuis/ccp-react-components";
import { FocusStyles } from "src/styles/Constants";
import styled from "styled-components";

export const StyledStack = styled(Stack)`
    @media (min-width: ${Breakpoints.XS}) {
        align-self: flex-end;
    }
`;

export const EmptyStateContainer = styled(Stack)`
    background-color: ${Palette.Navy750};
    border-radius: 4px;
`;

export const ButtonTextStyled = styled(ButtonText)`
    &:focus-visible {
        outline-offset: ${FocusStyles.OutlineOffset}px;
        border-radius: ${FocusStyles.BorderRadius}px;
    }
`;
