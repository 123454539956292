import type { SwipeActionItem } from "../../types";
import { ButtonTextStyled } from "./SwipeActionsMenuItem.styles";

type Props = {
    action: SwipeActionItem;
};

export const SwipeActionsMenuItem = ({ action }: Props) => {
    const { label, icon, disabled, onClick } = action;

    return (
        <ButtonTextStyled icon={icon} disabled={disabled} onClick={onClick}>
            {label}
        </ButtonTextStyled>
    );
};
