import { Breakpoints, Palette, Spacing } from "@secuis/ccp-react-components";
import { TableComponent } from "src/components/shared/Table/Table";
import { InvisibleScrollbar, Navy500Scrollbar } from "src/styles/templates/Scrollbar";
import styled from "styled-components";

const MAX_COLUMN_WIDTH = 285;
const COLUMN_SITE_DEFAULT_WIDTH = 340;
const COLUMN_OTHER_WIDTH = 80;

const separatorLine = `
    &::before {
        content: "";
        display: block;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 1px;
        background: ${Palette.Navy300};
        opacity: 0.3;
    }
`;

const TableBodyShadow = `
    &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        width: 19px;
        height: 100%;
        background: linear-gradient(to right, rgba(0, 0, 0, 0), ${Palette.Navy750} 100%);
        opacity: 0.6;
        z-index: 1;
    }
`;

export type TableStyledProps = {
    colMaxWidthEnabled: boolean;
    scrollable: boolean;
    categoryColumnCount: number;
    isFilterActive: boolean;
    isOtherColumnEnabled: boolean;
};

const getGridTemplateColumns = (categoryColumnCount: number, isOtherColumnEnabled: boolean, siteColumnWidth: number, isFilterActive?: boolean) => {
    const adjustedSiteColumnWidth = isFilterActive ? "80px" : `${siteColumnWidth}px`;
    const otherColumn = isOtherColumnEnabled ? `${COLUMN_OTHER_WIDTH}px` : "";

    return `${adjustedSiteColumnWidth} repeat(${categoryColumnCount}, 1fr) ${otherColumn}`;
};

export const TableStyled = styled(TableComponent)<TableStyledProps>`
    position: relative;
    max-width: ${({ colMaxWidthEnabled, categoryColumnCount, isOtherColumnEnabled }) =>
        colMaxWidthEnabled
            ? `${MAX_COLUMN_WIDTH * categoryColumnCount + COLUMN_SITE_DEFAULT_WIDTH + (isOtherColumnEnabled ? COLUMN_OTHER_WIDTH : 0)}px`
            : "none"};

    ${Navy500Scrollbar}

    @media (max-width: ${Breakpoints.XS}) {
        ${InvisibleScrollbar}
    }

    ${separatorLine}

    @media (max-width: 580px) {
        ${TableBodyShadow}
    }

    ${({ scrollable }) =>
        scrollable &&
        `
        div[role="rowgroup"]:nth-child(2) {
            overflow-y: scroll;
            overflow-x: hidden;
            max-height: 640px;

            @media (max-width: ${Breakpoints.L}) {
                max-height: 620px;
            }

            @media (max-width: ${Breakpoints.XS}) {
                max-height: 900px;
            }
        }
    `};

    div[role="rowgroup"] {
        position: relative;

        grid-template-columns: ${({ categoryColumnCount, isOtherColumnEnabled }) =>
            getGridTemplateColumns(categoryColumnCount, isOtherColumnEnabled, COLUMN_SITE_DEFAULT_WIDTH)};

        @media (max-width: ${Breakpoints.XL}) {
            grid-template-columns: ${({ categoryColumnCount, isOtherColumnEnabled }) => getGridTemplateColumns(categoryColumnCount, isOtherColumnEnabled, 290)};
        }

        @media (max-width: ${Breakpoints.M}) {
            grid-template-columns: ${({ isFilterActive, categoryColumnCount }) => getGridTemplateColumns(categoryColumnCount, false, 188, isFilterActive)};
        }

        @media (max-width: ${Breakpoints.XS}) {
            grid-template-columns: ${({ categoryColumnCount }) => `70px repeat(${categoryColumnCount}, auto)`};
        }

        @media (max-width: ${Breakpoints.XS}) {
            overflow-x: hidden;
        }
    }

    div[role="columnheader"] {
        background: ${Palette.Navy750};
        position: relative;
        padding-top: ${Spacing.XS}px;
        z-index: 2;

        ${separatorLine}
    }

    div[role="row"],
    div[role="cell"] {
        padding: 0;
    }

    div[role="row"] {
        background: transparent;
        border: none;

        @media (max-width: ${Breakpoints.XS}) {
            overflow: hidden;
        }

        ${({ isFilterActive }) =>
            isFilterActive &&
            `
            @media (max-width: 850px) {
                div[role="cell"]:not(:first-child) button {
                    padding: ${Spacing.S}px 8px;
                }
            }
        `}

        div[role="cell"] button > div > div:nth-child(2) {
            display: ${({ categoryColumnCount }) => (categoryColumnCount > 1 ? "block" : "none")};
        }
    }
`;

export const CellStyled = styled.button`
    width: 100%;
    height: 100%;
    text-align: left;
    position: relative;
    padding: 20px ${Spacing.S}px;

    @media (max-width: ${Breakpoints.XS}) {
        padding: ${Spacing.L}px ${Spacing.S}px;
    }

    ${separatorLine}
`;
