import { GraphColor } from "@secuis/ccp-react-components";
import { sum } from "lodash";
import { useMemo } from "react";
import { CategoryLevel1, CategoryLevel1Keys } from "src/models/ReportCategoryModel";

import { useSummaryPeriod } from "../shared/hooks";
import { useIncidentCategoriesCount } from "../shared/hooks/useIncidentCategoriesCount";

import { GraphDataItem } from "src/helpers/graphs";

const LABELS_MAP: Record<Exclude<CategoryLevel1, CategoryLevel1Keys.internalSecuritas>, string> = {
    criminal_act_or_suspicious_behavior: "A",
    facility: "B",
    health_and_safety: "C",
    people_and_assets: "D",
};
const COLORS_MAP: Record<Exclude<CategoryLevel1, CategoryLevel1Keys.internalSecuritas>, GraphColor> = {
    criminal_act_or_suspicious_behavior: "dataSeries1",
    facility: "dataSeries2",
    health_and_safety: "dataSeries3",
    people_and_assets: "dataSeries4",
};

const parseToPieChartData = (data: Partial<Record<CategoryLevel1, number>>, total: number): GraphDataItem[] => {
    if (!total) {
        return [];
    }

    return Object.values(CategoryLevel1Keys)
        .filter((k) => k !== CategoryLevel1Keys.internalSecuritas)
        .map((key) => {
            const dataValue = data[key] || 0;

            return {
                value: parseFloat(((dataValue / total) * 100).toFixed(1)),
                label: LABELS_MAP[key],
                color: COLORS_MAP[key],
                name: key,
                key,
            };
        });
};

export const useIncidentDistributionWidget = () => {
    const { currentPeriod, previousPeriod } = useSummaryPeriod();
    const { isLoading: isLoadingCurrent, queryResult: currentValues } = useIncidentCategoriesCount(currentPeriod.start, currentPeriod.end);
    const { isLoading: isLoadingPrevious, queryResult: oldValues } = useIncidentCategoriesCount(previousPeriod.start, previousPeriod.end);

    const incidentCount = useMemo(() => {
        return {
            previousValue: oldValues ? sum(Object.values(oldValues)) : 0,
            value: currentValues ? sum(Object.values(currentValues)) : 0,
        };
    }, [oldValues, currentValues]);

    return {
        incidentCount,
        chartData: parseToPieChartData(currentValues, incidentCount.value),
        isLoading: isLoadingCurrent || isLoadingPrevious,
    };
};
