import { Modal as SclModal } from "@secuis/ccp-react-components";
import { ModalLargeProps, ModalSmallProps } from "@secuis/ccp-react-components/dist/types/components/modal/Modal.model";

import { ModalFixedWrapperStyled } from "./Modal.styles";

type Props = (ModalSmallProps | ModalLargeProps) & {
    isFixed?: boolean;
};

export const Modal = ({ children, isOpen, isFixed = true, ...rest }: Props) => {
    const renderModal = () => (
        <SclModal isOpen={isOpen} {...rest}>
            {children}
        </SclModal>
    );

    if (!isOpen) {
        return null;
    }

    if (isFixed) {
        return <ModalFixedWrapperStyled>{renderModal()}</ModalFixedWrapperStyled>;
    }

    return renderModal();
};
