import { maxBy, sumBy } from "lodash";

import { IsoStringDate } from "../../../../helpers/date";
import { parseSqliteResult } from "../../../../helpers/parseSqliteResult";
import { WorkerExecResult } from "../../../../models/DbModel";
import { CategoryLevel1Keys } from "../../../../models/ReportCategoryModel";
import { getParameterizedArray, getSqlStringifiedArrayFromObject } from "../../../../sql/scripts/queryUtilities";
import { LineChartDataset } from "./LineChart";

export const getDeviantHoursQuery = ({ siteIds, startDate, endDate }: { siteIds: string[]; startDate: IsoStringDate; endDate: IsoStringDate }) => {
    const siteIdsParams = getParameterizedArray(siteIds);
    const stringifiedSites = getSqlStringifiedArrayFromObject(siteIdsParams);

    return {
        sql: `
            SELECT strftime('%H',report_date_time) as hour, count(id) as totalEvents
            FROM reports
            WHERE category_level1 IS NOT NULL
                AND category_level1 <> ""
                AND category_level1 <> "${CategoryLevel1Keys.internalSecuritas}"
                AND report_date_time >= STRFTIME('%Y-%m-%d %H:%M:%S', $startDate)
                AND report_date_time <= STRFTIME('%Y-%m-%d %H:%M:%S', $endDate)
                AND location_id IN (${stringifiedSites})
            GROUP BY strftime('%H',report_date_time)
            ORDER BY hour ASC
        `,
        params: {
            $startDate: startDate,
            $endDate: endDate,
            ...siteIdsParams,
        },
    };
};

export const parseDeviantHours = ({ results }: WorkerExecResult) => {
    const eventsPerHourMap = parseSqliteResult<{ totalEvents: number }>(results[0], true);
    const hoursArray: LineChartDataset["values"] = [...new Array(24)].map((_, hour) => {
        const key = hour.toString().padStart(2, "0");

        return {
            key: key,
            value: eventsPerHourMap[key]?.totalEvents ?? 0,
        };
    });

    const highestEventsCount = maxBy(hoursArray, "value")?.value;

    const topHours = hoursArray.filter(({ value }) => highestEventsCount && value === highestEventsCount).map(({ key }) => key);

    return {
        hourEventCounts: hoursArray,
        topHours,
        totalEvents: sumBy(hoursArray, "value") ?? 0,
    };
};
