import { Text } from "@secuis/ccp-react-components";
import { formatTime, set } from "src/helpers/date";

type Props = {
    cell: string;
};

export const StartTimeCell = ({ cell }: Props) => {
    if (!cell) {
        return <Text small>-</Text>;
    }
    const [hours, minutes] = cell.split(":");
    const dateObj = set(Date.now(), { hours: parseInt(hours), minutes: parseInt(minutes), milliseconds: 0 });

    return <Text small>{formatTime(dateObj)}</Text>;
};
