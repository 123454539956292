import { Box, Icon, Stack } from "@secuis/ccp-react-components";

import { ButtonTextStyled } from "./SitesBreadcrumbs.styles";
import type { ISiteGroup } from "./types";

export type ISiteGroupAncestor = Pick<ISiteGroup, "id" | "name">;

type Props = {
    selectedGroupAncestors: ISiteGroupAncestor[];
    onGroupSelect: (groupId: string) => void;
};

export const SitesBreadcrumbs = ({ selectedGroupAncestors, onGroupSelect }: Props) => {
    const renderItem = (group: ISiteGroupAncestor, index: number) => {
        const isDisabled = selectedGroupAncestors.length === 1 || index === selectedGroupAncestors.length - 1;

        if (index === 0) {
            return (
                <ButtonTextStyled
                    data-testid="sites-breadcrumbs-item"
                    key={group.id}
                    tabIndex={0}
                    icon="Building"
                    iconSize="S"
                    onClick={() => onGroupSelect(group.id)}
                    disabled={isDisabled}
                >
                    {group.name}
                </ButtonTextStyled>
            );
        }

        return (
            <Stack data-testid="sites-breadcrumbs-item" key={index} alignItems="center" gap="XS">
                <Icon variant="ArrowForward" size="M" />
                <ButtonTextStyled tabIndex={isDisabled ? -1 : 0} onClick={() => onGroupSelect(group.id)} disabled={isDisabled}>
                    {group.name}
                </ButtonTextStyled>
            </Stack>
        );
    };

    return (
        <Box data-testid="sites-breadcrumbs">
            <Stack alignItems="center" gap="S" flexWrap="wrap">
                {selectedGroupAncestors.map((group, index) => renderItem(group, index))}
            </Stack>
        </Box>
    );
};
