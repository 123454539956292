import { HttpResponse } from "msw";

import { ReportTemplateEnum } from "../../../models/ReportModel";
import { GetByDateRangeAndSiteIds } from "../../../store/insights/InsightsModel";
import { db } from "../../db";

export const getHourlyTourExceptionsSummaryResolver = (payload: GetByDateRangeAndSiteIds) => {
    const getHourBlock = (hour: number) => {
        if (hour >= 0 && hour < 6) {
            return "00-06";
        } else if (hour >= 6 && hour < 12) {
            return "06-12";
        } else if (hour >= 12 && hour < 18) {
            return "12-18";
        } else {
            return "18-24";
        }
    };
    const filter = buildFilter(payload);

    const exceptions = db.reports.findMany({
        where: filter,
    });
    const groupedExceptionsByCategoryAndHours = exceptions.reduce((acc, exception) => {
        const { reportDateTime, categoryLevel1, categoryLevel2 } = exception;
        const date = reportDateTime.split("T")[0];
        const hour = parseInt(reportDateTime.split("T")[1].split(":")[0]);
        const hourBlock = getHourBlock(hour);
        const key = `${date}-${categoryLevel1}-${categoryLevel2}-${hourBlock}`;

        if (acc[key]) {
            acc[key].count += 1;
        } else {
            acc[key] = {
                categoryLevel1,
                categoryLevel2,
                hours: hourBlock,
                count: 1,
            };
        }
        return acc;
    }, {});

    const result = Object.values(groupedExceptionsByCategoryAndHours);

    return HttpResponse.json(result);
};

const buildFilter = (requestParams: GetByDateRangeAndSiteIds) => {
    const locationFilter = {
        locationId: {
            in: requestParams.siteIds,
        },
    };

    const fromDateTime = Date.parse(requestParams.fromDateTime);
    const toDateTime = Date.parse(requestParams.toDateTime);

    const createDateTimeFilter = {
        createDateTime: {
            gte: fromDateTime,
            lt: toDateTime,
        },
    };

    const typeFilter = {
        type: {
            in: [ReportTemplateEnum.tourException, ReportTemplateEnum.tourMultiException],
        },
    };

    return {
        ...locationFilter,
        ...createDateTimeFilter,
        ...typeFilter,
    };
};

export default getHourlyTourExceptionsSummaryResolver;
