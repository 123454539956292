import { useSelector } from "react-redux";
import { useSqlQuery } from "src/sql/hooks";
import InsightsSelectors from "src/store/insights/InsightsSelectors";
import LocationsSelectors from "src/store/locations/LocationsSelectors";

import { getMatrixDataQuery, parseMatrixDataQueryResult } from "../Matrix.queries";
import { DataItem } from "../Matrix.types";
import { useCategories } from "./useCategories";

type useMatrixDataResult = {
    data: DataItem[];
    maxIncidentCount: number;
    isLoading: boolean;
    categories: string[];
};

export const useMatrixData = (categoryKey: string): useMatrixDataResult => {
    const { categories, level } = useCategories(categoryKey);

    const { queryResult, isLoading } = useMatrixDataQuery(categories, level);
    const selectedSites = useSelector(InsightsSelectors.getSelectedRegions);
    const siteNames = useSiteNames(selectedSites);
    const data: {
        item: DataItem;
        total: number;
        max: number;
    }[] = [];

    if (!isLoading && queryResult) {
        siteNames.forEach((siteName, siteId) => {
            const item = queryResult.find((item) => {
                return item.site.id === siteId;
            });

            if (item && item.site.total > 0) {
                data.push({
                    item: {
                        ...item,
                        site: {
                            total: item.site.total,
                            name: siteName,
                        },
                    },
                    total: item.site.total,
                    max: item.site.max,
                });
            }
        });
    }

    // find the max number of incidents for all sites
    const maxIncidentCount = Math.max(...data.map((site) => site.max));

    // sort items by total incidents per site descending
    const sortedData = data.sort((a, b) => b.total - a.total).map((item) => item.item);

    return {
        categories,
        data: sortedData,
        maxIncidentCount,
        isLoading,
    };
};

const useMatrixDataQuery = (categories: string[], level: number) => {
    const startDate = useSelector(InsightsSelectors.getSelectedStartDate).toISOString();
    const endDate = useSelector(InsightsSelectors.getSelectedEndDate).toISOString();
    const queryParams = { startDate, endDate, categories, level };

    const { isLoading, queryResult } = useSqlQuery(getMatrixDataQuery, parseMatrixDataQueryResult, queryParams);

    return { queryResult, isLoading };
};

const useSiteNames = (siteIds: string[]): Map<string, string> => {
    const { siteObjects } = useSelector(LocationsSelectors.getAuthorizedLocations);

    const sites = siteIds.length > 0 ? siteObjects.filter((site) => siteIds.includes(site.id)) : siteObjects;
    const siteNames = new Map(sites.map((site) => [site.id, site.name]));

    return siteNames;
};
