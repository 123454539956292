import { useCallback, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DateFilter } from "src/components/shared/filters";
import { selectDates } from "src/store/filter/FilterActions";
import FilterSelectors from "src/store/filter/FilterSelectors";

import { addDays, formatToRawDate } from "../../../../helpers/date";

type Props = {
    onCalendarOpen?: () => void;
};

export const ReportsDateFilter = ({ onCalendarOpen }: Props) => {
    const dispatch = useDispatch();
    const selectedStartDate = useSelector(FilterSelectors.getSelectedStartDate);
    const selectedEndDate = useSelector(FilterSelectors.getSelectedEndDate);

    const preselectedStartDate = useMemo(() => new Date(selectedStartDate), [selectedStartDate]);
    const preselectedEndDate = useMemo(() => {
        const tomorrow = formatToRawDate(addDays(new Date(), 1));

        return selectedEndDate === tomorrow ? new Date(formatToRawDate(new Date())) : new Date(selectedEndDate);
    }, [selectedEndDate]);

    const onDateChange = useCallback(
        (startDate: Date, endDate: Date) => {
            dispatch(selectDates(formatToRawDate(startDate), formatToRawDate(endDate)));
        },
        [dispatch],
    );

    return <DateFilter startDate={preselectedStartDate} endDate={preselectedEndDate} onDateChange={onDateChange} onCalendarOpen={onCalendarOpen} />;
};
