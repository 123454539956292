import { Palette, Stack } from "@secuis/ccp-react-components";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";

import { TileLoader } from "../../../components/Insights/TileLoader/TileLoader";
import { EmptyInboxContent } from "../../../components/shared/NoContent/EmptyInboxContent";
import { useFilterableReportTypes } from "../../../hooks/ReportTypeHooks";
import { useBlueCards } from "../../../store/insights/CardsHooks";
import InsightsSelectors from "../../../store/insights/InsightsSelectors";
import { cardWidth } from "./Cards.styled";
import { CardsCarousel } from "./CardsCarousel";
import { CardsSection } from "./CardsSection";
import { GenerateImprovementCards } from "./ImprovementCardsGenerator";
import { GenerateSummaryCards } from "./SummaryCardsGenerator";

const EmptyStateBackground = styled(Stack)`
    background-color: ${Palette.Navy750};
`;

const EmptyState = () => {
    const { t } = useTranslation();
    return (
        <EmptyStateBackground>
            <EmptyInboxContent
                title={t("insights.guardKpis.emptyState.title")}
                subtitle={t("insights.guardKpis.emptyState.subtitle")}
                mt="XL"
                mr="S"
                mb="XL"
                ml="S"
            />
        </EmptyStateBackground>
    );
};

export const BlueCards = () => {
    const { t } = useTranslation();
    const { isLoading, cards } = useBlueCards();
    const selectedRegions = useSelector(InsightsSelectors.getSelectedRegions);
    const { defaultInsightsReportTypes } = useFilterableReportTypes();

    const slides = useMemo(() => {
        if (isLoading || !cards) {
            return [];
        }
        return GenerateImprovementCards(new Date(), cardWidth).concat(GenerateSummaryCards(cards, cardWidth, selectedRegions, defaultInsightsReportTypes));
    }, [cards, isLoading, selectedRegions, defaultInsightsReportTypes]);

    return (
        <CardsSection title={t("insights.guardKpis.title")} subtitle={t("insights.guardKpis.subtitle")}>
            {isLoading ? <TileLoader height="370px" /> : slides.length ? <CardsCarousel cardWidth={cardWidth}>{slides}</CardsCarousel> : <EmptyState />}
        </CardsSection>
    );
};
