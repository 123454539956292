import { DataItem, IncidentData } from "../Matrix.types";

interface IncidentDataItem {
    [key: string]: IncidentData;
}

// Transforms matrix data into an array of objects with incident data (without site data)
const getIncidentsByCategory = (items: DataItem[]): IncidentDataItem[] => {
    return items.map((item) => {
        const incidentData: { [key: string]: IncidentData } = {};

        for (const key in item) {
            const value = item[key];
            if (value && "categoryIncidentCount" in value && "totalIncidentCount" in value) {
                incidentData[key] = value;
            }
        }

        return incidentData;
    });
};

// Calculates the sum of incidents for a specific category
const getIncidentCountForCategory = (incidents: IncidentDataItem[], category: string): number => {
    let incidentCount = 0;

    incidents.forEach((item) => {
        if (item[category]) {
            incidentCount += item[category].categoryIncidentCount;
        }
    });

    return incidentCount;
};

export const getCategoryIncidentCount = (items: DataItem[], category: string): number => {
    const incidents = getIncidentsByCategory(items);

    return getIncidentCountForCategory(incidents, category);
};
