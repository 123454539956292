import { delay, http, HttpResponse } from "msw";
import { setupWorker } from "msw/browser";
import { ReportsListItemType } from "src/models/ReportModel";

import { generateExamples } from "./mockData";

export const initApiMock = async (tours: ReportsListItemType[]) => {
    // turn off in test env, throws error because use browser worker
    if (process.env.NODE_ENV !== "test") {
        const worker = setupWorker();
        await worker.start();

        worker.use(
            http.get(/(.)*\/api\/v1\/global\/locations\/(.)*\/schedules(.)*/, async () => {
                await delay(1000);
                return HttpResponse.json(
                    generateExamples(
                        10,
                        tours.map((t) => t.id),
                    ),
                );
            }),
        );
    }
    return;
};
