import { Color, Icon, Stack, Text } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { getTrendingInfo } from "src/helpers/trending";
import { QaProps } from "src/models";
import InsightsSelectors from "src/store/insights/InsightsSelectors";

import { AlignedTextStyled } from "./TrendInfo.styles";

type Props = QaProps & {
    value: number;
    previousValue?: number;
    description?: string;
    align?: "left" | "right";
};
export const TrendInfo = ({ value, previousValue, description, align = "left", ...otherProps }: Props) => {
    const { t } = useTranslation();
    const color: Color = "tertiary";
    const descriptionLabel = description || t("insights.summary.defaultComparison");
    const hasData = useSelector(InsightsSelectors.getClientReportsStatus);
    const { trendResult, iconVariant } = getTrendingInfo(previousValue, value);
    const justifyContent = align === "left" ? "flex-start" : "flex-end";

    if (!hasData) {
        return null;
    }

    return (
        <Stack {...otherProps} justifyContent={justifyContent} flexWrap="wrap">
            <Text data-testid="percentage-value" color={color}>
                {trendResult}
            </Text>
            {iconVariant && <Icon color={color} variant={iconVariant} size="L" />}
            <AlignedTextStyled align={align} color={color}>
                {descriptionLabel}
            </AlignedTextStyled>
        </Stack>
    );
};
