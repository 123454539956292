import { Box, Breakpoints, Button, ButtonIcon, ButtonText, Headline, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useOnEscapeEventListener } from "src/hooks/WebAccessibilityHooks";

import { ChildrenProps } from "../../../models/ChildrenPropModel";
import { FilterButtonContainer, FilterWrapper } from "./FilterToolbar.styles";

type Props = ChildrenProps & {
    activeFiltersCount: number;
    canSubmit: boolean;
    onSubmit: () => void;
    onClearAll: () => void;
    onClose: () => void;
};

export const FilterToolbar = ({ activeFiltersCount, canSubmit, children, onSubmit, onClearAll, onClose }: Props) => {
    const { t } = useTranslation();
    const filterWrapperRef = useRef<HTMLDivElement>(null);
    const isMobile = useHasMaxWidth(Breakpoints.XS);

    useOnEscapeEventListener(onClose, filterWrapperRef);

    return (
        <FilterWrapper data-testid="filter-modal" ref={filterWrapperRef}>
            <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Headline color="primary">{t("filters.title.filter")}</Headline>
                <ButtonIcon data-testid="filter-close-button" icon="Close" mode="stateless" onClick={onClose} aria-label={t("filters.close")} />
            </Stack>
            {children}
            {isMobile && (
                <FilterButtonContainer>
                    <Button data-testid="button-apply-filter" color={"accent"} direction="row" disabled={!canSubmit} mode={"contained"} onClick={onSubmit}>
                        {t("filter.button.applyfilter")}
                        {activeFiltersCount ? ` (${activeFiltersCount})` : null}
                    </Button>
                    {!!activeFiltersCount && (
                        <Box mt={"S"}>
                            <ButtonText onClick={onClearAll}>{t("filters.clearAll")}</ButtonText>
                        </Box>
                    )}
                </FilterButtonContainer>
            )}
        </FilterWrapper>
    );
};
