import { Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const Container = styled(Stack)`
    border-radius: ${Spacing.XXS}px;
    background-color: ${Palette.Navy650};

    @media (max-width: ${Breakpoints.XS}), (min-width: calc(${Breakpoints.S} + 1px)) and (max-width: ${Breakpoints.L}) {
        flex-direction: column;
    }
`;

export const CheckpointsSummary = styled(Stack)`
    max-width: 223px;
    width: 100%;

    @media (max-width: ${Breakpoints.XS}), ((min-width: calc(${Breakpoints.S} + 1px)) and (max-width: ${Breakpoints.L})) {
        max-width: none;
    }
`;

export const PercentageText = styled.div<{ isLow: boolean }>`
    font-size: 48px;
    line-height: 80px;
    color: ${(props) => (props.isLow ? Palette.Red300 : Palette.Green300)};
    margin-right: ${Spacing.S}px;
`;

export const Dot = styled.div<{ color: string; marginLeft?: number }>`
    height: ${Spacing.XS}px;
    width: ${Spacing.XS}px;
    ${(props) => `background-color: ${props.color};`};
    ${(props) => (props.marginLeft ? `margin-left: ${props.marginLeft}px;` : "")};
    border-radius: 50%;
    display: inline-block;
    margin-right: ${Spacing.XS}px;
`;
