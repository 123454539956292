import { Box, Icon, IconType } from "@secuis/ccp-react-components";
import { useRef } from "react";

import { useChildOverflows } from "../../../hooks/CommonHooks";
import { ChildrenProps } from "../../../models/ChildrenPropModel";
import { Place } from "../../shared/Tooltip";
import { TileHeaderSection } from "./InsightHeader.styled";
import { Header, HeaderContainer, HeaderText, HeaderTextTooltip, HeaderTooltip } from "./InsightTileHeader.styled";

export type InsightTileHeaderProps = {
    id: string;
    iconVariant: IconType;
    headerText: string;
    className?: string;
    tooltipText?: string;
    tooltipPlace?: Place;
    additionalInfo?: JSX.Element;
};

export const InsightTileHeader = ({ id, iconVariant, children, headerText, className, tooltipText, tooltipPlace }: ChildrenProps<InsightTileHeaderProps>) => {
    const headerTextTooltipRef = useRef(null);
    const enableHeaderTextTooltip = useChildOverflows(headerTextTooltipRef);
    return (
        <HeaderContainer className={className}>
            <Header>
                <Box marginRight="XS">
                    <HeaderTooltip id={id} label={tooltipText} disable={!tooltipText} place={tooltipPlace}>
                        <Icon variant={iconVariant} size="M" />
                    </HeaderTooltip>
                </Box>
                <HeaderTextTooltip id={`${id}_text`} disable={!enableHeaderTextTooltip} label={headerText} place={tooltipPlace}>
                    <HeaderText data-testid="insight-tile-header-text" ref={headerTextTooltipRef} bold micro>
                        {headerText}
                    </HeaderText>
                </HeaderTextTooltip>
            </Header>
            {children && <TileHeaderSection>{children}</TileHeaderSection>}
        </HeaderContainer>
    );
};
