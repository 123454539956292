import { Breakpoints, ButtonText, Spacing, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import { useIncidentMatrixContext } from "../../../IncidentMatrixProvider";

type BackButtonProps = {
    categoryKey: string;
};

const BackButtonWrapper = styled.div`
    margin-bottom: ${Spacing.XL}px;

    @media (max-width: ${Breakpoints.L}) {
        margin-bottom: ${Spacing.L}px;
    }

    @media (max-width: ${Breakpoints.XS}) {
        margin-bottom: ${Spacing.S}px;
    }
`;

export const BackButton = ({ categoryKey }: BackButtonProps) => {
    const { setMatrixCategory, matrixCategoryLevel } = useIncidentMatrixContext();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const { t } = useTranslation();

    const iconSize = isMobile ? "L" : undefined;
    const categoryNameTranslation = t(`incident.category.${categoryKey}`);

    const buttonText =
        matrixCategoryLevel === 2
            ? t("incidentMatrix.table.backButton.allEvents")
            : `${categoryNameTranslation} ${t("incidentMatrix.table.backButton.events")}`;

    const onClickAction = () => {
        if (matrixCategoryLevel === 2) {
            setMatrixCategory("");
        } else {
            setMatrixCategory(categoryKey);
        }
    };

    return (
        <BackButtonWrapper>
            <ButtonText data-testid="back-navigation-button" icon="BackNavigation" iconSize={iconSize} onClick={onClickAction}>
                {buttonText}
            </ButtonText>
        </BackButtonWrapper>
    );
};
