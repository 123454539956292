import { useEffect, useState } from "react";
import { useMemoizedDependency } from "src/hooks/useMemoizedDependency";

type ToggleVisibleItemsProps<T> = {
    items: T[];
    maxVisibleItems: number;
};

export const useToggleVisibleItems = <T>({ items, maxVisibleItems }: ToggleVisibleItemsProps<T>) => {
    const [visibleItems, setVisibleItems] = useState<T[]>(items.slice(0, maxVisibleItems));
    const memoizedItems = useMemoizedDependency(items);

    useEffect(() => {
        setVisibleItems(items.slice(0, maxVisibleItems));
    }, [memoizedItems, maxVisibleItems]);

    const showMoreItems = () => {
        setVisibleItems(items);
    };

    const showLessItems = () => {
        setVisibleItems(items.slice(0, maxVisibleItems));
    };

    return {
        visibleItems,
        showMoreItems,
        showLessItems,
    };
};
