import { Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

export const BarStyled = styled(Stack)`
    background: ${Palette.Navy750};
    padding: 0 ${Spacing.XS}px;
    margin-bottom: ${Spacing.S}px;

    @media (max-width: ${Breakpoints.XS}) {
        padding: 0 ${Spacing.S}px;
        margin-bottom: 0;
    }
`;
