import { RSAA } from "redux-api-middleware";

import { APP_ROLES } from "../../models/RoleModel";
import {
    GET_LOCATION_CLIENT_FAILURE,
    GET_LOCATION_CLIENT_REQUEST,
    GET_LOCATION_CLIENT_SUCCESS,
    LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_FAILURE,
    LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_REQUEST,
    LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_SUCCESS,
} from "../locations/types";

const getClientDescendantsByLabel = (userId: string, clientId: string) => {
    const rolesFilter = APP_ROLES.join(",");
    return {
        [RSAA]: {
            auth: true,
            endpoint: `/v1/authorizationGraph/userOrganization/${userId}/location/${clientId}/descendants?targetLabels=siteObject,group&rolesFilterString=${rolesFilter}`,
            method: "GET",
            headers: {
                "Cache-Control": "no-cache",
            },
            silent: true,
            types: [
                LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_REQUEST,
                LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_SUCCESS,
                LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_FAILURE,
            ],
        },
    };
};

const getLocationClient = (locationId: string) => {
    return {
        [RSAA]: {
            auth: true,
            endpoint: `/v1/organization/ancestorVerticesUntilTargetVertexLabel/${locationId}/client/site,parentOf`,
            method: "GET",
            headers: {
                "Cache-Control": "no-cache",
            },
            silent: true,
            types: [GET_LOCATION_CLIENT_REQUEST, GET_LOCATION_CLIENT_SUCCESS, GET_LOCATION_CLIENT_FAILURE],
        },
    };
};

const actions = {
    getClientDescendantsByLabel,
    getLocationClient,
};

export default actions;
