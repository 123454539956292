import i18next from "i18next";
import { isEmpty, maxBy } from "lodash";
import { useMemo } from "react";
import { formatExplicit, setDay } from "src/helpers/date";
import { useSqlQuery } from "src/sql/hooks";
import { useFilteredSites } from "src/store/insights/FilterHooks";

import { STYLES } from "../shared/components/Graph/Graph.constants";
import { GraphData } from "../shared/components/Graph/Graph.types";
import { useSummaryPeriod } from "../shared/hooks";
import { parseResult, query } from "./DeviantDayWidget.queries";

const MAX_PEAK_DAYS_COUNT = 3;

const getWeekDayLabel = (weekDay: string, short: boolean = true): string => {
    const weekDayNumber = parseInt(weekDay);
    const date = setDay(new Date(), weekDayNumber, { weekStartsOn: 1 });
    return formatExplicit(date, short ? "EEEEEE" : "EEEE");
};

const weekDays = ["1", "2", "3", "4", "5", "6", "0"];

const parseToChartData = (
    data: Record<
        string | number,
        Partial<{
            weekDay: string;
            totalEvents: number;
        }>
    >,
    maxValue: number,
): GraphData => {
    if (!data) {
        return [];
    }

    const shouldHighlight = weekDays.filter((item) => data[item]?.totalEvents === maxValue).length <= MAX_PEAK_DAYS_COUNT;

    return weekDays.map((dayKey) => {
        const dailyData = data[dayKey];
        const totalEvents = dailyData?.totalEvents || 0;
        const label = getWeekDayLabel(dayKey, false);
        const name = getWeekDayLabel(dayKey);

        return {
            key: name,
            label: label,
            name: name,
            value: totalEvents,
            color: shouldHighlight && maxValue === totalEvents ? STYLES.barColor.highlight.default : STYLES.barColor.default.default,
            tooltip: [`${i18next.t("insights.summary.deviantDay")}: ${label}`, `${totalEvents} ${i18next.t("common.event", { count: totalEvents })}`],
        };
    });
};

export const useDeviantDayWidget = () => {
    const { siteIds } = useFilteredSites();
    const { currentPeriod } = useSummaryPeriod();
    const { queryResult, isLoading } = useSqlQuery(query, parseResult, {
        siteIds,
        startDate: currentPeriod.start,
        endDate: currentPeriod.end,
    });

    const maxDataValue = useMemo(() => {
        if (!queryResult || isEmpty(queryResult)) {
            return 0;
        }
        return maxBy(Object.values(queryResult), (obj) => obj.totalEvents).totalEvents;
    }, [queryResult]);

    const chartData = useMemo(() => parseToChartData(queryResult, maxDataValue), [queryResult, maxDataValue]);

    const shouldHighlightMaxDataValue = useMemo(
        () => chartData.filter((item) => item.value === maxDataValue).length <= MAX_PEAK_DAYS_COUNT,
        [chartData, maxDataValue],
    );

    return {
        chartData,
        isLoading: isLoading,
        maxDataValue,
        shouldHighlightMaxDataValue,
    };
};
