import { Headline, Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";

import { useSummary } from "./Summary.hooks";
import { NoWrapSpanStyled } from "./Summary.styles";
import { SummaryGrid } from "./SummaryGrid";
import { ViewModeSwitcher } from "./ViewModeSwitcher";

export const Summary = () => {
    const { t } = useTranslation();
    const { periodLabel } = useSummary();

    return (
        <>
            <Stack mb="S" gap="S" justifyContent="space-between" alignItems="center">
                <Headline>
                    {t("insights.summary.title")}: <NoWrapSpanStyled>{periodLabel}</NoWrapSpanStyled>
                </Headline>
                <ViewModeSwitcher />
            </Stack>
            <SummaryGrid />
        </>
    );
};
