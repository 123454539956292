import { Breakpoints, Icon, Palette, Spacing, Text } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { Tile } from "../../../../components/Insights/common/Tile.styled";
import { Tooltip } from "../../../../components/shared/Tooltip";
import { BoxShadow } from "../../../../styles/templates/BoxShadow";
import { flexColumnJustifyCenter } from "../../../../styles/templates/Flex";
import { microText } from "../../../../styles/templates/Typography";

export const InsightTileStyled = styled(Tile)`
    flex: 0 1 auto;
    width: fit-content;
    height: fit-content;
    background-color: ${Palette.Navy650};
    min-height: 468px;
    padding-bottom: ${Spacing.M}px;

    @media (max-width: ${Breakpoints.XS}) {
        max-width: none;
        min-width: 0;
        flex: 1 1 auto;
    }
`;

export const TileHeader = styled.div`
    & > div,
    svg {
        display: inline;
        vertical-align: middle;
    }
`;

export const StyledIcon = styled(Icon)`
    margin-right: 12px;
`;

export const TooltipStyled = styled(Tooltip)`
    float: right;
`;

export const ChartWrapper = styled.div`
    margin: auto;
`;

export const LegendWrapper = styled.div`
    display: grid;
    justify-content: center;
    grid-template-columns: minmax(auto, 106px) minmax(auto, 106px);
    grid-template-rows: repeat(2, 1fr);
    gap: ${Spacing.XS}px ${Spacing.S}px;
`;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const LegendItemStyled = styled(({ dataItemColor, ...rest }) => <Text {...rest} />)<{ dataItemColor: string }>`
    &::before {
        content: "";
        background-color: ${({ dataItemColor }): string => dataItemColor};
        width: ${Spacing.XS}px;
        height: ${Spacing.XS}px;
        display: inline-block;
        border-radius: 50%;
        margin-right: ${Spacing.XXS}px;
    }
`;

export const LabelText = styled.text`
    ${microText}
    fill: ${Palette.White};
`;

export const CellStyleOverrideWrapper = styled.div`
    & .recharts-sector {
        stroke: none;
    }
    & .recharts-sector:hover,
    .recharts-sector.active {
        stroke: ${Palette.Purple400};
        filter: drop-shadow(${BoxShadow.S});
        stroke-width: 2px;
        outline: none;
    }
`;

export const EmptyTileWrapper = styled.div`
    ${flexColumnJustifyCenter}
    flex-grow: 1;
`;
