import { ChildrenProps } from "../../../../models/ChildrenPropModel";
import { TabContentWrapper } from "./TabContent.styles";

export const TabContent = ({ children }: ChildrenProps) => {
    const preventTouchPropagation = (e) => {
        // we need to prevent default SCL tab behavior
        e.stopPropagation();
    };
    return (
        <TabContentWrapper onTouchStart={preventTouchPropagation} onTouchEnd={preventTouchPropagation}>
            {children}
        </TabContentWrapper>
    );
};
