export const getParameterizedArray = (array: string[]) => {
    return array.reduce((keys, siteId, i) => {
        keys[`$k${i}`] = siteId;
        return keys;
    }, {});
};

export const getSqlStringifiedArrayFromObject = (obj: Record<string, unknown>): string => {
    return Object.keys(obj)
        .map((k) => k)
        .join(",");
};

export const generateStringForQuery = (siteIds: string[]): string => {
    return siteIds
        .reduce((siteIdsString, siteId) => {
            siteIdsString += `"${siteId}",`;
            return siteIdsString;
        }, "")
        .slice(0, -1);
};

export type WithSiteZoneQueryParams = { withSiteZone?: boolean };

export const getReportQueryTimeRangeCondition = (startVarName: string, endVarName: string, withSiteZone = false, fieldPrefix = ""): string => {
    const prefix = fieldPrefix ? `${fieldPrefix}.` : "";
    const dateFormat = withSiteZone ? "%Y-%m-%d" : "%Y-%m-%d %H:%M:%S";
    const reportDateFieldName = getDateTimeFieldName(withSiteZone);

    return `${prefix}${reportDateFieldName} >= STRFTIME('${dateFormat}', ${startVarName})
                AND ${prefix}${reportDateFieldName} <= STRFTIME('${dateFormat}', ${endVarName})`;
};

export const getDateTimeFieldName = (withSiteZone = false): string => {
    return withSiteZone ? "report_date_time_local" : "report_date_time";
};
