import { PersistedState } from "redux-persist";

import { IUser } from "../../models/UserModel";
import { RequestStatus } from "../RequestStatus";

export const SITE_OBJECT_USERS_REQUEST = "@@siteObjects/SITE_OBJECT_USERS_REQUEST";
export const SITE_OBJECT_USERS_SUCCESS = "@@siteObjects/SITE_OBJECT_USERS_SUCCESS";
export const SITE_OBJECT_USERS_FAILURE = "@@siteObjects/SITE_OBJECT_USERS_FAILURE";

export interface IUserWithRoles extends IUser {
    roles: string[];
}

export interface IUsersRequestState {
    status: RequestStatus;
    users: IUserWithRoles[];
}

export interface ISiteObjectsState extends PersistedState {
    usersBySiteObjectId: Record<string, IUsersRequestState>;
}
