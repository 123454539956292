import { useBooleanFlagValue } from "@openfeature/react-sdk";
import { Box, Breakpoints, Button, ButtonText, Headline, Icon, Separator, Stack, useHasMaxWidth } from "@secuis/ccp-react-components";
import { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import { SwitcherBanner } from "../../../components/ClientSwitcher/SwitcherBanner";
import { InsightsDateFilter } from "../../../components/Insights/Filter/InsightsDateFilter";
import { TextWithIcon } from "../../../components/Reports/SharedComponents/TextWithIcon";
import { TranslatableEmptyInboxComponent } from "../../../components/shared/NoContent/EmptyInboxContent";
import { featureFlags } from "../../../data/featureFlags";
import { useDisplaySwitcherBanner } from "../../../store/clientSwitcher/ClientSwitcherHooks";
import { TABLE_TOTAL_KEY } from "../../../store/insights/InsightsModel";
import { DeviationKeys } from "../../../store/insights/PatrolDeviations/PatrolDeviationHelpers";
import * as PatrolDeviationsSelectors from "../../../store/insights/PatrolDeviations/PatrolDeviationsSelectors";
import { PatrolDeviationItem } from "../../../store/insights/PatrolDeviations/PatrolDeviationsTypes";
import { useTours } from "../../../store/insights/Tours/ToursHooks";
import * as ToursSelectors from "../../../store/insights/Tours/ToursSelectors";
import LocationsSelectors from "../../../store/locations/LocationsSelectors";
import { RequestStatus } from "../../../store/RequestStatus";
import { InsightsWrapper, PageWrapper, StyledButtonText, TitleWrapper } from "../Common.styled";
import { PatrolDeviations } from "../PatrolTasks/PatrolDeviations/PatrolDeviations";
import { getScannedCheckpointsPercentage } from "../PatrolTasks/PatrolTasks.helpers";
import { TotalPerformanceSection } from "../PatrolTasks/TotalPerformance/TotalPerformanceSection";
import { Tours } from "../PatrolTasks/Tours/Tours";
import { SectionHeadingStyled } from "./InsightsSitePage.styled";

const EXCLUDED_KEYS = [DeviationKeys.category, DeviationKeys.chartData, DeviationKeys.categoryLabel, DeviationKeys.children];

export const InsightsSitePage = () => {
    const { t } = useTranslation();
    const { siteId } = useParams<{ siteId?: string }>();
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    const navigate = useNavigate();
    const locationDetails = useSelector(LocationsSelectors.getAuthorizedLocationById(siteId));
    const displayBanner = useDisplaySwitcherBanner();
    const patrolDeviations = useSelector(PatrolDeviationsSelectors.getPatrolDeviations);
    const toursQueryStatus = useSelector(ToursSelectors.getLocationToursQueryStatus);
    const tours = useSelector(ToursSelectors.getLocationTours);
    const deviationsQueryStatus = useSelector(PatrolDeviationsSelectors.getPatrolDeviationsQueryStatus);
    useTours();
    const totalPerformanceData = useMemo(() => {
        if (!tours.length && !patrolDeviations.length) {
            return {
                exceptionsCount: 0,
                toursCount: 0,
                scannedCheckpointsPercent: 0,
                historicalExceptionsCount: 0,
                historicalToursCount: 0,
                historicalScannedCheckpointsPercent: 0,
            };
        }
        const totalTours = tours.find((x) => x.mysTourId === TABLE_TOTAL_KEY) || {
            count: 0,
            histCount: 0,
            checkpointsMissed: 0,
            checkpointsScanned: 0,
            histCheckpointsMissed: 0,
            histCheckpointsScanned: 0,
        };
        const totalDeviations = patrolDeviations.find((x) => x.category === TABLE_TOTAL_KEY) || {};
        const totalDeviationsValues = Object.keys(totalDeviations).reduce(
            (acc, key) => {
                if (EXCLUDED_KEYS.includes(DeviationKeys[key])) {
                    return acc;
                }
                return {
                    count: acc.count + (totalDeviations[key] as PatrolDeviationItem).count,
                    histCount: acc.histCount + (totalDeviations[key] as PatrolDeviationItem).histCount,
                };
            },
            { count: 0, histCount: 0 },
        );
        return {
            exceptionsCount: totalDeviationsValues.count,
            toursCount: totalTours.count,
            scannedCheckpointsPercent: getScannedCheckpointsPercentage(totalTours.checkpointsMissed, totalTours.checkpointsScanned),
            historicalExceptionsCount: totalDeviationsValues.histCount,
            historicalToursCount: totalTours.histCount,
            historicalScannedCheckpointsPercent: getScannedCheckpointsPercentage(totalTours.histCheckpointsMissed, totalTours.histCheckpointsScanned),
        };
    }, [tours, patrolDeviations]);
    const isEmptyPage =
        !totalPerformanceData.toursCount &&
        !totalPerformanceData.exceptionsCount &&
        !totalPerformanceData.historicalExceptionsCount &&
        !totalPerformanceData.historicalToursCount &&
        deviationsQueryStatus === RequestStatus.success;
    const isLoadingPage = toursQueryStatus === RequestStatus.loading || deviationsQueryStatus === RequestStatus.loading;

    const handleScheduleButtonClick = useCallback(() => {
        navigate(`/schedule/${siteId}`);
    }, [navigate, siteId]);
    const renderViewSchedule = useBooleanFlagValue(featureFlags.viewSchedule, false);

    return (
        <PageWrapper direction="column" ph={isMobile ? "S" : "L"} pt={isMobile ? "XXS" : "0"}>
            <InsightsWrapper pb="S" direction="column">
                {displayBanner && (
                    <Box style={{ width: "inherit" }} mb={isMobile ? "M" : "0"} ph={isMobile ? "0" : "XS"}>
                        <SwitcherBanner />
                    </Box>
                )}
                <Stack direction="column" pt={isMobile ? "XS" : "M"} pb="M" ph="XS" style={{ width: "inherit" }}>
                    {isMobile ? (
                        <Stack direction="column" gap="XS">
                            <StyledButtonText iconSize="L" color="primary" icon="BackNavigation" direction="row" onClick={() => navigate(-1)}>
                                {t("insights.title")}
                            </StyledButtonText>
                            <Stack gap={"XXS"} alignItems="center" pt="XS">
                                <Icon variant="BuildingFilled" />
                                <Headline bold>{locationDetails.name}</Headline>
                            </Stack>
                            <TextWithIcon id={siteId} color={"neutral"} iconSize={"M"} variant={"Location"}>
                                {locationDetails.address}
                            </TextWithIcon>
                            <InsightsDateFilter />
                        </Stack>
                    ) : (
                        <>
                            <TitleWrapper>
                                <ButtonText
                                    data-testid="insights-tasks-sites-page-back-navigation"
                                    color="primary"
                                    icon="BackNavigation"
                                    direction="row"
                                    onClick={() => navigate(-1)}
                                >
                                    {t("insights.title")}
                                </ButtonText>
                                <InsightsDateFilter />
                            </TitleWrapper>
                            <Stack gap="S" alignItems="center" pt="XS">
                                <Stack gap={"XXS"} alignItems="center">
                                    <Icon variant="BuildingFilled" />
                                    <Headline data-testid="insights-tasks-sites-page-location-name" bold>
                                        {locationDetails.name}
                                    </Headline>
                                </Stack>
                                <TextWithIcon id={siteId} color={"neutral"} iconSize={"M"} variant={"Location"}>
                                    {locationDetails.address}
                                </TextWithIcon>
                            </Stack>
                        </>
                    )}
                </Stack>
                {renderViewSchedule && (
                    <>
                        <Separator />
                        <SectionHeadingStyled>
                            <Box paddingVertical="XS">
                                <Headline bold>{t("insights.tabs.tasks")}</Headline>
                            </Box>
                            <Button onClick={handleScheduleButtonClick} mode="contained" fitContent={!isMobile}>
                                {t("schedule.title")}
                            </Button>
                        </SectionHeadingStyled>
                    </>
                )}
                <Stack gap="S" direction="column">
                    {!isLoadingPage && isEmptyPage ? (
                        <Box paddingTop="XS">
                            <TranslatableEmptyInboxComponent subtitle={"insights.patrolTours.noDataSubtitle"} title={"insights.patrolTours.noDataTitle"} />
                        </Box>
                    ) : (
                        <>
                            <TotalPerformanceSection hasSingleSite isLoading={isLoadingPage} {...totalPerformanceData} />
                            <PatrolDeviations
                                patrolDeviations={patrolDeviations}
                                isLoading={deviationsQueryStatus === RequestStatus.loading}
                                siteIds={[siteId]}
                                hasSingleSite
                            />
                            <Tours />
                        </>
                    )}
                </Stack>
            </InsightsWrapper>
        </PageWrapper>
    );
};
