import { Headline, Stack, Text } from "@secuis/ccp-react-components";
import { PropsWithChildren } from "react";

import { CardsSectionContainer } from "./CardsSection.styled";

type Props = PropsWithChildren & {
    title?: string;
    subtitle?: string;
};
export const CardsSection = ({ title, subtitle, children }: Props) => {
    return (
        <CardsSectionContainer>
            <Stack direction="column">
                {title && (
                    <Headline data-testid="cards-section-headline" bold>
                        {title}
                    </Headline>
                )}
                {subtitle && <Text>{subtitle}</Text>}
            </Stack>
            {children}
        </CardsSectionContainer>
    );
};
