import { PropsWithChildren, useMemo, useState } from "react";

import { createContext } from "../../helpers/createContext";

const [InsightsPageContext, useInsightsPageContext] = createContext<{
    isFilterActive: boolean;
    setIsFilterActive: (isOn: boolean) => void;
}>({
    defaultValue: {
        isFilterActive: false,
        setIsFilterActive: () => {},
    },
    providerName: "InsightsPageProvider",
});

export const InsightsPageProvider = ({ children }: PropsWithChildren) => {
    const [isFilterActive, setIsFilterActive] = useState(false);
    const contextValue = useMemo(() => ({ isFilterActive, setIsFilterActive }), [isFilterActive, setIsFilterActive]);

    return <InsightsPageContext.Provider value={contextValue}>{children}</InsightsPageContext.Provider>;
};

export { useInsightsPageContext };
