import { Breakpoints, Spacing, Stack } from "@secuis/ccp-react-components";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

import InsightTile from "../../../components/Insights/common/InsightTile";
import { EmptyInboxContent } from "../../../components/shared/NoContent/EmptyInboxContent";

const Content = styled(InsightTile)`
    width: inherit;
    @media only screen and (min-width: ${Breakpoints.XS}) {
        width: calc(50% - ${Spacing.XS}px);
    }
`;
export const NoReportsGraphTile = () => {
    const { t } = useTranslation();

    return (
        <Content id={"no-reports"} iconVariant="QuestionAskHelp" headerText={t("insights.trendingEvents.noReportsLabel")}>
            <Stack direction="column" justifyContent="center" alignItems="center">
                <EmptyInboxContent title={t("insights.overview.noReportsTitle")} subtitle={t("insights.overview.noReportsTitleInAllCategories")} />
            </Stack>
        </Content>
    );
};
