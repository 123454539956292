import { useTranslation } from "react-i18next";
import { Switcher } from "src/components/shared/Switcher";

import { Graph, GraphWrapperStyled, Widget } from "../shared/components";
import { CategoriesDropdown } from "../shared/components/CategoriesDropdown";
import { GraphLegend } from "../shared/components/GraphLegend";
import { WIDGET_VIEW_MODE_OPTIONS } from "../Summary.constants";
import { useSiteEventsWidget } from "./SiteEventsWidget.hooks";

export const SiteEventsWidget = () => {
    const { t } = useTranslation();
    const { viewMode, graphData, isSwitcherVisible, isCategoriesDropdownVisible, isLoading, isEmpty, setViewMode, updateActiveCategories } =
        useSiteEventsWidget();

    return (
        <Widget
            data-testid="site-events-widget"
            title={t("insights.summary.siteEventsWidget.title")}
            tooltip={t(`insights.summary.siteEventsWidget.tooltip.${viewMode}`)}
            topRightCornerSection={isSwitcherVisible && <Switcher options={WIDGET_VIEW_MODE_OPTIONS} onChange={setViewMode} />}
            topRightCornerSecondSection={isCategoriesDropdownVisible && <CategoriesDropdown onChange={updateActiveCategories} />}
            isEmpty={isEmpty}
            isLoading={isLoading}
            footer={<GraphLegend title={t("insights.summary.siteReportsWidget.graphLegend.title")} data={graphData.data} />}
        >
            <GraphWrapperStyled mt="0">
                <Graph data={graphData.data} barSize="sm" isVertical />
            </GraphWrapperStyled>
        </Widget>
    );
};
