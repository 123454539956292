import { PersistedState } from "redux-persist";

import { IGroup } from "../../models/GroupModel";
import { ISiteObject } from "../../models/SiteObjectModel";
import { RequestStatus } from "./../RequestStatus";

export const LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_REQUEST = "@@locations/LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_REQUEST";
export const LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_SUCCESS = "@@locations/LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_SUCCESS";
export const LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_FAILURE = "@@locations/LOCATIONS_GET_CLIENT_DESCENDANTS_BY_LABEL_FAILURE";

export const GET_LOCATION_CLIENT_REQUEST = "@@locations/GET_LOCATION_CLIENT_REQUEST";
export const GET_LOCATION_CLIENT_SUCCESS = "@@locations/GET_LOCATION_CLIENT_SUCCESS";
export const GET_LOCATION_CLIENT_FAILURE = "@@locations/GET_LOCATION_CLIENT_FAILURE";

export interface ILocationsState extends PersistedState {
    descendantsFetchStatus: RequestStatus;
    groups: IGroup[];
    siteObjects: ISiteObject[];
}
