import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { featureFlags } from "../data/featureFlags";
import { loadObjectFromState, loadValueFromState, saveState, saveValue, SessionStorageKey } from "../helpers/SessionStorageHelper";
import { ReportTemplateEnum } from "../models/ReportModel";
import FeatureFlagsSelectors from "../store/featureFlags/FeatureFlagsSelectors";
import FilterActions from "../store/filter/FilterActions";
import FilterSelectors from "../store/filter/FilterSelectors";
import { IFilterState } from "../store/filter/types";
import InsightsActions from "../store/insights/InsightsActions";
import InsightsSelectors from "../store/insights/InsightsSelectors";
import LocationsSelectors from "../store/locations/LocationsSelectors";
import { useSiteObjectItemsByRegions } from "../store/siteObjects/SiteObjectsHooks";
import { useFilterableReportTypes } from "./ReportTypeHooks";

const useStorageSliceSaveWatcher = (watch: boolean, stateSlice: unknown, storageKey: SessionStorageKey) => {
    useEffect(() => {
        if (!watch) {
            return;
        }

        saveState(stateSlice, storageKey);
    }, [watch, stateSlice, storageKey]);
};

export const useSessionState = (): { stateInitialized: boolean; loadSessionState: (userId: string) => void } => {
    const dispatch = useDispatch();
    const { sitesByRegions } = useSiteObjectItemsByRegions();
    const reportListFilters = useSelector(FilterSelectors.getAllFiltersToSave);
    const insightsFilters = useSelector(InsightsSelectors.getAllFiltersToSave);
    const { groups } = useSelector(LocationsSelectors.getAuthorizedLocations);
    const [stateInitialized, setStateInitialized] = useState<boolean>(false);
    const { setDefaultReportListReportTypeSelection } = useFilterableReportTypes();
    const isCalloutReportTypeEnabled = useSelector((state) => FeatureFlagsSelectors.selectFeatureFlag(state, featureFlags.calloutReports));
    const isAssignmentReportTypeEnabled = useSelector((state) => FeatureFlagsSelectors.selectFeatureFlag(state, featureFlags.assignmentReports));
    const isVehicleInspectionReportTypeEnabled = useSelector((state) => FeatureFlagsSelectors.selectFeatureFlag(state, featureFlags.vehicleInspectionReports));
    useStorageSliceSaveWatcher(stateInitialized, reportListFilters, SessionStorageKey.ReportFilters);
    useStorageSliceSaveWatcher(stateInitialized, insightsFilters, SessionStorageKey.InsightsFilters);

    const resetSessionStorage = (userId) => {
        saveValue(userId, SessionStorageKey.UserId);
        saveState(null, SessionStorageKey.ReportFilters);
        saveState(null, SessionStorageKey.InsightsFilters);
    };

    const getLocationsSelection = useCallback(
        (locationsFromFilter: string[]) => {
            const groupsIds = groups.map((g) => g.id);
            const locationsIds = Object.keys(sitesByRegions.itemsLookup);
            const hasOutdatedAccess = locationsFromFilter?.some((s) => !locationsIds.concat(groupsIds).includes(s));
            return hasOutdatedAccess ? [] : locationsFromFilter;
        },
        [sitesByRegions, groups],
    );

    const getReportTypesSelection = useCallback(
        (reportTypesFromFilter: string[]) => {
            return reportTypesFromFilter.reduce((acc, reportType) => {
                if (reportType === ReportTemplateEnum.assignment) {
                    return isAssignmentReportTypeEnabled ? [...acc, reportType] : acc;
                }
                if (reportType === ReportTemplateEnum.callout) {
                    return isCalloutReportTypeEnabled ? [...acc, reportType] : acc;
                }

                if (reportType === ReportTemplateEnum.vehicleInspection) {
                    return isVehicleInspectionReportTypeEnabled ? [...acc, reportType] : acc;
                }

                return [...acc, reportType];
            }, []);
        },
        [isAssignmentReportTypeEnabled, isCalloutReportTypeEnabled, isVehicleInspectionReportTypeEnabled],
    );

    const applyReportsFilters = useCallback(() => {
        const filtersState = loadObjectFromState(SessionStorageKey.ReportFilters);

        if (filtersState) {
            const locationsToFilter = getLocationsSelection(filtersState["selectedRegions"] as string[]);
            const reportTypesFilter = getReportTypesSelection(filtersState["selectedReportTypes"] as string[]);
            dispatch(
                FilterActions.applyPersistedState({
                    ...(filtersState as IFilterState),
                    selectedRegions: locationsToFilter,
                    selectedReportTypes: reportTypesFilter,
                }),
            );
        } else {
            setDefaultReportListReportTypeSelection();
        }
    }, [dispatch, getLocationsSelection, getReportTypesSelection, setDefaultReportListReportTypeSelection]);

    const applyInsightsFilters = useCallback(() => {
        const insightsFilters = loadObjectFromState(SessionStorageKey.InsightsFilters);
        if (insightsFilters) {
            const locationsToFilter = getLocationsSelection(insightsFilters["selectedLocations"] as string[]);
            dispatch(InsightsActions.applyPersistedFilters(locationsToFilter, insightsFilters["selectedStartDate"], insightsFilters["selectedEndDate"]));
        }
    }, [dispatch, getLocationsSelection]);

    const loadSessionState = useCallback(
        (userId: string) => {
            if (stateInitialized) {
                return;
            }
            const storedUserId = loadValueFromState(SessionStorageKey.UserId);
            if (userId !== storedUserId) {
                resetSessionStorage(userId);
                setStateInitialized(true);
                setDefaultReportListReportTypeSelection();
                return;
            }
            applyReportsFilters();
            applyInsightsFilters();
            setStateInitialized(true);
        },
        [applyInsightsFilters, setDefaultReportListReportTypeSelection, applyReportsFilters, stateInitialized],
    );

    return { loadSessionState, stateInitialized };
};
