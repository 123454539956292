import { HttpResponse } from "msw";

import { ReportTemplateEnum } from "../../../models/ReportModel";
import { GetByDateRangeAndSiteIds } from "../../../store/insights/InsightsModel";
import { db } from "../../db";

export const getLocationsTourSessionSummaryResolver = (payload: GetByDateRangeAndSiteIds) => {
    const filter = buildFilter(payload);

    const tours = db.reports.findMany({
        where: filter,
    });
    const byLocationId = tours.reduce((acc, tour) => {
        if (acc[tour.locationId]) {
            acc[tour.locationId].exceptionsCount += tour.exceptionReportIds.length;
            acc[tour.locationId].checkpointsMissed += tour.checkpointsMissedTotal;
            acc[tour.locationId].checkpointsScanned += tour.checkpointsScannedTotal;
            acc[tour.locationId].count += 1;
        } else {
            acc[tour.locationId] = {
                locationId: tour.locationId,
                exceptionsCount: tour.exceptionReportIds.length,
                checkpointsMissed: tour.checkpointsMissedTotal,
                checkpointsScanned: tour.checkpointsScannedTotal,
                count: 1,
            };
        }

        return acc;
    }, {});

    const result = Object.values(byLocationId);
    return HttpResponse.json(result);
};

const buildFilter = (requestParams: GetByDateRangeAndSiteIds) => {
    const locationFilter = {
        locationId: {
            in: requestParams.siteIds,
        },
    };

    const fromDateTime = Date.parse(requestParams.fromDateTime);
    const toDateTime = Date.parse(requestParams.toDateTime);

    const createDateTimeFilter = {
        createDateTime: {
            gte: fromDateTime,
            lt: toDateTime,
        },
    };

    const typeFilter = {
        type: {
            in: [ReportTemplateEnum.patrolTour],
        },
    };

    return {
        ...locationFilter,
        ...createDateTimeFilter,
        ...typeFilter,
    };
};

export default getLocationsTourSessionSummaryResolver;
