import { HttpResponse } from "msw";

import { ReportTemplateEnum } from "../../../models/ReportModel";
import { GetByDateRangeAndSiteIds } from "../../../store/insights/InsightsModel";
import { db } from "../../db";

export const getDailyTourExceptionsSummaryResolver = (payload: GetByDateRangeAndSiteIds) => {
    const filter = buildFilter(payload);

    const exceptions = db.reports.findMany({
        where: filter,
    });
    const groupedExceptions = exceptions.reduce((acc, exception) => {
        const { reportDateTime, categoryLevel1 } = exception;

        const date = reportDateTime.split("T")[0];
        let item = acc.find((i) => i.dateTime === date && i.categoryLevel1 === categoryLevel1);
        if (item) {
            item.count += 1;
        } else {
            item = {
                categoryLevel1,
                count: 1,
                dateTime: date,
            };
            acc.push(item);
        }
        return acc;
    }, []);

    const result = Object.values(groupedExceptions);
    return HttpResponse.json(result);
};

const buildFilter = (requestParams: GetByDateRangeAndSiteIds) => {
    const locationFilter = {
        locationId: {
            in: requestParams.siteIds,
        },
    };

    const fromDateTime = Date.parse(requestParams.fromDateTime);
    const toDateTime = Date.parse(requestParams.toDateTime);

    const createDateTimeFilter = {
        createDateTime: {
            gte: fromDateTime,
            lt: toDateTime,
        },
    };

    const typeFilter = {
        type: {
            in: [ReportTemplateEnum.tourException, ReportTemplateEnum.tourMultiException],
        },
    };

    return {
        ...locationFilter,
        ...createDateTimeFilter,
        ...typeFilter,
    };
};

export default getDailyTourExceptionsSummaryResolver;
