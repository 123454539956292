import { createApi } from "@reduxjs/toolkit/query/react";

import { baseQuery } from "../../api/baseQuery";
import { TaskSchedule } from "./types";

export const scheduleApi = createApi({
    reducerPath: "scheduleApi",
    baseQuery: baseQuery,
    endpoints: (builder) => ({
        getTasks: builder.query<TaskSchedule[], { date: string; locationId: string }>({
            query: ({ date, locationId }) => `v1/global/locations/${locationId}/schedules?date=${date}`,
        }),
    }),
});

export const { useLazyGetTasksQuery } = scheduleApi;
