import i18next from "i18next";

import { regionsByCountry } from "../data/regions";
import { getValueFromProperty } from "../helpers/PropertiesValueHelper";
import { IAvailableClient } from "../store/clientSwitcher/types";
import { IBaseLocation } from "./BaseLocationModel";
export interface ISiteObject extends IBaseLocation {
    address: string;
    state: string;
    partitionKey: string;
    postalCode: string;
    city: string;
    coordinates: number[];
    region: string;
    countryCode: string;
    displayName: string;
    client: IAvailableClient;
}

export function mapToSiteObject(dto): ISiteObject {
    const countryCode = dto.countryCode?.toLowerCase();
    return {
        id: dto.id,
        sourceId: getValueFromProperty(dto, "sourceId"),
        partitionKey: getValueFromProperty(dto, "partitionKey"),
        label: dto.label,
        name: dto.name,
        address: dto.properties.address,
        state: getValueFromProperty(dto, "state"),
        postalCode: dto.properties.postalCode,
        city: dto.properties.city,
        countryCode: countryCode,
        isDeleted: getValueFromProperty(dto, "isDeleted") === "1",
        coordinates: [dto.properties.latitude, dto.properties.longitude],
        region: regionsByCountry[countryCode],
        displayName: dto.name,
        client: dto.client,
    };
}

export function getFullAddressOrCoordinates(item: ISiteObject): string {
    if (item.address) {
        return `${item.countryCode?.toUpperCase()}, ${item.city}, ${item.address}`;
    }
    if (item.coordinates?.length === 2) {
        return `${item.coordinates[0]}, ${item.coordinates[1]}`;
    }
    return "";
}
export function getFullAddressOrCountry(item: ISiteObject): string {
    if (item.address) {
        return `${item.countryCode?.toUpperCase()}, ${item.city}, ${item.address}`;
    }
    if (item.countryCode) {
        return i18next.t(`country.${item.countryCode}`);
    }
    return "";
}
