import { DateRangeChipModel } from "@secuis/ccp-react-components";
import { getYear, startOfYear, subDays } from "src/helpers/date";
import { useTranslation } from "react-i18next";

export const useDateRangeChips = (): DateRangeChipModel[] => {
    const { t } = useTranslation();
    const today = new Date();

    return [
        {
            label: t("common.date.today"),
            from: today,
        },
        {
            label: t("common.date.yesterday"),
            to: subDays(today, 1),
            from: subDays(today, 1),
        },
        {
            label: t("common.datepicker.last7days"),
            from: subDays(today, 6),
        },
        {
            label: t("common.datepicker.last30days"),
            from: subDays(today, 29),
        },
        {
            label: t("common.datepicker.last6months"),
            from: subDays(today, 179),
        },
        {
            // current year
            label: `${getYear(today)}`,
            from: startOfYear(today),
        },
    ];
};
