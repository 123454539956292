import { Breakpoints, Palette, Spacing, Stack } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { flexColumnJustifyCenterAlignCenter } from "../../../styles/templates/Flex";
import { InvisibleScrollbar } from "../../../styles/templates/Scrollbar";

export const ExpandablePanel = styled.div`
    position: fixed;
    background-color: ${Palette.Navy750};
    z-index: 9999;
    width: 100%;
    height: 100%;
`;

export const FilterWrapper = styled.section`
    display: flex;
    position: relative;
    flex: 1;
    flex-direction: column;
    background-color: ${Palette.Navy750};
    padding: ${Spacing.S}px ${Spacing.M}px 0 ${Spacing.M}px;

    max-width: 320px;
    height: 100vh;
    overflow-y: auto;
    overflow-x: hidden;
    ${InvisibleScrollbar};

    @media (max-width: ${Breakpoints.XS}) {
        padding: ${Spacing.S}px ${Spacing.S}px 0 ${Spacing.S}px;
        max-width: 100%;
        height: 100%;
    }
`;

export const FilterButtonContainer = styled(Stack)`
    padding: ${Spacing.M}px ${Spacing.M}px ${Spacing.L}px ${Spacing.M}px;
    margin: 0 -${Spacing.S}px;
    background-color: ${Palette.Navy700};
    position: sticky;
    margin-top: auto;
    bottom: 0;
    ${flexColumnJustifyCenterAlignCenter}
`;

export const CollapsedFilterWrapper = styled.section`
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    background-color: ${Palette.Navy750};
    padding-top: ${Spacing.S}px;

    max-width: 72px;
    height: 100vh;
    overflow-y: auto;
    ${InvisibleScrollbar};
`;
