import { Breakpoints, useHasMaxWidth } from "@secuis/ccp-react-components";

import { Swipeable } from "../Swipeable";
import { SwipeActionsMenu } from "./SwipeActionsMenu/SwipeActionsMenu";
import type { SwipeActionItem } from "./types";

type Props = {
    children: React.ReactNode;
    breakpoint?: Breakpoints;
    actions?: SwipeActionItem[];
    closeOnClick?: boolean;
};

export const SwipeActions = ({ children, breakpoint, actions, closeOnClick }: Props) => {
    const matchMaxWidth = useHasMaxWidth(breakpoint);

    if (!actions?.length || (breakpoint && !matchMaxWidth)) return children;

    return (
        <Swipeable contentRight={<SwipeActionsMenu actions={actions} />} closeOnClick={closeOnClick}>
            {children}
        </Swipeable>
    );
};
