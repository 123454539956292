import { ButtonText, Icon } from "@secuis/ccp-react-components";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";

import { EmptyInboxContent } from "../../../components/shared/NoContent/EmptyInboxContent";
import { Place } from "../../../components/shared/Tooltip";
import { useIsInViewport } from "../../../hooks/ViewportHooks";
import { useGraphOverviewRedirection } from "../../../store/insights/GraphOverviewHooks";
import { IGraphTile } from "../../../store/insights/InsightsModel";
import { InsightsCategoriesGraph } from "./InsightsCategoriesGraph";
import {
    AllReportsContainer,
    CategoriesGraphContainer,
    EmptyTile,
    GraphContainer,
    GraphHeader,
    GraphTitleText,
    HiddenIcon,
    InsightTileStyled,
    LessReportsText,
    SeverityGraphContainer,
    SeverityGraphHeader,
    SeverityLevelTooltip,
} from "./InsightsGraphTile.styled";
import { InsightsSeverityGraph } from "./InsightsSeverityGraph";

interface InsightsGraphTileProps {
    headerText: string;
    tooltipText: string;
    tooltipPlace?: Place;
    id: string;
    data: IGraphTile;
    setGraphReadyForGuide: (className: string) => void;
}

export const InsightsGraphTile = ({ headerText, tooltipText, id, data, tooltipPlace, setGraphReadyForGuide }: InsightsGraphTileProps) => {
    const { t } = useTranslation();
    const { redirectToCategoryLevel1Reports } = useGraphOverviewRedirection();
    const severityGraphRef = useRef(null);
    const isIntersecting = useIsInViewport(severityGraphRef);

    const hasDataToDisplay = useMemo(() => {
        return Object.keys(data.bySeverityLevel).length !== 0 || data.top5.length > 0;
    }, [data]);

    useEffect(() => {
        if (hasDataToDisplay && isIntersecting) setGraphReadyForGuide(id);
    }, [setGraphReadyForGuide, hasDataToDisplay, id, isIntersecting]);

    return (
        <InsightTileStyled
            id={id}
            iconVariant="Info"
            headerContent={
                hasDataToDisplay && (
                    <AllReportsContainer>
                        <ButtonText
                            color="primary"
                            icon="ArrowForward"
                            direction="row-reverse"
                            iconSize="S"
                            micro
                            uppercase
                            onClick={() => redirectToCategoryLevel1Reports(id)}
                        >
                            {t("insights.overview.allReports")}
                        </ButtonText>
                    </AllReportsContainer>
                )
            }
            headerText={headerText}
            tooltipText={tooltipText}
            tooltipPlace={tooltipPlace}
        >
            {hasDataToDisplay ? (
                <GraphContainer>
                    <CategoriesGraphContainer>
                        <GraphHeader>
                            <GraphTitleText bold micro>
                                {t("insights.overview.top5Categories")}{" "}
                            </GraphTitleText>
                            {/* This one is to force horizontal alignment with <SeverityGraphHeader/>. Icon there 'expands' the text. */}
                            <HiddenIcon variant={"Info"} size="M" />
                        </GraphHeader>
                        <InsightsCategoriesGraph data={data.top5} />
                        {data.top5.length === 1 && <LessReportsText small>{t("insights.overview.accessToSingleCategory")}</LessReportsText>}
                        {data.top5.length > 1 && data.top5.length < 5 && (
                            <LessReportsText small>{t("insights.overview.accessToLimitedCategories", { categoriesCount: data.top5.length })}</LessReportsText>
                        )}
                    </CategoriesGraphContainer>
                    <SeverityGraphContainer className={id} ref={severityGraphRef}>
                        <SeverityGraphHeader>
                            <GraphTitleText bold micro>
                                {t("insights.overview.reportsBySeverityLevel")}
                            </GraphTitleText>
                            <SeverityLevelTooltip id={"severity-levels--tooltip"} label={t("insights.overview.severityLevelsDescription")}>
                                <Icon variant={"Info"} size="M" />
                            </SeverityLevelTooltip>
                        </SeverityGraphHeader>
                        <InsightsSeverityGraph category={id} data={data.bySeverityLevel} />
                    </SeverityGraphContainer>
                </GraphContainer>
            ) : (
                <EmptyTile>
                    <EmptyInboxContent title={t("insights.overview.noReportsLabel")} subtitle={t("insights.overview.noReportsInAllCategories")} />
                </EmptyTile>
            )}
        </InsightTileStyled>
    );
};
