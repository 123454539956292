import { InsightsContent } from "./InsightsContent";
import { InsightsPageProvider } from "./InsightsPageProvider";

export const Insights = () => {
    return (
        <InsightsPageProvider>
            <InsightsContent />
        </InsightsPageProvider>
    );
};
