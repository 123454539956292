import { PersistedState } from "redux-persist";

import { getValueFromProperty } from "../../helpers/PropertiesValueHelper";
import { RequestStatus } from "../RequestStatus";

export const GET_AVAILABLE_CLIENTS_REQUEST = "@@switcher/GET_AVAILABLE_CLIENTS_REQUEST";
export const GET_AVAILABLE_CLIENTS_SUCCESS = "@@switcher/GET_AVAILABLE_CLIENTS_SUCCESS";
export const GET_AVAILABLE_CLIENTS_FAILURE = "@@switcher/GET_AVAILABLE_CLIENTS_FAILURE";

export const GET_AVAILABLE_CLIENTS_TOTAL_COUNT_REQUEST = "@@switcher/GET_AVAILABLE_CLIENTS_TOTAL_COUNT_REQUEST";
export const GET_AVAILABLE_CLIENTS_TOTAL_COUNT_SUCCESS = "@@switcher/GET_AVAILABLE_CLIENTS_TOTAL_COUNT_SUCCESS";
export const GET_AVAILABLE_CLIENTS_TOTAL_COUNT_FAILURE = "@@switcher/GET_AVAILABLE_CLIENTS_TOTAL_COUNT_FAILURE";

export const IS_USER_AUTHORIZED_AT_REQUEST = "@@switcher/IS_USER_AUTHORIZED_AT_REQUEST";
export const IS_USER_AUTHORIZED_AT_SUCCESS = "@@switcher/IS_USER_AUTHORIZED_AT_SUCCESS";
export const IS_USER_AUTHORIZED_AT_FAILURE = "@@switcher/IS_USER_AUTHORIZED_AT_FAILURE";

export const GET_CLIENT_AUTHORIZATIONS_REQUEST = "@@switcher/GET_CLIENT_AUTHORIZATIONS_REQUEST";
export const GET_CLIENT_AUTHORIZATIONS_SUCCESS = "@@switcher/GET_CLIENT_AUTHORIZATIONS_SUCCESS";
export const GET_CLIENT_AUTHORIZATIONS_FAILURE = "@@switcher/GET_CLIENT_AUTHORIZATIONS_FAILURE";

export const SELECT_CLIENT = "@@switcher/SELECT_CLIENT";
export const CLEAR_CLIENTS_SEARCH = "@@switcher/CLEAR_CLIENTS_SEARCH";
export const RESET_CLIENTS_STATE = "@@switcher/RESET_CLIENTS_STATE";

export interface IAvailableClient {
    id: string;
    name: string;
    label: string;
}

export interface IClientAuthorization {
    id: string;
    userId: string;
    locationId: string;
    roleName: string;
    createdTime: string;
    isDeleted: boolean;
}

export interface IClientAuthorizations {
    data: IClientAuthorization[];
    status: RequestStatus;
}

export interface IClientSwitcherState extends PersistedState {
    initialClientCount: number;
    initialStatus: RequestStatus;
    availableClients: {
        status: RequestStatus;
        totalCount: number;
        results: IAvailableClient[];
        skip: number;
    };
    selectedClient: IAvailableClient;
    userAuthorizations: Record<string, { authorized: boolean; status: RequestStatus }>;
    clientAuthorizations: IClientAuthorizations;
}

export function mapToAvailableClient(dto): IAvailableClient {
    return {
        id: dto.id,
        label: dto.label,
        name: getValueFromProperty(dto, "name"),
    };
}
