const CORRECTION_ANGLE = 4;

type PropsType = {
    cx: number;
    cy: number;
    midAngle: number;
    startAngle: number;
    endAngle: number;
    innerRadius: number;
    outerRadius: number;
    payload: { value: number };
    direction?: "up" | "down";
};

export const CustomPieChartLine = ({ cx, cy, midAngle, outerRadius, direction }: PropsType): JSX.Element => {
    const correction = direction === "up" ? CORRECTION_ANGLE : -CORRECTION_ANGLE;
    const angle = direction ? midAngle + correction : midAngle;
    const RADIAN = Math.PI / 180;
    const sx = cx + outerRadius * Math.cos(-RADIAN * midAngle);
    const sy = cy + outerRadius * Math.sin(-RADIAN * midAngle);
    const mx = cx + (outerRadius + 20) * Math.cos(-RADIAN * angle);
    const my = cy + (outerRadius + 20) * Math.sin(-RADIAN * angle);

    return <path d={`M${sx},${sy}L${mx},${my}`} stroke="white" fill="white" />;
};
