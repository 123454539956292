import { Breakpoints, Icon, Palette, Text, useHasMaxWidth } from "@secuis/ccp-react-components";
import styled from "styled-components";

import { Tooltip } from "../../../components/shared/Tooltip";
import { CardBaseContainer, CardDescription, CardHeader, CardHeaderText, CardHeaderTextWrapper, PaddedContainer, TooltipIcon } from "./Cards.styled";

interface TextCardProps {
    id: string;
    headerText: string;
    descriptionText: string;
    tooltipText: string;
    cardWidth: number;
}

const DescriptionText = styled(Text)`
    color: ${Palette.Navy800};
    white-space: pre-line;
`;

const HeaderText = styled(CardHeaderText)`
    color: ${Palette.Navy800};
`;

const NavyIcon = styled(Icon)`
    color: ${Palette.Navy800};
`;
const NavyTooltipIcon = styled(TooltipIcon)`
    color: ${Palette.Navy800};
`;

const StyledTooltip = styled(Tooltip)`
    display: flex;
    align-self: start;

    ${NavyTooltipIcon} {
        &:hover {
            cursor: pointer;
        }
    }
`;

export const TextCard = ({ id, headerText, descriptionText, tooltipText, cardWidth }: TextCardProps) => {
    const isMobile = useHasMaxWidth(Breakpoints.XS);
    return (
        <CardBaseContainer cardWidth={cardWidth} cardType="text_card">
            <PaddedContainer>
                <CardHeader>
                    <NavyIcon variant="Message" size="M" />
                    <CardHeaderTextWrapper>
                        <HeaderText micro bold uppercase>
                            {headerText}
                        </HeaderText>
                    </CardHeaderTextWrapper>
                    <StyledTooltip place={isMobile ? "bottom" : null} label={tooltipText} id={id}>
                        <NavyTooltipIcon variant="Info" size="M" />
                    </StyledTooltip>
                </CardHeader>
                <CardDescription>
                    <DescriptionText small>{descriptionText}</DescriptionText>
                </CardDescription>
            </PaddedContainer>
        </CardBaseContainer>
    );
};
