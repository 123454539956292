import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SUMMARY_CARDS_CONFIGURATION } from "../../components/Insights/Cards/SummaryCardsConfiguration";
import { featureFlags } from "../../data/featureFlags";
import { useFeatureFlag } from "../../hooks/featureFlags";
import { Databases, QueryResult, WorkerExecResult } from "../../models/DbModel";
import { DynamicReportType, isPatrolTour, PatrolTourType, ReportsListItemType } from "../../models/ReportModel";
import {
    getCriticalIncidentsBySiteQuery,
    getHighlightsCategoriesQuery,
    getReportsCountQuery,
    mapToHighlights,
    updateReportReadStatusQuery,
} from "../../sql/scripts/insights/highlightsQueries";
import ReportCategoriesSelectors from "../reportCategories/ReportCategoriesSelectors";
import { useTrackReportVisitMutation, useTrackTourSessionVisitMutation } from "../reports/reportsApi";
import UserSelectors from "../user/UserSelectors";
import { useFilteredSites } from "./FilterHooks";
import InsightsActions from "./InsightsActions";
import { useDatabase } from "./InsightsLoadHooks";
import { CardCountItem, IBlueCards } from "./InsightsModel";
import InsightsSelectors from "./InsightsSelectors";
import { generateQueryKey } from "./keys";

const mapKpiResult = (kpi: WorkerExecResult): CardCountItem => {
    if (!kpi?.results?.[0]?.values || !kpi?.results?.[0]?.values.length) {
        return {
            prev: 0,
            current: 0,
        };
    }
    const prev = kpi?.results?.[0]?.values[1][0];
    const current = kpi?.results?.[0]?.values[0][0];

    return {
        prev,
        current,
    };
};

export const useBlueCards = (): {
    isLoading: boolean;
    cards: IBlueCards;
} => {
    const [cards, setCards] = useState(null);
    const [isLoaded, setIsLoaded] = useState(false);
    const { dbReadyToQuery, execute } = useDatabase(Databases.Reports);
    const { siteIds } = useFilteredSites();
    const areSiteZonesEnabled = useFeatureFlag(featureFlags.sitesTimezones);

    useEffect(() => {
        setIsLoaded(false);
    }, [siteIds]);

    const getBlueCardsAsync = useCallback(async (): Promise<IBlueCards> => {
        const accessControlReportsCountQuery = getReportsCountQuery(
            "access_control_categories",
            siteIds,
            SUMMARY_CARDS_CONFIGURATION.accessControlCategories.dbQueryDateRange,
            areSiteZonesEnabled,
        );
        const accessControlReportsCountJob = execute(accessControlReportsCountQuery);
        const accessControlSystemsReportsCountQuery = getReportsCountQuery(
            "access_control_systems_categories",
            siteIds,
            SUMMARY_CARDS_CONFIGURATION.accessControlSystemsCategories.dbQueryDateRange,
            areSiteZonesEnabled,
        );
        const accessControlSystemsReportsCountJob = execute(accessControlSystemsReportsCountQuery);
        const medicalAssistanceReportsCountQuery = getReportsCountQuery(
            "medical_assistance_categories",
            siteIds,
            SUMMARY_CARDS_CONFIGURATION.medicalAssistanceCategories.dbQueryDateRange,
            areSiteZonesEnabled,
        );
        const medicalAssistanceReportsCountJob = execute(medicalAssistanceReportsCountQuery);
        const confrontationReportsCountQuery = getReportsCountQuery(
            "confrontation_categories",
            siteIds,
            SUMMARY_CARDS_CONFIGURATION.confrontationCategories.dbQueryDateRange,
            areSiteZonesEnabled,
        );
        const confrontationReportsCountJob = execute(confrontationReportsCountQuery);
        const equipmentAccidentReportsCountQuery = getReportsCountQuery(
            "equipment_accident_categories",
            siteIds,
            SUMMARY_CARDS_CONFIGURATION.equipmentAccidentCategories.dbQueryDateRange,
            areSiteZonesEnabled,
        );
        const equipmentAccidentReportsCountJob = execute(equipmentAccidentReportsCountQuery);
        const reportedAuditsCountQuery = getReportsCountQuery(
            "reported_audits_categories",
            siteIds,
            SUMMARY_CARDS_CONFIGURATION.reportedAuditsCategories.dbQueryDateRange,
            areSiteZonesEnabled,
        );
        const reportedAuditsCountJob = execute(reportedAuditsCountQuery);
        const kpis = await Promise.all([
            accessControlReportsCountJob,
            accessControlSystemsReportsCountJob,
            medicalAssistanceReportsCountJob,
            confrontationReportsCountJob,
            equipmentAccidentReportsCountJob,
            reportedAuditsCountJob,
        ]);

        return {
            accessControlCount: mapKpiResult(kpis?.[0]),
            accessControlSystemsCount: mapKpiResult(kpis?.[1]),
            medicalAssistanceCount: mapKpiResult(kpis?.[2]),
            confrontationCount: mapKpiResult(kpis?.[3]),
            equipmentAccidentCount: mapKpiResult(kpis?.[4]),
            reportedAuditsCount: mapKpiResult(kpis?.[5]),
        };
    }, [execute, siteIds]);

    useEffect(() => {
        const getCards = async () => {
            try {
                const cardsResult = await getBlueCardsAsync();
                setCards(cardsResult);
            } finally {
                setIsLoaded(true);
            }
        };
        if (!dbReadyToQuery || isLoaded) {
            return;
        }
        getCards();
    }, [dbReadyToQuery, isLoaded, getBlueCardsAsync]);

    return { isLoading: !isLoaded || !dbReadyToQuery, cards };
};

export const useRedAndPurpleCards = (
    siteIds: string[],
): {
    loadNextPage: () => void;
} => {
    const dispatch = useDispatch();
    const { dbReadyToQuery, execute } = useDatabase(Databases.Reports);
    const selectedStartDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const selectedEndDate = useSelector(InsightsSelectors.getSelectedEndDate);
    const categories = useSelector(ReportCategoriesSelectors.getPreventedCategories);
    const { page: currentPage, queryKey } = useSelector(InsightsSelectors.getCriticalCards);
    const areSitesZonesEnabled = useFeatureFlag(featureFlags.sitesTimezones);

    const getRedAndPurpleCardsAsync = useCallback(
        async (page) => {
            if (!siteIds?.length) {
                return [];
            }
            const reportByCriticalCategoriesQuery = getHighlightsCategoriesQuery(
                page,
                siteIds,
                selectedStartDate,
                selectedEndDate,
                categories,
                areSitesZonesEnabled,
            );
            const reportByCriticalCategoriesJob = execute(reportByCriticalCategoriesQuery.sql, reportByCriticalCategoriesQuery.params);
            const highlights = await Promise.all([reportByCriticalCategoriesJob]);

            return mapToHighlights(highlights[0]?.results);
        },
        [execute, selectedStartDate, selectedEndDate, siteIds, categories, areSitesZonesEnabled],
    );

    const loadNextPage = useCallback(() => {
        dispatch(InsightsActions.criticalCardsRequestNextPage());
    }, [dispatch]);

    useEffect(() => {
        return () => {
            dispatch(InsightsActions.criticalCardsReset());
        };
    }, []);

    useEffect(() => {
        const getNextPageCards = async () => {
            const cardsResult = await getRedAndPurpleCardsAsync(currentPage);

            dispatch(InsightsActions.criticalCardsNextPageSuccess(cardsResult));
        };
        if (currentPage > 0) {
            getNextPageCards();
        }
    }, [currentPage]);

    useEffect(() => {
        const getCards = async (key: string) => {
            dispatch(InsightsActions.criticalCardsRequest());
            const cardsResult = await getRedAndPurpleCardsAsync(0);
            dispatch(InsightsActions.criticalCardsSuccess(cardsResult, key));
        };
        if (!siteIds) {
            return;
        }

        const newKey = generateQueryKey({ locationIds: siteIds, fromDate: selectedStartDate, toDate: selectedEndDate });
        if (!dbReadyToQuery || newKey === queryKey) {
            return;
        }
        getCards(newKey);
    }, [dbReadyToQuery, selectedStartDate, selectedEndDate, siteIds, queryKey, getRedAndPurpleCardsAsync, dispatch]);

    return { loadNextPage };
};

const mapToIncidentsBySite = (result: QueryResult) => {
    if (!result?.values) {
        return [];
    }
    return result?.values?.map((val) => {
        return {
            location_id: val[0],
            count: val[1],
        };
    });
};

export const useIncidentsCountBySite = (
    siteIds: string[],
): {
    incidents: {
        location_id: string;
        count: number;
    }[];
    isLoading: boolean;
} => {
    const dispatch = useDispatch();
    const [queryKey, setQueryKey] = useState<string>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { dbReadyToQuery, execute } = useDatabase(Databases.Reports);
    const selectedStartDate = useSelector(InsightsSelectors.getSelectedStartDate);
    const selectedEndDate = useSelector(InsightsSelectors.getSelectedEndDate);
    const categories = useSelector(ReportCategoriesSelectors.getPreventedCategories);
    const [incidents, setIncidents] = useState<
        {
            location_id: string;
            count: number;
        }[]
    >([]);
    const getCriticalIncidentsAsync = useCallback(async () => {
        const query = getCriticalIncidentsBySiteQuery(siteIds, selectedStartDate, selectedEndDate, categories);
        const result = await execute(query.sql, query.params);

        return mapToIncidentsBySite(result?.results[0]);
    }, [execute, selectedStartDate, selectedEndDate, siteIds, categories]);

    useEffect(() => {
        const getCards = async () => {
            setIsLoading(true);
            const result = await getCriticalIncidentsAsync();
            setIncidents(result);
            setIsLoading(false);
        };
        if (!siteIds.length) {
            setIsLoading(false);
            return;
        }
        const newKey = generateQueryKey({ locationIds: siteIds, fromDate: selectedStartDate, toDate: selectedEndDate });
        if (!dbReadyToQuery || newKey === queryKey) {
            return;
        }
        setQueryKey(newKey);
        getCards();
    }, [dbReadyToQuery, selectedStartDate, selectedEndDate, siteIds, queryKey, getCriticalIncidentsAsync, dispatch]);

    return {
        incidents,
        isLoading: !dbReadyToQuery || isLoading,
    };
};

type TrackedReport = DynamicReportType | PatrolTourType | ReportsListItemType;

export const useTrackReportVisit = () => {
    const { execute, dbReadyToQuery } = useDatabase(Databases.Reports) || {};
    const { info: user } = useSelector(UserSelectors.getUserInfo);
    const [trackReportVisit] = useTrackReportVisitMutation();
    const [trackTourSessionVisit] = useTrackTourSessionVisitMutation();

    const trackUserReportVisit = useCallback(
        (report: TrackedReport, isTourReport: boolean) => {
            if (isTourReport) {
                trackTourSessionVisit({ reportId: report.id, siteObjectId: report.locationId, userId: user.id });
            } else {
                trackReportVisit({ reportId: report.id, siteObjectId: report.locationId, userId: user.id });
            }
        },
        [user?.id, trackReportVisit, trackTourSessionVisit],
    );

    const updateReportInDb = useCallback(
        (report: TrackedReport, isTourReport: boolean) => {
            if (execute && dbReadyToQuery && !isTourReport) {
                execute(updateReportReadStatusQuery(report.id));
            }
        },
        [execute, dbReadyToQuery],
    );

    const updateReportReadStatus = useCallback(
        (report: TrackedReport) => {
            if (user?.id) {
                const isTourReport = isPatrolTour(report);
                trackUserReportVisit(report, isTourReport);
                updateReportInDb(report, isTourReport);
            }
        },
        [trackUserReportVisit, user?.id, updateReportInDb],
    );

    return { updateReportReadStatus };
};
