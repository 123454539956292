import { isRSAA, RSAA } from "redux-api-middleware";

import AppActions from "../store/app/AppActions";

const apiErrorMiddleware =
    ({ dispatch }) =>
    (next) =>
    async (action) => {
        if (!isRSAA(action)) {
            return next(action);
        }

        const silent = action[RSAA].silent;
        if (silent) {
            delete action[RSAA].silent;
        }

        const result = await next(action);

        if (!result?.error && !result?.payload?.error) {
            return result;
        }

        // ignore logging azure translator actions
        // that's because when developing locally the calls won't work and make a mess in the console
        if ((process.env.NODE_ENV === "development" && result.type.includes("@@translate")) || silent) {
            return result;
        }

        const {
            payload: { message, name, status },
        } = result;

        dispatch(
            AppActions.setApiError({
                message,
                name,
                silent,
                status,
            }),
        );

        return result;
    };

export default apiErrorMiddleware;
