import { useReportCategories } from "src/store/reportCategories/ReportCategoriesHooks";

import { MATRIX_REPORT_CATEGORIES as rootCategories } from "../Matrix.constants";

export const useCategories = (categoryKey: string): { categories: string[]; level: number } => {
    const { reportCategories } = useReportCategories();

    if (!categoryKey) {
        return { categories: rootCategories, level: 1 };
    }

    for (const category of reportCategories) {
        if (category.key === categoryKey) {
            return {
                categories: category.level2Categories.map((subCategory) => subCategory.key),
                level: 2,
            };
        }

        const level2Category = category.level2Categories.find((subCategory) => subCategory.key === categoryKey);
        if (level2Category) {
            return {
                categories: level2Category.level3Categories.map((subCategory) => subCategory.key),
                level: 3,
            };
        }
    }

    return { categories: rootCategories, level: 1 };
};
