import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import { IsoStringDate } from "../../../../helpers/date";
import { useApiRequest } from "../../../../hooks/accessApi";
import { useFilteredSites } from "../../../../store/insights/FilterHooks";
import { TourSessions } from "../../../../store/insights/InsightsPatrolTasksModel";
import { useSummaryPeriod } from "../shared/hooks";
import { SummaryPeriod } from "../shared/types";
import { ToursIndicators } from "./PerformanceIndicatorsWidget.types";

export const usePerformanceIndicatorsWidget = () => {
    const { t } = useTranslation();
    const { currentPeriod, previousPeriod } = useSummaryPeriod();
    const { values: currentValues, isLoading: isLoadingCurrent } = useReducedIndicatorValues(currentPeriod);
    const { values: previousValues, isLoading: isLoadingPrevious } = useReducedIndicatorValues(previousPeriod);

    const indicators = [
        {
            title: t("insights.summary.performanceIndicators.completedTours"),
            value: currentValues.completedTours,
            prevValue: previousValues.completedTours,
        },
        {
            title: t("insights.summary.performanceIndicators.uncompletedTours"),
            value: currentValues.uncompletedTours,
            prevValue: previousValues.uncompletedTours,
        },
    ];

    return { indicators, isLoading: isLoadingCurrent || isLoadingPrevious };
};

const useTourRequest = (startDate: IsoStringDate, endDate: IsoStringDate, siteIds: string[]) => {
    const { data: tourData, isLoading } = useApiRequest<TourSessions[]>("/v1/global/insights/tour-sessions/filter", "POST", {
        siteIds,
        fromDateTime: startDate,
        toDateTime: endDate,
    });

    return { tourData, isLoading };
};

const useReducedIndicatorValues = (period: SummaryPeriod) => {
    const { siteIds } = useFilteredSites();
    const { isLoading, tourData } = useTourRequest(period.start, period.end, siteIds);

    const toursStats: ToursIndicators = useMemo(() => {
        const emptyIndicatorsValues: ToursIndicators = { completedTours: 0, uncompletedTours: 0 };

        if (tourData) {
            return tourData.reduce(
                ({ uncompletedTours, completedTours }, { completedCount, incompleteCount }) => ({
                    completedTours: completedTours + completedCount,
                    uncompletedTours: uncompletedTours + incompleteCount,
                }),
                emptyIndicatorsValues,
            );
        }

        return emptyIndicatorsValues;
    }, [tourData]);

    return { values: toursStats, isLoading };
};
